import { What } from 'weplayed-typescript-api';

import { BaseEntityList } from './BaseEntityList';

export function withBase<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends React.FC<any>,
>(
  Component: T,
  what?: What,
): T {
  // eslint-disable-next-line react/function-component-definition
  const name = `${Component.displayName}WithBase`;
  // eslint-disable-next-line func-names
  const renderer = function (props): JSX.Element {
    return (
      <BaseEntityList what={what} {...props}>
        <Component {...props} />
      </BaseEntityList>
    );
  };

  renderer.displayName = name;

  return renderer as T;
}
