import { countBy, identity } from 'lodash/fp';
import * as React from 'react';
import { useMutation } from 'react-query';
import { LinkType, share as api, What } from 'weplayed-typescript-api';

import { ShareTarget } from 'common/types';
import { EVENTS, weplayedEvent } from 'common/utils/events';
import { pkify } from 'common/utils/helpers';
import { getCollectionShareURL, getMomentShareURL } from 'common/utils/share';
import { getVisitorId } from 'common/utils/visitorId';

import { COLLECTION$, DISCOVER$, MOMENT$ } from 'cms/routes';

import { UseShareReturnType, UseShareType } from './types';

const privatePrefix = `${window.location.protocol}//${window.location.hostname}`;

export const useShare: UseShareType = function useShare({
  context,
  item: outerItem,
  items: outerItems,
  orgId,
  type,
  userId,
}) {
  const items = React.useMemo(
    () => outerItems || (outerItem && [outerItem]) || [],
    [outerItem, outerItems],
  );

  const report = (target: ShareTarget, stats: Record<LinkType, number>): void => weplayedEvent({
    context,
    entity_type: type,
    entity_id: items.map(pkify),
    type: EVENTS.SHARE,
    service: target,
    stats,
  });

  const links: UseShareReturnType['links'] = React.useCallback((target) => {
    const queryParams: Record<string, string> = {
      utm_source: 'weplayed',
    };

    if (userId) {
      queryParams.utm_term = `u-${userId}`;
    } else {
      queryParams.utm_term = `v-${getVisitorId()}`;
    }

    switch (type) {
      case What.ATHLETES: {
        queryParams.utm_content = 'at-00';
        break;
      }

      case What.CHANNELS: {
        queryParams.utm_content = 'ch-00';
        break;
      }

      case What.COLLECTIONS: {
        queryParams.utm_content = 'pl-00';
        break;
      }

      case What.MOMENTS: {
        queryParams.utm_content = 'mo-00';
        break;
      }

      default: //
    }

    switch (target) {
      case 'email': {
        queryParams.utm_medium = 'email_share';
        break;
      }

      case 'facebook': {
        queryParams.utm_medium = 'fb_share';
        break;
      }

      case 'twitter': {
        queryParams.utm_medium = 'tw_share';
        break;
      }

      case 'linkedin': {
        queryParams.utm_medium = 'li_share';
        break;
      }

      case 'copy': {
        queryParams.utm_medium = 'copy_share';
        break;
      }

      default:
        queryParams.utm_medium = 'other_share';
    }

    const publicParams = {
      ...queryParams,
    };

    if (orgId) {
      publicParams.wl_org_id = orgId;
      publicParams.namespace = '1';
    }

    return Object.fromEntries(
      items.map(({ pk: uid }) => {
        const privatePath = (
          (type === What.MOMENTS && MOMENT$.buildPath({ uid }))
          || (type === What.COLLECTIONS && COLLECTION$.buildPath({ uid }))
          || (type === What.GAMES && DISCOVER$.buildPath({
            section: What.GAMES,
            uid,
            sub: What.MOMENTS,
          }))
          || (type === What.PLAYERS && DISCOVER$.buildPath({
            section: What.PLAYERS,
            uid,
            sub: What.MOMENTS,
          }))
        );

        const publicPath = (
          (type === What.MOMENTS && getMomentShareURL(uid, publicParams))
          || (type === What.COLLECTIONS && getCollectionShareURL(uid, publicParams))
          // || (type === What.GAMES && GAME.buildPath({ uid: item.pk, queryParams }))
          || (type === What.GAMES && false)
          // || (type === What.PLAYERS && PLAYER.buildPath({ uid: item.pk, queryParams }))
          || (type === What.PLAYERS && false)
        );

        return [uid, {
          [LinkType.PRIVATE]: `${privatePrefix}${privatePath}`,
          [LinkType.PUBLIC]: publicPath || null,
        }];
      }),
    );
  }, [items, orgId, type, userId]);

  const email: UseShareReturnType['email'] = useMutation(
    async (params) => {
      await api.email({ ...params, links: links('email') });

      return countBy(identity, params.recipients.map(({ link }) => link));
    },
    {
      onSuccess: (stats) => {
        report('email', stats);
      },
    },
  );

  const social: UseShareReturnType['social'] = (target) => {
    if (items.length !== 1) {
      return;
    }

    const url = Object.values(links(target)).shift()[LinkType.PUBLIC];
    const [item] = items;

    if (!url) return;
    const beforeOnClick = (): void => {
      report(target, { public: 1, private: 0 });
    };

    switch (type) {
      case What.MOMENTS: return {
        beforeOnClick,
        title: `Check out this moment:\n\n${item.name}\n`,
        url,
      };

      case What.COLLECTIONS: return {
        beforeOnClick,
        title: `Check out this playlist:\n\n${item.name}\n`,
        url,
      };

      default: return { url: '', beforeOnClick: (): void => { } };
    }
  };

  return { email, links, report, social };
};
