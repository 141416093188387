import * as cx from 'classnames';
import * as React from 'react';

import { formatDate } from 'common/utils/dates';

import { TeamImage } from 'cms/components/TeamImage';

import * as s from './GameLabel.m.less';
import { GameLabelProps } from './types';

export const GameLabel: React.FC<GameLabelProps> = function GameLabel({
  game, className, children, noDate, score1, score2, ...rest
}) {
  const { team1, team2, date_played } = game;

  return (
    <div className={cx(s.root, className)} {...rest}>
      <TeamImage team={team1} size="xx-small" className={s.team} />
      vs
      <TeamImage team={team2} size="xx-small" className={s.team} />
      {typeof score1 === 'number' && typeof score2 === 'number' && (
        <>
          ・
          {score1}
          {' '}
          -
          {' '}
          {score2}
        </>
      )}
      {!noDate && `・${formatDate(date_played)}`}
      {children && (
        <>
          ・
          {children}
        </>
      )}
    </div>
  );
};
