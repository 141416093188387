import * as cx from 'classnames';
import * as React from 'react';
import { Overlay } from 'react-bootstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { MomentDescriptionReadonly } from 'cms/components/MomentDescription';

import * as s from './Sortables.m.less';
import {
  MomentAction, MomentProps, MomentsContainerProps, SortableMenuProps,
} from './types';

const SortableMenu: React.FC<
  SortableMenuProps & React.RefAttributes<HTMLUListElement>
> = React.forwardRef(({ className, onClick, style, hasEdit }, ref) => (
  <ul
    className={cx('dropdown-menu', s.sortableMenu, className)}
    ref={ref}
    style={style}
  >
    <button type="button" onClick={(): void => onClick('top')}>
      Move to Top
    </button>
    <button type="button" onClick={(): void => onClick('bottom')}>
      Move to Bottom
    </button>
    <div className={cx('dropdown-divider', s.divider)} />
    {hasEdit && (
      <button type="button" onClick={(): void => onClick('edit')}>
        Edit
      </button>
    )}
    <button type="button" onClick={(): void => onClick('remove')}>
      Remove from Playlist
    </button>
  </ul>
));

SortableMenu.displayName = 'SortableMenu';

const SortableMoment = SortableElement<MomentProps>(
  ({ value: moment, momentIndex, onMenu, className }: MomentProps) => (
    <li className={cx(s.item, className)}>
      <span className={s.index}>{momentIndex + 1}</span>
      <div className={s.thumbnail}>
        <div className={moment.game.sport.slug}>
          {moment.thumbnail && <img src={moment.thumbnail} alt={moment.name} />}
        </div>
      </div>
      <div className={s.description}>
        <MomentDescriptionReadonly
          inline
          moment={moment}
          withDuration
        />
        <button
          className={s.menu}
          data-idx={momentIndex}
          onClick={onMenu}
          type="button"
        >
          Menu
        </button>
      </div>
    </li>
  ),
);

export const MomentsContainer = SortableContainer<MomentsContainerProps>(
  ({ items, onAction, disabled, edit, hasEdit, editable = true }: MomentsContainerProps) => {
    const [menu, setMenu] = React.useState<{idx: number, target: HTMLButtonElement}>();

    const handleMenuOpen = React.useCallback(({ currentTarget: target }) => {
      const idx = parseInt(target.getAttribute('data-idx'), 10);
      setMenu({ idx, target });
    }, []);

    const handleMenuClose = React.useCallback(() => {
      setMenu(null);
    }, []);

    const handleMenu = React.useCallback((action: MomentAction) => {
      setMenu(null);
      onAction(items[menu.idx], action);
    }, [items, menu?.idx, onAction]);

    return (
      <>
        <ul className={cx(s.root, disabled && s.disabled, (edit || !editable) && s.inactive)}>
          {items.map((moment, index) => (
            <SortableMoment
              className={cx(index === menu?.idx && s.hover, edit && edit !== moment.pk && s.hidden)}
              index={index}
              key={moment.pk}
              momentIndex={index}
              value={moment}
              onMenu={handleMenuOpen}
            />
          ))}
        </ul>
        {editable && (
          <Overlay
            onHide={handleMenuClose}
            placement="left"
            rootClose
            show={Boolean(menu)}
            target={menu?.target}
          >
            <SortableMenu onClick={handleMenu} hasEdit={hasEdit && items[menu?.idx]?.can_manage} />
          </Overlay>
        )}
      </>
    );
  },
);

export const testDragCancel = (
  e: React.MouseEvent<HTMLElement>,
): boolean => {
  let element: HTMLElement = e.target as HTMLElement;
  do {
    if (element.tagName === 'BUTTON') {
      return true;
    }

    element = element.parentNode as HTMLElement;
  } while (element && element !== e.currentTarget);

  return false;
};
