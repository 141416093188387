import * as cx from 'classnames';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { LinkType } from 'weplayed-typescript-api';

import { LinkDescription, LinkName } from './constants';
import * as s from './CopyLinkDropdown.m.less';
import { LinkTypeButtonProps } from './types';

const LinkTypeText: React.FC<{ type: LinkType }> = function LinkTypeText({ type }): JSX.Element {
  return (
    <div className={s.item}>
      <b>{LinkName[type]}</b>
      <span>{LinkDescription[type]}</span>
    </div>
  );
};

export const LinkTypeButton = function LinkTypeButton({
  className,
  description,
  disabled,
  onChange,
  value,
}: LinkTypeButtonProps): JSX.Element {
  return (
    <Dropdown
      className={cx(s.root, className)}
      onSelect={onChange}
    >
      <Dropdown.Toggle
        as={description ? 'button' : undefined}
        className={description ? cx('dropdown-item', s.itemWithDescription) : ''}
        disabled={disabled}
        variant="transparent"
      >
        {description ? <LinkTypeText type={value} /> : LinkName[value]}
      </Dropdown.Toggle>
      <Dropdown.Menu className={s.menu}>
        <Dropdown.Item as="button" eventKey={LinkType.PRIVATE}>
          <LinkTypeText type={LinkType.PRIVATE} />
        </Dropdown.Item>
        <Dropdown.Item as="button" eventKey={LinkType.PUBLIC}>
          <LinkTypeText type={LinkType.PUBLIC} />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
