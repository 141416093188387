import { omit, pick } from 'lodash';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { CreateParams } from 'cms/hooks/useCollections/types';

import { ReducerAction } from './types';

export const reducer = (state: CreateParams, action: ReducerAction): CreateParams => {
  switch (action.action) {
    case 'set': return {
      ...pick(
        action.collection,
        'algo_maintained',
        'name',
        'theme',
        'visibility',
        'blocked_from_team',
        'blocked_from_game',
        'blocked_from_team_athlete',
        'moments',
      ),
      ...Object.fromEntries(Object.entries(
          pick(
            action.collection,
            'dedicated_team',
            'dedicated_game',
            'dedicated_team_athlete',
          ) as { [K: string]: BaseEntityWithPk },
        ).map(([key, value]) => [key, value ? value.pk : null])),
    };

    case 'update': return {
      ...state,
      ...omit(action, 'action'),
    };

    default: return state;
  }
};
