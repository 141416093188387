import * as React from 'react';
import { LinkType, What } from 'weplayed-typescript-api';

import { useApplication } from 'common/hooks/useApplication';

import { useShare } from 'cms/hooks/useShare';

import { SuccessMessage } from './constants';
import { UseCopyLinkArgs, UseCopyLinkReturnType } from './types';

export const useCopyLink = function useCopyLink<T extends What>({
  type,
  item,
  context,
  userId,
  orgId,
  onSuccess,
}: UseCopyLinkArgs<T>): UseCopyLinkReturnType {
  const { broadcast } = useApplication();
  const { links: copyLinks, report } = useShare({ context, item, type, orgId, userId });
  const links = React.useMemo(() => {
    const { [item?.pk]: l } = copyLinks('copy');
    return l;
  }, [copyLinks, item?.pk]);

  const handleSuccess = React.useCallback(({ text }) => {
    broadcast('success', SuccessMessage);

    report('copy', {
      public: text === links[LinkType.PUBLIC] ? 1 : 0,
      private: text === links[LinkType.PRIVATE] ? 1 : 0,
    });

    if (onSuccess) {
      onSuccess();
    }
  }, [broadcast, onSuccess, links, report]);

  return { handleSuccess, links };
};
