import * as cx from 'classnames';
import * as React from 'react';
import { Dropdown, Overlay } from 'react-bootstrap';
import Clipboard from 'react-clipboard.js';
import { LinkType, What } from 'weplayed-typescript-api';

import { LinkDescription, LinkName } from './constants';
import * as s from './CopyLinkDropdown.m.less';
import { CopyLinkDropdownProps } from './types';
import { useCopyLink } from './useCopyLink';

export const CopyLinkDropdown = function CopyLinkDropdown<
  T extends What
>({
  className,
  context,
  item,
  onClose,
  orgId,
  placement = 'auto-start',
  target,
  type,
  userId,
}: CopyLinkDropdownProps<T>): JSX.Element {
  const { links, handleSuccess } = useCopyLink({
    type, item, context, userId, orgId, onSuccess: onClose,
  });

  return (
    <Overlay
      flip
      onHide={onClose}
      placement={placement}
      rootClose
      rootCloseEvent="click"
      show={Boolean(target)}
      target={target}
    >
      {({ arrowProps: _1, className: cn, ...props }): React.ReactNode => (
        <Dropdown {...props} className={cx(s.root, s.global, className, cn)}>
          <Dropdown.Menu className={s.menu}>
            {links?.[LinkType.PRIVATE] && (
              <Dropdown.Item
                as={Clipboard}
                onSuccess={handleSuccess}
                data-clipboard-text={links?.[LinkType.PRIVATE]}
              >
                <div className={s.item}>
                  <b>{LinkName[LinkType.PRIVATE]}</b>
                  <span>{LinkDescription[LinkType.PRIVATE]}</span>
                </div>
              </Dropdown.Item>
            )}
            {links?.[LinkType.PUBLIC] && (
              <Dropdown.Item
                as={Clipboard}
                onSuccess={handleSuccess}
                data-clipboard-text={links?.[LinkType.PUBLIC]}
              >
                <div className={s.item}>
                  <b>{LinkName[LinkType.PUBLIC]}</b>
                  <span>{LinkDescription[LinkType.PUBLIC]}</span>
                </div>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Overlay>
  );
};
