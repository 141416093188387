import {
  BaseEntityWithPk, Collection as $Collection,
} from 'weplayed-typescript-api';

import { defaults } from '../constants';
import { Collection } from '../Items/Tiles/Collection';
import { CollectionsProps, TilesExtraProps, WithGetData } from '../types';
import { useControls } from '../useControls';
import { getBlock, getLike, getPin } from '../utils';
import { Base } from './Base';

const d = defaults.tiles.collections;

// eslint-disable-next-line react/function-component-definition
export function Collections<
  T extends BaseEntityWithPk = $Collection,
  D extends $Collection = $Collection,
>({
  athlete = d.athlete,
  created = d.created,
  creator = d.creator,
  edited = d.edited,
  game = d.game,
  getData,
  link,
  onBlock,
  onLike,
  onLink,
  onMenu,
  onPin,
  position,
  sport = d.sport,
  status,
  visibility = d.visibility,
  ...props
}: Omit<CollectionsProps<T, D>, 'type'> & TilesExtraProps & WithGetData<T, D>): JSX.Element {
  const handlers = useControls(
    { onBlock, onLike, onLink, onMenu, onPin, getBlock, getLike, getPin },
  );

  const renderer = (p): JSX.Element => (
    <Collection<T, D>
      {...p}
      {...handlers}
      athlete={athlete}
      blocking={status?.publish.includes(p.item.pk)}
      created={created}
      creator={creator}
      edited={edited}
      game={game}
      getData={getData}
      link={link}
      pinning={status?.pin.includes(p.item.pk)}
      position={typeof position === 'number' ? position + p.index : null}
      sport={sport}
      visibility={visibility}
    />
  );

  return (
    <Base<T>
      {...props}
      renderer={renderer}
    />
  );
}
