import cx from 'classnames';
import { identity, noop } from 'lodash/fp';
import {
  BaseEntityWithPk, Collection as $Collection,
} from 'weplayed-typescript-api';

import { formatDate } from 'common/utils/dates';

import { Avatar } from 'cms/components/Avatar';
import { FadeInImage } from 'cms/components/FadeInImage';
import { GameLabel } from 'cms/components/GameLabel';
import { SportLabel } from 'cms/components/SportLabel';

import { defaults } from '../../constants';
import { CollectionItemProps } from '../../types';
import { getBlock, getPin } from '../../utils';
import { Block, Like, Link, Menu, Pin, Visibility } from '../Controls';
import { EntityLink } from '../EntityLink';
import { collections as def } from '../links';
import { useLink } from '../useLink';
import { Base } from './Base';
import { Blocked } from './Blocked';
import * as s from './Items.m.less';

const d = defaults.lists.collections;

export const Collection = function Collection<
  T extends BaseEntityWithPk = $Collection,
  D extends $Collection = $Collection
>({
  athlete = d.athlete,
  blocking,
  created = d.created,
  creator = d.creator,
  edited = d.edited,
  getData = identity,
  game = d.game,
  item: $item,
  link,
  onBlock,
  onLike,
  onLink,
  onMenu,
  onPin,
  pinning,
  position,
  sport = d.sport,
  visibility = d.visibility,
  ...props
}: CollectionItemProps<T, D>): JSX.Element {
  const item = getData($item);
  const url = useLink({ item, link, def });

  const {
    algo_created, algo_maintained, visibility: visible, moment_count,
    name, thumbnail, created_by, ctime, mtime, liked_by_user,
    dedicated_game, dedicated_player, sport: sp, is_hidden,
  } = item;

  const $game = dedicated_game;
  const thumb = thumbnail && (
    <>
      <FadeInImage src={thumbnail} alt={name} />
      {is_hidden && <Blocked />}
    </>
  );

  const algoText = algo_created
    && `System Playlist${algo_maintained ? ' - updated automatically' : ''}`;
  const algo = algoText && (
    <span className={cx(s.algo, algo_maintained && s.auto)} title={algoText}>
      {algoText}
    </span>
  );

  return (
    <Base<T>
      item={$item}
      controls={[
        <Pin
          className={s.visible}
          disabled={!visible}
          key="pin"
          loading={pinning}
          onClick={onPin}
          pin={getPin(item)}
        />,
        <Block
          block={getBlock(item)}
          disabled={!visible}
          className={s.visible}
          key="block"
          loading={blocking}
          onClick={visible && onBlock}
        />,
        <Visibility
          className={s.visible}
          key="visibility"
          onClick={visibility && noop}
          visible={Boolean(visible)}
        />,
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          disabled={!visible}
          key="link"
          onClick={onLink}
        />,
        <Like
          className={cx(liked_by_user && s.visible)}
          key="like"
          like={liked_by_user}
          onClick={onLike}
        />,
        <Menu
          className={s.visible}
          key="menu"
          onClick={onMenu}
        />,
      ]}
      {...props}
    >
      {typeof position === 'number' && (
        <td>{`${position}.`}</td>
      )}
      <td>
        <EntityLink
          className={cx(s.thumbnail, sp?.slug)}
          url={moment_count !== 0 && url}
        >
          {thumb}
          {algo}
        </EntityLink>
      </td>
      <td><span className={s.name} title={name}>{name}</span></td>
      <td>{moment_count}</td>
      {game && (<td>{$game && <GameLabel game={$game} className={s.gameLabel} />}</td>)}
      {sport && <td><SportLabel sport={sp} /></td>}
      {athlete && <td>{dedicated_player && dedicated_player.name}</td>}
      {creator && (<td>{created_by && <Avatar user={created_by} className={s.avatar} />}</td>)}
      {created && <td>{formatDate(ctime)}</td>}
      {edited && <td>{formatDate(mtime)}</td>}
    </Base>
  );
};
