import { What } from 'weplayed-typescript-api';

import { Collections } from './Collections';
import { Games } from './Games';
import * as Controls from './Items/Controls';
import * as ControlsStyle from './Items/Controls/Controls.m.less';
import * as ListItems from './Items/Lists';
import * as ListItemsStyle from './Items/Lists/Items.m.less';
import * as TileItems from './Items/Tiles';
import { Lists as L } from './Lists';
import * as ListHeaderStyle from './Lists/Header.m.less';
import { Moments } from './Moments';
import { Players } from './Players';
import { Tiles as T } from './Tiles';
import { withBase } from './withBase';

export { Controls };

export const styles = {
  Lists: {
    Header: ListHeaderStyle,
    Items: ListItemsStyle,
  },
  Controls: ControlsStyle,
};

export * from './EntitiesListGroup';
export * from './EntitiesListSwitch';

export { useLink } from './Items/useLink';
export * as defaults from './Items/links';

export const Items = {
  Lists: ListItems,
  Tiles: TileItems,
};

export * from './Lists/constants';

export * from './Items/EntityLink';

export { useControls } from './useControls';

export * from './utils';

export * from './types';

export { withBase };

export const Entities = {
  Collections,
  Games,
  Moments,
  Players,
};

const { MOMENTS, COLLECTIONS, GAMES, PLAYERS } = What;

export const Tiles = {
  Base: T.Base,
  Collections: withBase(T.Collections, COLLECTIONS),
  Games: withBase(T.Games, GAMES),
  Moments: withBase(T.Moments, MOMENTS),
  Players: withBase(T.Players, PLAYERS),
};

export const Lists = {
  Base: L.Base,
  Collections: withBase(L.Collections, COLLECTIONS),
  Games: withBase(L.Games, GAMES),
  Moments: withBase(L.Moments, MOMENTS),
  Players: withBase(L.Players, PLAYERS),
};

export { useEntities } from './useEntities';
