import { ReactComponent as HeartOutline } from '@mdi/svg/svg/heart-outline.svg';
import { ReactComponent as Heart } from '@mdi/svg/svg/heart.svg';
import { ReactComponent as StarOutline } from '@mdi/svg/svg/star-outline.svg';
import { ReactComponent as Star } from '@mdi/svg/svg/star.svg';
import * as cx from 'classnames';
import * as React from 'react';

import { TooltipButton } from 'cms/components/TooltipButton';

import * as s from './LikeButton.m.less';
import { Props } from './types';

const LikeButton: React.FC<Props> = function LikeButton({
  disabled,
  likes, liked, onChange, className, onClick,
  tooltip = false, withText, ...rest
}) {
  const handleAction = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick(e);
    }

    if (onChange) {
      onChange(!liked, e);
    }
  }, [onClick, onChange, liked]);

  const tooltipText = likes
    ? ((liked && 'Unlike') || 'Like')
    : ((liked && 'Remove from favorites') || 'Add to favorites');

  const buttonText = withText && (
    likes
      ? ''
      : ((liked && 'Remove from favorites') || 'Add to favorites')
  );

  const content = (
    <>
      {liked
        ? ((likes && <Heart />) || <Star />)
        : ((likes && <HeartOutline />) || <StarOutline />)}
      {buttonText || (likes && <div className={s.counter}>{likes}</div>)}
    </>
  );

  return tooltip
  ? (
    <TooltipButton
      className={cx(s.root, (onChange || onClick) && s.editable, className)}
      disabled={disabled || !(onChange || onClick)}
      onClick={handleAction}
      title="Toggle Favorites"
      tooltip={tooltipText}
      {...rest}
    >
      {content}
    </TooltipButton>
  ) : (
    <button
      className={cx(s.root, (onChange || onClick) && s.editable, className)}
      disabled={disabled || !(onChange || onClick)}
      onClick={handleAction}
      title="Toggle Favorites"
      type="button"
    >
      {content}
    </button>
  );
};

const LikeButtonMemo: React.FunctionComponent<Props> = React.memo(LikeButton);

export { LikeButtonMemo as LikeButton };
