import * as React from 'react';
import Helmet from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { useProfile } from 'common/hooks/useProfile';
import { PasswordResetConfirm } from 'common/hooks/useProfile/types';

import { Button } from 'cms/components/Button';
import FormField from 'cms/components/Field/FormField';
import LabelError from 'cms/components/Field/LabelError';
import NonFieldErrors from 'cms/components/Field/NonFieldErrors';
import { CLIENT_URLS } from 'cms/routes';

import { Form, UrlParams } from './types';

export const ResetPasswordConfirm: React.FC = function ResetPasswordConfirm() {
  const { uid, token } = useParams<UrlParams>();
  const {
    reset_password_confirm: [update, { isLoading, error, isSuccess }],
    reset_password_confirm_test: [
      testToken,
      { isLoading: isTestLoading, isSuccess: isTestSuccess, error: testError },
    ],
  } = useProfile();

  useHandleQuery({ error: testError, isLoading: isTestLoading });

  React.useEffect(() => {
    testToken({ uid, token });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, token]);

  const handleSubmit = React.useCallback((e: React.FormEvent<Form>) => {
    e.preventDefault();

    const attributes = ['new_password1', 'new_password2'].reduce(
      (acc, key) => ({ ...acc, [key]: e.currentTarget.elements[key].value }),
      { uid, token } as PasswordResetConfirm,
    );

    update(attributes);
  }, [update, uid, token]);

  const errors = error?.json;

  return (
    <div className="container">
      <Helmet>
        <title>WePlayed: Reset Password</title>
      </Helmet>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <h1>Reset Password</h1>
          {isSuccess ? (
            <>
              <p>You can Sign In.</p>
              <Button as={Link} size="lg" to={CLIENT_URLS.AUTH.LOGIN.toPath()}>
                Sign In
              </Button>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <NonFieldErrors errors={errors} />

              {testError?.json && (
                <LabelError error={testError.json.errors?.[0]?.detail} />
              )}

              <FormField name="uid" errors={errors} />
              <FormField name="token" errors={errors} />

              <FormField
                errors={errors}
                label="password"
                name="new_password1"
                required
              >
                <input
                  className="form-control"
                  disabled={!isTestSuccess}
                  name="new_password1"
                  required
                  type="password"
                />
              </FormField>
              <FormField
                errors={errors}
                label="repeat password"
                name="new_password2"
                required
              >
                <input
                  className="form-control"
                  disabled={!isTestSuccess}
                  name="new_password2"
                  required
                  type="password"
                />
              </FormField>
              <Button
                disabled={isLoading || !isTestSuccess}
                loading={isLoading || isTestLoading}
                size="lg"
                type="submit"
              >
                Reset Password
              </Button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
