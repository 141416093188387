import cx from 'classnames';
import * as React from 'react';

import { EmptyItemProps } from '../../types';
import * as s from './Items.m.less';

export const Empty: React.FC<EmptyItemProps> = function Empty({
  allowDrop = true,
}) {
  // here is a trick for not calculating number of columns to span
  // just set it to the value higher than even possible
  // It's doubtful to have a table with more than 100 columns here
  return (
    <tr className={cx(s.item, s.drop, !allowDrop && s.nodrop)}>
      <td colSpan={100}>
        <span>drop here</span>
      </td>
    </tr>
  );
};
