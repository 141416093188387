import cx from 'classnames';
import { identity } from 'lodash/fp';
import { BaseEntityWithPk, Player as Player$ } from 'weplayed-typescript-api';

import { FadeInImage } from 'cms/components/FadeInImage';
import { SportLabel } from 'cms/components/SportLabel';

import { defaults } from '../../constants';
import { PlayerItemProps } from '../../types';
import { Like, Link, Menu } from '../Controls';
import { EntityLink } from '../EntityLink';
import { players as def } from '../links';
import { useLink } from '../useLink';
import { Base } from './Base';
import * as s from './Items.m.less';

const d = defaults.lists.players;

export const Player = function Player<
  T extends BaseEntityWithPk = Player$,
  D extends Player$ = Player$,
>({
  getData = identity,
  item: $item,
  link,
  sport: $sport = d.sport,
  onLike,
  onLink,
  onMenu,
  ...props
}: PlayerItemProps<T, D>): JSX.Element {
  const item = getData($item);
  const url = useLink({ item, link, def });

  const {
    name, sport, photo_url, number, position, followed_by_user,
  } = item;

  const thumb = photo_url && <FadeInImage src={photo_url} alt={name} />;

  const text = `${name} ${number && ` (${number})${position ? ` ${position}` : ''}`}`;

  return (
    <Base<T>
      item={$item}
      controls={[
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link key="link" onClick={onLink} />,
        <Like
          className={cx(followed_by_user && s.visible)}
          key="like"
          like={followed_by_user}
          onClick={onLike}
        />,
        <Menu key="menu" onClick={onMenu} className={s.visible} />,
      ]}
      {...props}
    >
      <td>
        <EntityLink
          className={cx(s.thumbnail, sport?.slug)}
          url={url}
        >
          {thumb}
        </EntityLink>
      </td>
      <td><span className={s.name} title={text}>{text}</span></td>
      {$sport && <td><SportLabel sport={sport} /></td>}
    </Base>
  );
};
