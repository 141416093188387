import { groupBy, uniq } from 'lodash/fp';
import { ID } from 'weplayed-typescript-api';

import { Action, ExportsContextType, ExportsState } from './types';

const cleanup = (state: ExportsState[]): ExportsState[] => {
  const map: Record<ID, ExportsState[]> = groupBy(({ jobUid }) => jobUid, state);
  return []
  .concat(...Object.values(map).filter((v) => !v.every(({ hidden }) => hidden)))
  .sort(({ created_at: ac, uid: au }, { created_at: bc, uid: bu }) => {
    const created_at = bc.valueOf() - ac.valueOf();
    if (created_at === 0) {
      return au === bu ? 0 : ((au < bu && -1) || 1);
    }

    return created_at;
  });
};

export const reducer = (
  state: ExportsContextType['state'],
  action: Action,
): ExportsContextType['state'] => {
  switch (action.action) {
    case 'clear': {
      const type = action.notification;
      const pending = uniq(
        state.filter(({ url, errors }) => !url && !errors).map(({ jobUid }) => jobUid),
      );

      return cleanup(state
        .reduce((p, s) => {
          if ((!s.url && !s.errors)) {
            return [...p, s];
          }

          if ((s.url || s.errors)
              && pending.includes(s.jobUid)
              && s.notification_type.includes(type)
          ) {
            return [...p, { ...s, hidden: true }];
          }

          if (!s.notification_type.includes(type)) {
            return [...p, s];
          }

          return p;
        }, []));
    }

    case 'dismiss': return cleanup(state
      .map((s) => (s.uid === action.uid && s.jobUid === action.jobUid && (s.url || s.errors)
        ? { ...s, hidden: true }
        : s)));

    case 'update': {
      const update = action.state.reduce(
        (p, { uid: jobUid, type, created_at, files, notification_type }) => [
          ...p,
          ...files.map(
            ({ pk: _pk, ...s }) => ({ ...s, type, created_at, jobUid, notification_type }),
          ),
        ],
        [],
      );

      return cleanup(update.reduce((p, s) => {
        const idx = p.findIndex(({ uid, jobUid }) => s.uid === uid && s.jobUid === jobUid);

        if (idx !== -1) {
          const item = p[idx];

          if (!item.hidden) {
            return [
              ...p.slice(0, idx),
              s,
              ...p.slice(idx + 1),
            ];
          }
        } else {
          return [...p, s];
        }

        return p;
      }, state));
    }

    default: return state;
  }
};
