import * as React from 'react';

import { useUserRoles } from 'common/hooks/useUserRoles';

import { Recipient, UseRecipientsType } from './types';

export const useRecipients: UseRecipientsType = function useRecipients({
  enabled,
  query,
}) {
  const {
    user_roles: { data: user_roles, isLoading },
  } = useUserRoles({ enabled, status: 'active' });

  const data = React.useMemo<Recipient[]>(
    () => {
      const q = query?.toLocaleLowerCase();

      return user_roles?.map(({
        avatar_480_480_url,
        avatar_64x64_url,
        avatar_url,
        status,
        user_email: email,
        user_name: full_name,
        user_pk: pk,
      }) => ({ avatar_480_480_url, avatar_64x64_url, avatar_url, email, full_name, pk, status }))
      .filter(({ full_name, email, status }) => status === 'Active' && (
        q
          ? full_name.toLocaleLowerCase().includes(q) || email.toLocaleLowerCase().includes(q)
          : true
      ));
    },
    [user_roles, query],
  );

  return {
    data,
    isLoading,
  };
};
