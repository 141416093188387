import * as cx from 'classnames';
import * as React from 'react';

import * as s from './Loading.m.less';
import { Props } from './types';

export const Loading: React.FC<Props> = function Loading({
  className, dark, loading = false, ...rest
}) {
  return (
    <div className={cx(s.root, dark && s.dark, loading && s.loading, className)} {...rest}>
      <div />
    </div>
  );
};
