import * as cx from 'classnames';
import * as React from 'react';

import { Avatar } from 'cms/components/Avatar';

import * as s from './PageHeader.m.less';
import { PageHeaderProps } from './types';

export const PageHeader: React.FC<PageHeaderProps> = function PageHeader({
  user, level = 1, children, className,
}) {
  const cn = cx(s.header, className);

  switch (level) {
    case 1: return (
      <h2 className={cn}>
        {user && <Avatar user={user} size="small" className={s.user} />}
        {children}
      </h2>
    );

    case 2: return (
      <h3 className={cn}>{children}</h3>
    );

    case 3: return (
      <h4 className={cn}>{children}</h4>
    );

    default: return (
      <h1 className={cn}>{children}</h1>
    );
  }
};
