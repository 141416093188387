import { What } from 'weplayed-typescript-api';

import { SignalListener, UseDataSignalType } from './types';

const listeners = new Set<SignalListener<unknown, What, unknown>>();

const subscribe: ReturnType<UseDataSignalType>['subscribe'] = (listener) => {
  listeners.add(listener);

  return (): void => { listeners.delete(listener); };
};

const notify: ReturnType<UseDataSignalType>['notify'] = (signal) => {
  listeners.forEach((listener): void => listener(signal));

  return signal.optimistic;
};

export const useDataSignal: UseDataSignalType = function useDataSignal() {
  return {
    notify,
    subscribe,
  };
};
