import { What } from 'weplayed-typescript-api';

import { Recipient } from 'cms/hooks/useRecipients/types';

import { ShareData } from './types';

export const isTypeOf = <T extends What>(
  context: unknown,
  type: T,
): context is ShareData<T> => (context as ShareData<T>)?.type === type;

export const toLabel = ({ full_name }: Recipient): string => full_name;

export const toData = (email: string): Recipient => ({ email, full_name: email });
