import cx from 'classnames';

import { LikeButton } from 'cms/components/LikeButton';

import * as s from './Controls.m.less';
import { LikeProps } from './types';

export const Like = function Like({
  disabled,
  hidden,
  like,
  onClick,
}: LikeProps): JSX.Element {
  return (
    <LikeButton
      className={cx(s.control, s.like, hidden && s.hidden)}
      disabled={disabled}
      liked={like}
      onClick={onClick}
    />
  );
};
