import { useMutation } from 'react-query';
import { games, What } from 'weplayed-typescript-api';

import { logger } from 'common/utils/logger';

import { about, useDataSignal } from '../useDataSignal';
import { UseGamesReturnType, UseGamesType } from './types';

export const useGames: UseGamesType = function useGames() {
  const { notify } = useDataSignal();

  const like: UseGamesReturnType['like'] = useMutation(
    ({ uid, like: l }) => notify(about.liked(What.GAMES, uid, l, games.like({ uid, like: l }))),
    {
      onError: (error, { uid, like: liked }) => {
        notify(about.liked(What.GAMES, uid, !liked));
        logger.error('Game liking failed', { message: error.message });
      },
    },
  );

  const bulkLike: UseGamesReturnType['bulkLike'] = useMutation(
    ({ uids, like: liked }) => notify(
      about.liked(What.GAMES, uids, liked, games.like({ uid: uids, like: liked })),
    ),
    {
      onError: (error, { uids, like: liked }) => {
        notify(about.liked(What.GAMES, uids, !liked));
        logger.error('Bulk Game liking failed', { message: error.message });
      },
    },

  );

  return { like, bulkLike };
};
