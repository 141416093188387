import { castArray } from 'lodash/fp';
import { ID, What } from 'weplayed-typescript-api';

import { Signal } from '../types';

const signal = 'reviewed' as const;

interface ReviewedSignal<
  W extends What.MOMENTS,
  T
> extends Signal<typeof signal, W, T> {
  reviewed: boolean;
  what: W,
}

export const test = <W extends What.MOMENTS>(
  s: Signal<unknown, What, unknown>,
  w: W,
): s is ReviewedSignal<W, unknown> => s.signal === signal && s.what === w;

export const notify = <W extends What.MOMENTS, T>(
  what: W,
  uid: ID | ID[],
  reviewed: boolean,
  optimistic?: Promise<T>,
): ReviewedSignal<W, T> => ({
  optimistic,
  signal,
  reviewed,
  uid: castArray(uid),
  what,
});
