import { mergeAll } from 'lodash/fp';
import * as React from 'react';
import { InfiniteQueryConfig, useInfiniteQuery, useQuery } from 'react-query';
import { search, What, WhatType } from 'weplayed-typescript-api';

import { signalIs } from '../useDataSignal';
import { Signal } from '../useDataSignal/types';
import { PAGINATED_PATH, useQueryCacheUpdate } from '../useQueryCacheUpdate';
import { QueryCacheUpdateEntityConfig } from '../useQueryCacheUpdate/types';
import {
  SearchParams, UseSearchReturnType, UseSearchSectionArgs,
  UseSearchSectionReturnType, UseSearchType, WhatSearch,
} from './types';

const PAGE_SIZE = 30;

/* eslint-disable @typescript-eslint/no-explicit-any */
const defaultOptions: InfiniteQueryConfig<any, any> = {
  // staleTime: 60 * 1000,
  refetchOnWindowFocus: false,
  // keepPreviousData: true,
};

const getDataKey = (key: What, params: SearchParams, count: number): unknown[] => [
  'search-data', key, count, params,
].filter(Boolean);

const REFRESH = 30000;

function useSearchSection<W extends WhatSearch, T extends WhatType<W> = WhatType<W>>({
  context,
  count,
  data,
  enabled,
  facetFilter,
  facets,
  key,
  params,
  refresh,
}: UseSearchSectionArgs<W, T>): UseSearchSectionReturnType<T> {
  const config = React.useMemo(() => {
    const predicate = getDataKey(key, params, count);
    const conf: QueryCacheUpdateEntityConfig<What> = {
      context,
      what: key,
      predicate,
      path: PAGINATED_PATH,
      invalidate: (
        signal: Signal<unknown, What, unknown>,
        keys,
      ) => [
        keys,
        signalIs.removed(signal, key as What.MOMENTS | What.COLLECTIONS),
      ],
    };

    return [conf];
  }, [context, count, key, params]);

  useQueryCacheUpdate(config);

  const dataQ: UseSearchSectionReturnType<T>['data'] = useInfiniteQuery(
    getDataKey(key, params, count),
    async (_, __, ___, ____, page?: number) => {
      const { items, total, next } = await data(params, count, page);
      return {
        items,
        next,
        total,
      };
    },
    {
      ...defaultOptions,
      enabled: data && enabled,
      refetchInterval: refresh,
      getFetchMore: ({ next }) => next,
    },
  );

  const facetsQ: UseSearchSectionReturnType<T>['facets'] = useQuery(
    ['search-facets', key, params, facetFilter],
    () => facets({ ...params, ...facetFilter }),
    {
      ...defaultOptions,
      keepPreviousData: Boolean(facetFilter),
      enabled: facets && enabled,
      refetchInterval: refresh,
      staleTime: 30000,
    },
  );

  return { data: dataQ, facets: facetsQ };
}

export const useSearch: UseSearchType = function useSearch({
  base,
  context,
  data = true,
  empty = false,
  enabled,
  facetFilter,
  facets = true,
  pageSize = PAGE_SIZE,
  query,
  refresh,
  summary: loadSummary = true,
}) {
  const summaryProps = base || { text: query.text || undefined };

  const summary: UseSearchReturnType['summary'] = useQuery(
    ['search', summaryProps],
    () => search.summary(summaryProps),
    {
      ...defaultOptions,
      enabled: loadSummary && Boolean(enabled && (base || query?.text || empty)),
      refetchInterval: refresh && REFRESH,
    },
  );

  const q = React.useMemo(() => mergeAll([{}, base, query]), [base, query]);
  const qp = React.useMemo(() => mergeAll([{ sort: 'relevance' }, base, query]), [base, query]);

  const active = (Object.values(What) as WhatSearch[])
    .filter((k) => (query?.text || empty)
      && !summary.isLoading
      && (!loadSummary || summary.data?.[k] !== 0)
      && (
        enabled === undefined
        || (Array.isArray(enabled) && enabled.includes(k))
        || (enabled === k)
      ));

  const moments: UseSearchReturnType[What.MOMENTS] = useSearchSection({
    context,
    count: pageSize,
    data: data && search.moments.data,
    enabled: active.includes(What.MOMENTS),
    facetFilter,
    facets: (q?.text || empty) && facets && search.moments.facets,
    key: What.MOMENTS,
    params: q,
    refresh: refresh && REFRESH,
  });

  const collections: UseSearchReturnType[What.COLLECTIONS] = useSearchSection({
    context,
    count: pageSize,
    data: data && search.collections.data,
    enabled: active.includes(What.COLLECTIONS),
    facetFilter,
    facets: (q?.text || empty) && facets && search.collections.facets,
    key: What.COLLECTIONS,
    params: q,
    refresh: refresh && REFRESH,
  });

  const games: UseSearchReturnType[What.GAMES] = useSearchSection({
    context,
    count: pageSize,
    data: data && search.games.data,
    enabled: active.includes(What.GAMES),
    facetFilter,
    facets: (q?.text || empty) && facets && search.games.facets,
    key: What.GAMES,
    params: q,
    refresh: refresh && REFRESH,
  });

  const players: UseSearchReturnType[What.PLAYERS] = useSearchSection({
    context,
    count: pageSize,
    data: data && search.players.data,
    enabled: active.includes(What.PLAYERS),
    facetFilter,
    facets: (q?.text || empty) && facets && search.players.facets,
    key: What.PLAYERS,
    params: qp,
    refresh: refresh && REFRESH,
  });

  return { summary, moments, collections, games, players };
};
