import * as React from 'react';

import * as s from './Items.m.less';

export const Blocked: React.FC = function Blocked() {
  return (
    <div className={s.blocked}>
      This content has been blocked in all areas, including the game mode
    </div>
  );
};
