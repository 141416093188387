import * as s from './Header.m.less';
/**
 * Names sorted in order appearing in tables
 */
import { HeadersDescription } from './types';

export const ListHeaders: HeadersDescription = {
  ATHLETE: ['Athlete', s.athlete],
  BLOCK: [true, s.control],
  CREATED: ['Created', s.date],
  CREATOR: ['Creator', s.creator],
  DATE: ['Date', s.date],
  EDITED: ['Last Edited', s.date],
  GAME: ['Game', s.game],
  LIKE: [true, s.control],
  LINK: [true, s.control],
  MENU: [true, s.control],
  MOMENTS: ['Moments', s.moments],
  NAME: [true, s.name],
  PIN: [true, s.control],
  POSITION: [true, s.position],
  SCORE: ['Score', s.score],
  SPORT: ['Sport', s.sport],
  TEAMS: ['Teams', s.teams],
  TIME: ['Time', s.date],
  THUMBNAIL: [true, s.thumbnail],
  VISIBILITY: [true, s.control],
} as const;
