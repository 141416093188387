import * as React from 'react';
import { FormCheck, Modal } from 'react-bootstrap';

import { Button } from 'cms/components/Button';

import { ConfirmationModalProps } from './types';

export const ConfirmationModal: React.FC<ConfirmationModalProps> = function ConfirmationModal({
  cancelDisabled,
  cancelText,
  children,
  confirmDisabled,
  confirmText,
  confirmVariant,
  disabled,
  id,
  loading,
  message,
  onCancel,
  onConfirm,
  rememberText = 'don\'t show this message again',
  show = true,
  showRemember = false,
  title,
}) {
  const checkbox = React.useRef<HTMLInputElement>();

  const handleDecision = React.useCallback((choice: boolean) => {
    const remember = showRemember ? checkbox.current?.checked : undefined;

    return choice ? onConfirm(remember) : onCancel(remember);
  }, [onCancel, onConfirm, showRemember]);

  const handleConfirm = React.useCallback(() => handleDecision(true), [handleDecision]);

  const handleCancel = React.useCallback(() => handleDecision(false), [handleDecision]);

  return (
    <Modal
      id={id}
      show={show}
      keyboard
      onHide={onCancel}
      onEscapeKeyDown={onCancel}
      backdropClassName="modal-backdrop-blackout"
    >
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {message || children}
        {rememberText && showRemember && (
          <FormCheck.Label className="d-flex justify-content-center align-items-center">
            <FormCheck.Input
              className="position-static mt-0 mb-0 mr-2"
              name="remember"
              ref={checkbox}
              value="true"
            />
            {rememberText}
          </FormCheck.Label>
        )}
      </Modal.Body>
      <Modal.Footer>
        {onConfirm && (
          <Button
            data-testid="confirmation-modal-ok"
            disabled={confirmDisabled || disabled || loading}
            id={id ? `${id}-confirm` : undefined}
            loading={loading}
            onClick={handleConfirm}
            variant={confirmVariant}
          >
            {confirmText || 'Ok'}
          </Button>
        )}
        {onCancel && (
          <Button
            data-testid="confirmation-modal-cancel"
            disabled={cancelDisabled || disabled || loading}
            id={id ? `${id}-cancel` : undefined}
            onClick={handleCancel}
            variant="default"
          >
            {cancelText || 'Cancel'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
