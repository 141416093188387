/**
 * A set of different property accessors
 */
import {
  EntityWithFollow, EntityWithLike, EntityWithPublication,
} from 'weplayed-typescript-api';

import { EntitiesListConfig, EntitiesListType, EntitiesSize } from './types';

export const getLike = (item: EntityWithLike): boolean => Boolean(item?.liked_by_user);

export const getFollow = (item: EntityWithFollow): boolean => Boolean(item?.followed_by_user);

export const getPin = (item: EntityWithPublication): boolean => (
  Boolean(item?.pin && !item.pin.expires)
);

export const getBlock = (item: EntityWithPublication): boolean => Boolean(item?.blocked);

export const isEntitiesListSettings = (
  config: Record<string, unknown>,
): config is EntitiesListConfig => (
  config?.type
  && Object.values(EntitiesListType).includes(config.type as EntitiesListType)
  && config.size
  && Object.values(EntitiesSize).includes(config.size as EntitiesSize)
);
