import * as cx from 'classnames';
import * as React from 'react';

import { momentDuration } from 'common/utils/moments';

import * as s from './MomentDescription.m.less';
import { renderReadOnly } from './ReadOnlyBuilder';
import { ReadonlyProps } from './types';

const MomentDescriptionReadonly: React.FC<ReadonlyProps> = function MomentDescriptionReadonly({
  className, inline = false,
  textareaPlaceholder, moment, link, withDuration,
  onClick,
}) {
  const text = moment?.description || moment?.name;
  const name = moment?.name;
  const element = text
    ? renderReadOnly(text, !moment?.description, {
      link: !onClick && link,
    })
    : textareaPlaceholder || '';

  const durationText = withDuration
    ? ` (${momentDuration(moment).toFixed(1)}s)`
    : '';

  if (!inline) {
    return (
      <div
        className={cx(s.textbox, !text && s.textboxPlaceholder, className)}
        onClick={onClick}
        title={name}
      >
        {element}
        {durationText}
      </div>
    );
  }

  return (
    <span className={className} title={name}>
      {element}
      {durationText}
    </span>
  );
};

const MomentDescriptionReadonlyMemo = React.memo(MomentDescriptionReadonly);

export { MomentDescriptionReadonlyMemo as MomentDescriptionReadonly };
