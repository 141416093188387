import { LinkType } from 'weplayed-typescript-api';

export const LinkName: Record<LinkType, string> = {
  [LinkType.PRIVATE]: 'CMS link',
  [LinkType.PUBLIC]: 'Public link',
};

export const LinkDescription: Record<LinkType, string> = {
  [LinkType.PRIVATE]: 'Recipient must be a CMS user to view content',
  [LinkType.PUBLIC]: 'Can be viewed by all recipients and does not require a CMS access',
};

export const SuccessMessage = 'Link was copied to your clipboard';
