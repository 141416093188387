import * as cx from 'classnames';
import * as React from 'react';

import * as s from './ColoredEntity.m.less';
import { ColoredEntityType } from './types';

export const ColoredEntity: ColoredEntityType = function ColoredEntity({
  as: As = 'span',
  children,
  color,
  className,
  style,
  ...props
}) {
  const st: React.CSSProperties = React.useMemo(
    () => (color ? { ...style, backgroundColor: `#${color}` } : style),
    [color, style],
  );

  return <As style={st} className={cx(s.root, className)} {...props}>{children}</As>;
};
