import { BaseEntityWithPk, Game as Game$ } from 'weplayed-typescript-api';

import { defaults } from '../constants';
import { Game } from '../Items/Tiles/Game';
import { GamesProps, TilesExtraProps, WithGetData } from '../types';
import { useControls } from '../useControls';
import { getLike } from '../utils';
import { Base } from './Base';

const d = defaults.tiles.games;

// eslint-disable-next-line react/function-component-definition
export function Games<
  T extends BaseEntityWithPk = Game$,
  D extends Game$ = Game$
>({
  getData,
  hideLabel = d.hideLabel,
  link,
  onLike,
  onLink,
  onMenu,
  ...props
}: Omit<GamesProps<T, D>, 'type'> & TilesExtraProps & WithGetData<T, D>): JSX.Element {
  const handlers = useControls({ onLike, onLink, getLike, onMenu });

  const renderer = (p): JSX.Element => (
    <Game<T, D>
      {...p}
      {...handlers}
      getData={getData}
      hideLabel={hideLabel}
      link={link}
    />
  );

  return (
    <Base<T>
      {...props}
      renderer={renderer}
    />
  );
}
