import cx from 'classnames';
import { identity, noop } from 'lodash/fp';
import {
  BaseEntityWithPk, Collection as $Collection,
} from 'weplayed-typescript-api';

import { formatDate } from 'common/utils/dates';
import { momentsCount } from 'common/utils/moments';
import { formatDistance } from 'common/utils/time';

import { FadeInImage } from 'cms/components/FadeInImage';
import { GameLabel } from 'cms/components/GameLabel';
import { SportLabel } from 'cms/components/SportLabel';

import { defaults } from '../../constants';
import { CollectionItemProps, EntitiesSize } from '../../types';
import { getBlock, getPin } from '../../utils';
import { Block, Creator, Like, Link, Menu, Pin, Visibility } from '../Controls';
import { EntityLink } from '../EntityLink';
import { collections as def } from '../links';
import { useLink } from '../useLink';
import { Base } from './Base';
import { Blocked } from './Blocked';
import * as s from './Items.m.less';

const d = defaults.tiles.collections;

export const Collection = function Collection<
  T extends BaseEntityWithPk = $Collection,
  D extends $Collection = $Collection
>({
  athlete = d.athlete,
  blocking,
  created = d.created,
  creator = d.creator,
  edited: _edited = d.edited,
  game = d.game,
  getData = identity,
  item: $item,
  link,
  onBlock,
  onLike,
  onLink,
  onMenu,
  onPin,
  pinning,
  position,
  sport = d.sport,
  visibility = d.visibility,
  ...props
}: CollectionItemProps<T, D>): JSX.Element {
  const item = getData($item);
  const url = useLink({ item, link, def });

  const {
    algo_created, algo_maintained, moment_count, is_hidden,
    name, thumbnail, created_by, ctime, liked_by_user,
    dedicated_game, dedicated_player, sport: sp, visibility: visible,
  } = item;

  const $game = dedicated_game;
  const mc = `${dedicated_player?.name ? '・' : ''}${momentsCount(moment_count)}`;
  const thumb = (
    <>
      {thumbnail && <FadeInImage src={thumbnail} alt={name} className={s.thumbnail} />}
      {typeof position === 'number' && <span className={s.position}>{position}</span>}
      {is_hidden && <Blocked />}
    </>
  );

  const algoText = algo_created
    && `System Playlist${algo_maintained ? ' - updated automatically' : ''}`;
  const algo = algoText && (
    <span className={cx(s.algo, algo_maintained && s.auto)} title={algoText}>
      {algoText}
    </span>
  );
  const cdate = created && ctime ? Date.parse(ctime) : null;

  return (
    <Base<T>
      item={$item}
      controls={[
        <Creator
          className={s.creator}
          creator={created_by}
          key="creator"
          onClick={creator && noop}
        />,
        <Pin
          className={s.visible}
          disabled={!visible}
          key="pin"
          loading={pinning}
          onClick={onPin}
          pin={getPin(item)}
        />,
        <Block
          block={getBlock(item)}
          className={s.visible}
          disabled={!visible}
          key="block"
          loading={blocking}
          onClick={onBlock}
        />,
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          disabled={!visible}
          key="link"
          onClick={onLink}
        />,
        <Like
          className={cx(liked_by_user && s.visible)}
          key="like"
          like={liked_by_user}
          onClick={onLike}
        />,
        <Visibility
          className={s.visible}
          key="visibility"
          onClick={visibility && !visible && noop}
          visible={false}
        />,
        <Menu
          className={s.visible}
          key="menu"
          onClick={onMenu}
        />,
      ]}
      {...props}
    >
      <EntityLink
        className={cx(s.rtile, sp?.slug)}
        url={moment_count !== 0 && url}
      >
        {thumb}
        {algo}
      </EntityLink>
      <div className={s.extra}>
        <div className={s.name} title={name}>{name}</div>
        <div className={s.info}>
          {sport
            ? (
              <SportLabel sport={sp}>
                {athlete && dedicated_player && `・${dedicated_player.name}`}
              </SportLabel>
            )
            : athlete && dedicated_player?.name}
          {game ? (
            <GameLabel game={$game} className={s.score}>
              {mc}
            </GameLabel>
          ) : mc }
          {cdate && (
            <span className={s.created} title={formatDate(cdate)}>
              {formatDistance(cdate, props.size === EntitiesSize.COMPACT)}
            </span>
          )}
        </div>
      </div>

    </Base>
  );
};
