import { castArray } from 'lodash/fp';
import { ID, What, WhatType } from 'weplayed-typescript-api';

import { Signal } from '../types';

const signal = 'updated' as const;

export interface UpdatedSignal<
  W extends What.MOMENTS | What.COLLECTIONS,
  T
> extends Signal<typeof signal, W, T> {
  what: W;
  item: W extends What.MOMENTS ? WhatType<W> : Partial<WhatType<W>>;
}

export const test = <W extends What.MOMENTS | What.COLLECTIONS>(
  s: Signal<unknown, What, unknown>,
  w: W,
): s is UpdatedSignal<W, unknown> => s.signal === signal && s.what === w;

export const notify = <W extends What.MOMENTS | What.COLLECTIONS, T>(
  what: W,
  uid: ID | ID[],
  item: W extends What.MOMENTS ? WhatType<W> : Partial<WhatType<W>>,
): UpdatedSignal<W, T> => ({
  item,
  signal,
  uid: castArray(uid),
  what,
});
