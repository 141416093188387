import * as hidden from './hidden';
import * as liked from './liked';
import * as pinned from './pinned';
import * as published from './published';
import * as removed from './removed';
import * as reviewed from './reviewed';
import * as updated from './updated';

export const about = {
  hidden: hidden.notify,
  liked: liked.notify,
  pinned: pinned.notify,
  published: published.notify,
  removed: removed.notify,
  reviewed: reviewed.notify,
  updated: updated.notify,
};

export const signalIs = {
  hidden: hidden.test,
  liked: liked.test,
  pinned: pinned.test,
  published: published.test,
  removed: removed.test,
  reviewed: reviewed.test,
  updated: updated.test,
};
