import { InfiniteQueryResult } from 'react-query';
import {
  Collection, HttpError, ID, Moment, User,
} from 'weplayed-typescript-api';

import { MomentsCollectionsUpdate } from 'cms/hooks/useMoments/types';

export type AddToCollectionModalProps = {
  collectionIDs: ID[];
  onUpdate(update: MomentsCollectionsUpdate): void;
  onClose(): void;
  updating: boolean;
  user: User;
} & ({
  moment: Moment;
  moments?: Moment[];
} | {
  moment?: Moment;
  moments: Moment[];
});

export enum CreatorFilter {
  ALL = 'all', OWN = 'own',
}

export type ReducerState = Omit<MomentsCollectionsUpdate, 'moments'>;

export type ReducerAction = ({
  collection: Collection;
  momentIDs: ID[];
} | ID);

export enum SortOrder {
  MODIFIED = 'recency',
  ALPHABETICALLY = 'name',
}

interface UseDataArgs {
  sort?: SortOrder;
  text?: string;
  uid?: ID;
}

export interface UseDataReturnType {
  collections: InfiniteQueryResult<Collection[], HttpError>;
}

export type UseData = (args: UseDataArgs) => UseDataReturnType;
