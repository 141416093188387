import { StringTuple } from 'common/types';

export const downloadSuccess = 'Email with download with be sent shortly';
export const downloadFail = 'Sorry, you do not have the necessary content rights to get this download';
export const downloadError = 'Error occurred while requesting the download';
export const bulkDeleteQuestion: StringTuple = [
  'Are you sure you want to completely delete {0} moment',
  'Are you sure you want to completely delete {0} moments',
];

export const collectionsMoments: StringTuple = [
  'Playlist updated successfully',
  '{0} playlists updated successfully',
];

const changes = 'Changes will apply to your site in 24 hours.';
export const pinnedSuccess = `Moment pinned. ${changes}`;
export const unpinnedSuccess = `Moment unpinned. ${changes}`;

export const publishedSuccess = `Moment published. ${changes}`;
export const unpublishedSuccess = `Moment unpublished. ${changes}`;
