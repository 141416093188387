import * as React from 'react';
import {
  Collection, PublishDestination, SportSlug, What,
} from 'weplayed-typescript-api';

import { renderColoredEntity } from 'common/components/ColoredEntity';
import { useAuth } from 'common/hooks/useAuth';
import { getLabelForSlug } from 'common/utils/sports';

import { useCollection } from 'cms/hooks/useCollection';
import { useTop100 } from 'cms/hooks/useTop100';

import { EntryState, UseDataArgs, UseDataReturnType } from './types';

const names = ['playlist', 'playlists'] as const;

export const useData = function useData<W extends PublishDestination>({
  status,
  uid,
  what,
  where,
}: UseDataArgs<Collection, W>): UseDataReturnType<Collection> {
  const { profile: user } = useAuth();
  const { conference, cms_org_id } = user;
  const orgId = conference?.pk || cms_org_id;

  const { collection, pins } = useCollection({
    pins: true,
    uid,
    summary: true,
  });

  const { collections: {
    featured: top,
    pin: onPin,
  } } = useTop100({
    enabled: what && where && What.COLLECTIONS,
    recommended: false,
    sport: where === What.SPORTS ? what as SportSlug : undefined,
    type: where,
    uid: where === What.SPORTS ? orgId : what,
  });

  const blocking = React.useMemo(
    () => {
      if (collection.data) {
        const { dedicated_team_athlete } = collection.data;
        const {
          blocked_from_game: game = false,
          blocked_from_org_schools: schools = [],
          blocked_from_org_sports: sports = [],
          blocked_from_org: org = false,
          blocked_from_team_athlete: athlete = false,
          blocked_from_team: team = false,
        } = collection.data;

        return {
          athletes: athlete && dedicated_team_athlete ? [dedicated_team_athlete.pk] : [],
          org,
          team,
          game,
          sports,
          schools,
        };
      }
    },
    [collection.data],
  );

  const isReady = Boolean(collection.data && blocking && pins.data);

  const org: EntryState<Collection, What.ORGS>[] | undefined = React.useMemo(() => {
    if (!isReady || !conference) {
      return;
    }

    return [{
      blocked: blocking.org,
      name: 'Home page',
      loading: where === What.ORGS && what === orgId && top.isLoading,
      pinned: Boolean(pins.data.organizations[orgId]),
      pk: orgId,
      top: where === What.ORGS && what === orgId && top,
    }];
  }, [
    blocking?.org, conference, isReady, orgId, pins.data?.organizations,
    what, where, top,
  ]);

  const athletes: EntryState<Collection, What.ATHLETES>[] | undefined = React.useMemo(() => {
    if (!isReady || conference || !collection.data.dedicated_team_athlete) {
      return;
    }

    const { pk, name } = collection.data.dedicated_team_athlete;

    return [{
      pk,
      loading: where === What.ATHLETES && what === pk && top.isLoading,
      name,
      blocked: blocking.athletes.includes(pk),
      pinned: Boolean(pins.data.athletes?.[pk]),
      top: where === What.ATHLETES && what === pk && top,
    }];
  }, [
    blocking?.athletes, collection.data?.dedicated_team_athlete,
    conference, isReady, pins.data?.athletes, what, where, top,
  ]);

  const teams: EntryState<Collection, What.TEAMS>[] | undefined = React.useMemo(() => {
    if (!isReady || conference || !collection.data.dedicated_team) {
      return;
    }

    const { dedicated_team } = collection.data;

    return [{
      blocked: blocking.team,
      name: renderColoredEntity(
        getLabelForSlug(dedicated_team.sport),
        dedicated_team.primary_color,
      ),
      pinned: Boolean(pins.data.teams?.[dedicated_team.pk]),
      pk: dedicated_team.pk,
      top: where === What.TEAMS && what === dedicated_team.pk && top,
    }];
  }, [
    blocking?.team, collection.data, conference, isReady, pins.data?.teams,
    what, where, top,
  ]);

  const sports: EntryState<Collection, What.SPORTS>[] | undefined = React.useMemo(() => {
    if (!isReady || !conference || !collection.data.sport_slugs) {
      return;
    }

    const { sport_slugs } = collection.data;

    return sport_slugs.map((sport) => ({
      pk: sport,
      name: getLabelForSlug(sport),
      blocked: blocking.sports.includes(sport),
      pinned: Boolean(pins.data.sports?.[sport]),
      top: where === What.SPORTS && what === sport && top,
    }));
  }, [
    blocking?.sports, collection.data, conference, isReady, pins.data?.sports,
    what, where, top,
  ]);

  const schools: EntryState<Collection, What.SCHOOLS>[] | undefined = React.useMemo(() => {
    if (!isReady || !conference) {
      return;
    }

    const $schools = conference.schools
      .filter(({ pk }) => (collection.data.org_ids || []).includes(pk)) || [];

    return $schools.map((school) => ({
      pk: school.pk,
      name: renderColoredEntity(school.name, school.primary_color),
      blocked: blocking.schools.includes(school.pk),
      pinned: Boolean(pins.data.organizations?.[school.pk]),
      top: where === What.SCHOOLS && what === school.pk && top,
    }));
  }, [
    blocking?.schools, collection.data?.org_ids, conference, isReady, pins.data?.organizations,
    what, where, top,
  ]);

  return {
    athletes,
    blocking: status?.publish.includes(uid),
    item: collection.data,
    loading: collection.isLoading || pins.isLoading,
    names,
    onPin,
    org,
    schools,
    sports,
    teams,
  };
};
