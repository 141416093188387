import { Game as Game$, What } from 'weplayed-typescript-api';

import { makeTokens } from 'common/utils/strings';

import { Base } from './Base';
import { Games as List } from './Lists/Games';
import { Games as Tiles } from './Tiles/Games';
import { GamesProps } from './types';

const renderers = [Tiles, List] as const;
const toTokens = <T extends Game$>({ name }: T): string[] => makeTokens(name);

// eslint-disable-next-line react/function-component-definition
export function Games<
  T extends Game$ = Game$
>(props: GamesProps<T>): JSX.Element {
  return (
    <Base
      renderers={renderers}
      toTokens={toTokens}
      what={What.GAMES}
      {...props}
    />
  );
}
