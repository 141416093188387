import * as React from 'react';
import { Collection, PublishDestination } from 'weplayed-typescript-api';

import { Entities } from 'cms/components/Entities';

import { PublishSidebar } from './PublishSidebar';
import { PublishSidebarProps } from './types';
import { useData } from './useCollectionsData';

export const CollectionPublishSidebar: React.FC<
  Omit<PublishSidebarProps<Collection, PublishDestination>, 'useData' | 'renderer'>
> = function CollectionPublishSidebar(props) {
  return (
    <PublishSidebar<Collection, PublishDestination>
      {...props}
      useData={useData}
      renderer={Entities.Collections}
    />
  );
};
