import { ReactComponent as Hide } from '@mdi/svg/svg/cancel.svg';
import { ReactComponent as Clone } from '@mdi/svg/svg/content-copy.svg';
import { ReactComponent as Remove } from '@mdi/svg/svg/delete.svg';
import { ReactComponent as Download } from '@mdi/svg/svg/download.svg';
import { ReactComponent as Info } from '@mdi/svg/svg/information-outline.svg';
import { ReactComponent as Publish } from '@mdi/svg/svg/monitor.svg';
import { ReactComponent as Name } from '@mdi/svg/svg/pencil.svg';
import { ReactComponent as Share } from '@mdi/svg/svg/share-variant.svg';
import * as React from 'react';

import { Menu, MenuButtonHoC, MenuGroup } from '../Menus';
import { CollectionActionsDropdownProps, DropdownActionType } from './types';

export const CollectionActionDropdown: React.FunctionComponent<
CollectionActionsDropdownProps
> = function CollectionActionDropdown({
  className,
  invoke,
  item,
  onClose,
  placement,
  showClone,
  showDownload,
  showEdit,
  showInfo,
  showPublish,
  showRemove,
  showShare,
  showVisibility,
  status,
  target,
}) {
  const onClick = React.useCallback(
    // eslint-disable-next-line prefer-arrow-callback
    function onClick<T extends DropdownActionType>(e: React.MouseEvent<HTMLButtonElement>) {
      onClose();

      const action = e.currentTarget.name as T;
      const uid = item.pk;

      if (action === 'hide') {
        invoke({ action, uid, hidden: !item.is_hidden });
      } else {
        invoke({ action, uid });
      }
    },
    [invoke, item.is_hidden, item.pk, onClose],
  );

  const Button = React.useMemo(() => MenuButtonHoC<DropdownActionType>({
    onClick,
    pk: item?.pk,
    prefix: 'moment-actions',
    status,
  }), [onClick, item?.pk, status]);

  return (
    <Menu
      className={className}
      item={item}
      onClose={onClose}
      placement={placement}
      target={target}
    >
      {item && (
        <>
          <MenuGroup>
            <Button action="info" icon={Info} show={showInfo}>Info</Button>
          </MenuGroup>
          <MenuGroup>
            <Button action="download" icon={Download} show={showDownload}>Download</Button>
          </MenuGroup>
          {!item.private && (
            <MenuGroup>
              <Button action="share" icon={Share} show={showShare}>Share</Button>
              <Button action="publish" icon={Publish} show={showPublish}>Publish</Button>
            </MenuGroup>
          )}
          <MenuGroup>
            <Button action="update" icon={Name} show={showEdit}>Edit</Button>
            <Button action="clone" icon={Clone} show={showClone}>Clone</Button>
            <Button action="hide" danger={!item.is_hidden} icon={Hide} show={showVisibility}>
              {item.is_hidden ? 'Unblock' : 'Block'}
            </Button>
          </MenuGroup>

          <MenuGroup>
            <Button action="remove" danger icon={Remove} show={showRemove}>Delete</Button>
          </MenuGroup>
        </>
      )}
    </Menu>
  );
};
