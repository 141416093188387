import { debounce, memoize } from 'lodash/fp';
import * as React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { ID } from 'weplayed-typescript-api';

import { EmbedParams } from 'common/types';
import { logger } from 'common/utils/logger';

import {
  embedParamsDefault, embedParamsDescription,
} from 'cms/containers/EmbedRouter/constants';
import { CLIENT_URLS } from 'cms/routes';

import {
  ApproxCoef, DefaultWidth, MaxWidth, MinWidth, WidthStep,
} from './constants';
import * as s from './EmbedMomentForm.m.less';
import { EmbedMomentFormProps } from './types';

const descPairs = Object.entries(embedParamsDescription) as [[ keyof EmbedParams, string ]];

const { MOMENT: { EMBED } } = CLIENT_URLS;

const debouncedWidthHandler = memoize((handler: (n: number) => void) => {
  const h = debounce(500, handler);

  return (
    { currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>,
  ): void => h(parseInt(value, 10));
});

const getIframeCode = (
  uid: ID,
  width: number,
  height: number,
  params: EmbedParams,
): string => {
  const queryParams = Object.fromEntries(
    Object.entries(params)
      .filter(([key, value]): boolean => embedParamsDefault[key] !== value),
  ) as { [key: string]: string };

  queryParams.utm_source = 'weplayed';
  queryParams.utm_medium = 'embed';
  queryParams.utm_content = 'mo-00';

  queryParams.m = uid;
  const path = EMBED.buildPath({ queryParams });

  const iframe = {
    src: new URL(path, window.location.toString()).toString(),
    width,
    height,
    frameborder: 'no',
    allowtransparency: true,
    scrolling: 'no',
    allow: 'autoplay;fullscreen',
  };

  return `<iframe ${
    Object.entries(iframe).map(([k, v]) => `${k}="${encodeURI(String(v))}"`).join(' ')
  }></iframe>`;
};

export const EmbedMomentForm: React.FC<EmbedMomentFormProps> = function EmbedMomentForm({
  onCode,
  uid,
}) {
  const [width, setWidth] = React.useState(DefaultWidth);
  const [params, setParams] = React.useState(embedParamsDefault);
  const [iframe, setIframe] = React.useState<string>();
  const text = React.useRef<HTMLTextAreaElement>();

  React.useEffect(() => {
    const handleMessage = ({ data, origin }: MessageEvent): void => {
      if (typeof data === 'string' && Math.max(
        origin.indexOf('weplayed.com'),
        origin.indexOf('localhost'),
      ) > 0) {
        try {
          const { height: h } = JSON.parse(data);

          setIframe(null);
          const value = getIframeCode(uid, width, h, params);
          text.current.value = value;
          onCode(value);
        } catch (_e) {
          logger.error(
            'Got MessageEvent that could not be parsed to new height',
            { data, origin },
          );
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return (): void => window.removeEventListener('message', handleMessage);
  }, [onCode, params, uid, width]);

  React.useEffect(() => {
    setIframe(getIframeCode(uid, width, width * ApproxCoef, params));
    onCode(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, uid, width]); // no height!

  const handleChangeParam = React.useCallback(
    (param: keyof EmbedParams) => (
      ({ currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>): void => {
        setParams({ ...params, [param]: checked });
      }
    ),
    [params],
  );

  const loading = Boolean(iframe);

  return (
    <Container>
      {iframe && <div className={s.iframe} dangerouslySetInnerHTML={{ __html: iframe }} />}
      <Row>
        <Col xs="12" sm="4">
          <Form.Group controlId="iframeWidth">
            <Form.Label>Width</Form.Label>
            <Form.Control
              as="input"
              className={s.input}
              defaultValue={width}
              disabled={loading}
              max={MaxWidth}
              min={MinWidth}
              onChange={debouncedWidthHandler(setWidth)}
              placeholder="width"
              step={WidthStep}
              type="number"
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="8">
          {descPairs.map(([key, desc]): JSX.Element => (
            <Form.Check
              checked={Boolean(params[key])}
              disabled={loading}
              key={key}
              label={desc}
              onChange={handleChangeParam(key)}
              type="checkbox"
            />
          ))}
        </Col>
      </Row>
      <Row>
        <Col className={s.textarea}>
          <Form.Control
            as="textarea"
            ref={text}
            rows={4}
            readOnly
          />
        </Col>
      </Row>
    </Container>
  );
};
