import * as React from 'react';
import {
  Moment, PublishDestination, SportSlug, What,
} from 'weplayed-typescript-api';

import { renderColoredEntity } from 'common/components/ColoredEntity';
import { useAuth } from 'common/hooks/useAuth';
import { pickByPk } from 'common/utils/helpers';
import { toPlayerDisplay } from 'common/utils/moments';

import { renderPlayer } from 'cms/components/MomentDescription';
import { useMoment } from 'cms/hooks/useMoment';
import { useMomentPublication } from 'cms/hooks/useMomentPublication';
import { useTop100 } from 'cms/hooks/useTop100';

import { EntryState, UseDataArgs, UseDataReturnType } from './types';

const names = ['moment', 'moments'] as const;

export const useData = function useData<W extends PublishDestination>({
  status,
  uid,
  what,
  where,
}: UseDataArgs<Moment, W>): UseDataReturnType<Moment> {
  const { profile: user } = useAuth();
  const { conference, cms_org_id } = user;
  const orgId = conference?.pk || cms_org_id;

  const { moment, pins } = useMoment({
    pins: true,
    uid,
  });
  const { publication } = useMomentPublication({ uid });

  const { moments: {
    featured: top,
    pin: onPin,
  } } = useTop100({
    enabled: what && where && What.MOMENTS,
    recommended: false,
    sport: where === What.SPORTS ? what as SportSlug : undefined,
    type: where,
    uid: where === What.SPORTS ? orgId : what,
  });

  const isReady = Boolean(moment.data && pins.data && publication.data);

  const blocking = React.useMemo(() => {
    if (isReady) {
      const { sport } = moment.data.game;

      const {
        blocked_from_team_athletes: athletes = [],
        blocked_from_org: org = false,
        blocked_from_org_sport: sports = false,
        blocked_from_team: team = false,
        blocked_from_org_schools: schools = [],
      } = publication.data;
      return {
        athletes,
        org,
        team,
        game: null,
        sports: sports ? [sport.slug] : [],
        schools,
      };
    }
  }, [isReady, moment.data?.game, publication.data]);

  const org: EntryState<Moment, What.ORGS>[] | undefined = React.useMemo(() => {
    if (!isReady || !conference) {
      return;
    }

    return [{
      blocked: blocking.org,
      name: 'Home page',
      pinned: Boolean(pins.data.organizations?.[orgId]),
      pk: orgId,
      top: where === What.ORGS && what === orgId && top,
    }];
  }, [
    blocking?.org, conference, isReady, orgId, pins.data?.organizations,
    where, what, top,
  ]);

  const athletes: EntryState<Moment, What.ATHLETES>[] | undefined = React.useMemo(() => {
    if (!isReady || conference) {
      return;
    }

    const { game: { team1, team2 }, players } = moment.data;

    const teams = (user.org?.teams?.map(({ team_pk }) => team_pk) || [])
      .filter((pk) => (pk === team1.pk || pk === team2.pk));

    return players
      .filter(({ team_id }) => teams.includes(team_id))
      .map((player) => {
        const team = [team1, team2].find(pickByPk(player.team_id));

        return {
          blocked: (blocking.athletes || []).includes(player.team_athlete_id),
          name: renderPlayer({
            color: `#${team.primary_color}`,
            display: toPlayerDisplay(player),
            id: player.pk,
          }),
          pinned: Boolean(pins.data.athletes?.[player.team_athlete_id]),
          pk: player.team_athlete_id,
          top: where === What.ATHLETES && what === player.team_athlete_id && top,
        };
      });
  }, [
    blocking?.athletes, conference, isReady, moment.data, pins.data?.athletes, user.org?.teams,
    what, where, top,
  ]);

  const teams: EntryState<Moment, What.TEAMS>[] | undefined = React.useMemo(() => {
    if (!isReady || conference) {
      return;
    }

    const { primary_team, game } = moment.data;

    return [{
      blocked: blocking.team,
      name: game.sport.name,
      pinned: Boolean(pins.data.teams?.[game[primary_team]?.pk]),
      pk: game[primary_team]?.pk,
      top: where === What.TEAMS && what === game[primary_team]?.pk && top,
    }];
  }, [
    conference, moment.data, isReady, blocking?.team, pins.data?.teams,
    what, where, top,
  ]);

  const sports: EntryState<Moment, What.SPORTS>[] | undefined = React.useMemo(() => {
    if (!isReady || !conference) {
      return;
    }

    const { game: { sport } } = moment.data;

    return [{
      blocked: Boolean(blocking.sports.length),
      name: sport.name,
      pinned: Boolean(pins.data.sports?.[sport.slug]),
      pk: sport.slug,
      top: where === What.SPORTS && what === sport.slug && top,
    }];
  }, [
    isReady, conference, moment.data, blocking?.sports.length, pins.data?.sports,
    what, where, top,
  ]);

  const schools: EntryState<Moment, What.SCHOOLS>[] | undefined = React.useMemo(() => {
    if (!isReady || !conference) {
      return;
    }

    const { game: { team1, team2 } } = moment.data;

    return [team1.pk, team2.pk]
      .map((pk) => conference.schools.find((s) => Boolean(s.teams.find((t) => t.team_pk === pk))))
      .filter(Boolean)
      .map((school) => ({
        blocked: blocking.schools.includes(school.pk),
        name: renderColoredEntity(school.name, school.primary_color),
        pinned: Boolean(pins.data.organizations?.[school.pk]),
        pk: school.pk,
        top: where === What.SCHOOLS && what === school.pk && top,
      }));
  }, [
    isReady, conference, moment.data, blocking?.schools, pins.data?.organizations,
    what, where, top,
  ]);

  return {
    athletes,
    blocking: status?.publish.includes(uid),
    item: moment.data,
    loading: pins.isLoading || publication.isLoading || moment.isLoading,
    names,
    onPin,
    org,
    schools,
    sports,
    teams,
  };
};
