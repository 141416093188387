import { ReactComponent as Help } from '@mdi/svg/svg/information.svg';
import * as cx from 'classnames';
import * as React from 'react';

import { PageHeader } from '../PageHeader';
import { TooltipButton } from '../TooltipButton';
import * as s from './PageSidebar.m.less';
import { PageSidebarSectionProps } from './types';

export const PageSidebarSection: React.FC<PageSidebarSectionProps> = function PageSidebarSection({
  buttons,
  children,
  className,
  disabled,
  help,
  rest,
  noBorder,
  title,
}) {
  return (
    <div className={cx(s.section, noBorder && s.noBorder, rest && s.rest)}>
      <div className={cx(className, buttons && s.buttons, disabled && s.disabled)}>
        {title && (
          <PageHeader level={3}>
            {title}
            {help && (
              <TooltipButton
                className={s.help}
                tooltip={help}
              >
                <Help />
              </TooltipButton>
            )}
          </PageHeader>
        )}
        {children}
      </div>
    </div>
  );
};
