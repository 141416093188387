import * as React from 'react';
import { useQuery, useQueryCache } from 'react-query';
import {
  athletes, Collection, EntityWithPublication, Moment, org, PaginatedResponse,
  PinConfigEntry, pinning, teams, What,
} from 'weplayed-typescript-api';

import { isBlocked } from 'common/utils/collections';

import { TOP_COUNT, TOP_FIRST } from 'cms/constants';

import { useQueryCacheUpdate } from '../useQueryCacheUpdate';
import {
  QueryCacheConfigContext, QueryCacheInvalidator, QueryCacheUpdateEntityConfigs,
} from '../useQueryCacheUpdate/types';
import { Kind, UseTop100ReturnType, UseTop100Type } from './types';
import { getKey, injectPin, processResponse } from './utils';

const { MOMENTS, COLLECTIONS, ATHLETES, ORGS, SCHOOLS, SPORTS, TEAMS } = What;

const defaultOptions = {
  refetchOnWindowFocus: false,
};

/**
 * Helper hook to easy access and update top100 moments and collections
 * @param config
 */
export const useTop100: UseTop100Type = function useTop100({
  count = TOP_COUNT,
  enabled,
  recommended = true,
  sport,
  type,
  uid,
}) {
  // config for update moments/collections by events from outside hook
  const config = React.useMemo(() => {
    if (!enabled) {
      return [];
    }

    const context: QueryCacheConfigContext = {
      athleteId: type === ATHLETES && uid,
      teamId: type === TEAMS && uid,
      orgId: (type === SCHOOLS || type === ORGS || type === SPORTS) && uid,
      sport: type === SPORTS ? sport : undefined,
    };

    const featuredKey = getKey(type, enabled, uid, sport, Kind.FEATURED, count);
    const invalidate: QueryCacheInvalidator = (_, keys) => [[...keys, featuredKey], true];

    const rules: QueryCacheUpdateEntityConfigs = [
      {
        context,
        invalidate: true,
        predicate: featuredKey,
        what: enabled,
      },
      {
        context,
        invalidate,
        predicate: getKey(type, enabled, uid, sport, Kind.RECOMMENDED, count),
        what: enabled,
      },
    ];

    return rules;
  }, [count, enabled, sport, type, uid]);

  useQueryCacheUpdate(config);

  const cache = useQueryCache();

  const getMoments = React.useMemo(() => {
    if (enabled !== MOMENTS || !uid) {
      return;
    }

    // response type is pretty much the same
    return (limit, offset = 0): Promise<PaginatedResponse<Moment>> => {
      const res = (type === ATHLETES && athletes.top.moments({ uid }, limit, offset))
        || (type === TEAMS && teams.top.moments({ uid }, limit, offset))
        || org.top.moments({ uid, sport }, limit, offset);
      return res;
    };
  }, [sport, enabled, type, uid]);

  const getCollections = React.useMemo(() => {
    if (enabled !== COLLECTIONS || !uid) {
      return;
    }

    // response type is pretty much the same
    return (limit, offset = 0): Promise<PaginatedResponse<Collection>> => (
      (type === ATHLETES && athletes.top.collections({ uid }, limit, offset))
        || (type === TEAMS && teams.top.collections({ uid }, limit, offset))
        || org.top.collections({ uid, sport }, limit, offset));
  }, [sport, enabled, type, uid]);

  const [moreMoments, setMoreMoments] = React.useState<number>(null);
  const [moreCollections, setMoreCollections] = React.useState<number>(null);

  const fetch = Math.min(TOP_FIRST, count);

  const ignoreDepMoments = React.useRef(false);
  const ignoreDepCollections = React.useRef(false);

  const mFeatured: UseTop100ReturnType['moments']['featured'] = useQuery(
    getKey(type, MOMENTS, uid, sport, Kind.FEATURED, count),
    async () => {
      const [data, total] = processResponse<Moment>(await getMoments(fetch));

      setMoreMoments(Math.min(100, total) - data.length);

      return data;
    },
    {
      ...defaultOptions,
      enabled: getMoments,
      onSuccess: () => {
        // onSucces is always called after setQueryData
        // so we need a way to prevent this until config is updated
        if (!ignoreDepMoments.current) {
          cache.invalidateQueries(
            getKey(type, MOMENTS, uid, sport, Kind.RECOMMENDED, count),
          );
        }
        ignoreDepMoments.current = false;
      },
    },
  );

  const mRecommended: UseTop100ReturnType['moments']['recommended'] = useQuery(
    getKey(type, MOMENTS, uid, sport, Kind.RECOMMENDED, count),
    async () => {
      const response = await getMoments(moreMoments, fetch);

      const [data] = processResponse<Moment & EntityWithPublication>(response);

      return data;
    },
    {
      ...defaultOptions,
      enabled: recommended && getMoments && moreMoments && !mFeatured.isLoading,
    },
  );

  const pinMoment: UseTop100ReturnType['moments']['pin'] = React.useCallback(
    async (item, pin): Promise<void> => {
      const items = injectPin(
        [...(mFeatured.data || []), ...(mRecommended.data || [])],
        item,
        pin,
      );

      ignoreDepMoments.current = true;

      const featured = items.splice(0, TOP_FIRST);
      cache.setQueryData(
        getKey(type, MOMENTS, uid, sport, Kind.FEATURED, count),
        featured,
      );

      if (items.length) {
        cache.setQueryData(
          getKey(type, MOMENTS, uid, sport, Kind.RECOMMENDED, count),
          items,
        );
      }

      const conf: PinConfigEntry[] = featured
        .filter(({ pin: p }) => p)
        .map(({ pk, pin: p }) => ({ ...p, id: pk } as PinConfigEntry));

      await pinning.moments({
        pin: conf,
        what: type === What.SPORTS ? sport : uid,
        where: type,
        orgId: uid,
      });

      cache.invalidateQueries(getKey(type, MOMENTS, uid, sport, Kind.FEATURED, count));
    },
    [cache, count, mFeatured.data, mRecommended.data, sport, type, uid],
  );

  const cp = React.useCallback((c: Collection) => ({
    ...c,
    blocked: isBlocked(type, c, type === SPORTS ? sport : uid),
  }), [sport, type, uid]);

  const cFeatured: UseTop100ReturnType['collections']['featured'] = useQuery(
    getKey(type, COLLECTIONS, uid, sport, Kind.FEATURED, count),
    async () => {
      const [data, total] = processResponse<Collection>(await getCollections(fetch), cp);

      setMoreCollections(Math.min(100, total) - data.length);

      return data;
    },
    {
      ...defaultOptions,
      enabled: getCollections,
      onSuccess: () => {
        // onSucces is always called after setQueryData
        // so we need a way to prevent this until config is updated
        if (!ignoreDepCollections.current) {
          cache.invalidateQueries(
            getKey(type, COLLECTIONS, uid, sport, Kind.RECOMMENDED, count),
          );
        }
        ignoreDepCollections.current = false;
      },
    },
  );

  const cRecommended: UseTop100ReturnType['collections']['recommended'] = useQuery(
    getKey(type, COLLECTIONS, uid, sport, Kind.RECOMMENDED, count),
    async () => {
      const response = await getCollections(moreCollections, fetch);

      const [data] = processResponse<Collection>(response, cp);

      return data;
    },
    {
      ...defaultOptions,
      enabled: recommended && getCollections && moreCollections && !cFeatured.isLoading,
      onSettled: () => setMoreCollections(null),
    },
  );

  const pinCollection: UseTop100ReturnType['collections']['pin'] = React.useCallback(
    async (item, pin): Promise<void> => {
      const items = injectPin(
        [...(cFeatured.data || []), ...(cRecommended.data || [])],
        item,
        pin,
      );

      ignoreDepCollections.current = true;

      const featured = items.splice(0, TOP_FIRST);
      cache.setQueryData(
        getKey(type, COLLECTIONS, uid, sport, Kind.FEATURED, count),
        featured,
      );

      if (items.length) {
        cache.setQueryData(
          getKey(type, COLLECTIONS, uid, sport, Kind.RECOMMENDED, count),
          items,
        );
      }

      const conf: PinConfigEntry[] = featured
        .filter(({ pin: p }) => p)
        .map(({ pk, pin: p }) => ({ ...p, id: pk } as PinConfigEntry));

      await pinning.collections({
        pin: conf,
        what: type === What.SPORTS ? sport : uid,
        where: type,
        orgId: uid,
      });
      cache.invalidateQueries(getKey(type, COLLECTIONS, uid, sport, Kind.FEATURED, count));
    },
    [cFeatured.data, cRecommended.data, cache, count, sport, type, uid],
  );

  const isLoading = mFeatured.isFetching || cFeatured.isFetching;

  return {
    moments: {
      featured: mFeatured,
      recommended: mRecommended,
      pin: pinMoment,
    },
    collections: {
      featured: cFeatured,
      recommended: cRecommended,
      pin: pinCollection,
    },
    isLoading,
  };
};
