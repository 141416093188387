import * as cx from 'classnames';
import { castArray } from 'lodash/fp';
import * as React from 'react';

import * as s from './CollectionModal.m.less';
import { CollectionSectionProps, CollectionSectionRowProps } from './types';

export const CollectionSection: React.FC<CollectionSectionProps> = function CollectionSection({
  border = true,
  children,
  disabled,
}) {
  return (
    <div className={cx(s.section, border && s.border, disabled && s.disabled)}>
      {children}
    </div>
  );
};

export const CollectionSectionRow: React.FC<
  CollectionSectionRowProps
> = function CollectionSectionRow({
  children,
  disabled,
  note,
  title,
}) {
  const columns = castArray(children);

  return (
    <div className={cx(s.row, disabled && s.disabled)}>
      {title && (
        <h4>
          {title}
          {note && <span className={s.sectionNote}>{note}</span>}
        </h4>
      )}

      <dl>
        <dt>{columns[0]}</dt>
        <dd>{columns[1]}</dd>
      </dl>
    </div>
  );
};
