import { castArray } from 'lodash/fp';
import {
  BlockEntity, CollectionPublication, ID, MomentPublication, What,
} from 'weplayed-typescript-api';

import { Signal } from '../types';

const signal = 'published' as const;

export interface PublishedSignal<
  W extends What.MOMENTS | What.COLLECTIONS,
  T
> extends Signal<typeof signal, W, T> {
  publish: W extends What.MOMENTS
           ? BlockEntity | null // for review action
           : BlockEntity,
  publication: W extends What.MOMENTS ? MomentPublication :
               W extends What.COLLECTIONS ? CollectionPublication :
               never;
  what: W;
}

export const test = <W extends What.MOMENTS | What.COLLECTIONS>(
  s: Signal<unknown, What, unknown>,
  w: W,
): s is PublishedSignal<W, unknown> => s.signal === signal && s.what === w;

export const notify = <W extends What.MOMENTS | What.COLLECTIONS, T>(
  what: W,
  uid: ID | ID[],
  publish: BlockEntity | null,
  publication: PublishedSignal<W, T>['publication'],
): PublishedSignal<W, T> => ({
  publish,
  publication,
  signal,
  uid: castArray(uid),
  what,
});
