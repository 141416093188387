import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { BaseEntityList } from './BaseEntityList';
import { EntitiesListGroupContext } from './constants';
import { EntitiesListGroupContextType, EntitiesListType } from './types';

/**
 * Base entities generator wrapper, whose main responsibility is
 * toggling between list types
 */
// eslint-disable-next-line react/function-component-definition
export function Base<
  T extends BaseEntityWithPk,
  P extends {
    items: T[],
    renderers: readonly [
      // list generator
      React.FC<{ items: T[] }>,
      // tiles generator
      React.FC<{ items: T[] }>,
    ],
    toTokens(item: T): string[],
    type?: EntitiesListType,
  }
>({
  type: $type,
  renderers: [Tiles, List],
  toTokens,
  ...props
}: P): JSX.Element {
  const {
    config: { type: $$type },
  } = React.useContext(EntitiesListGroupContext) as EntitiesListGroupContextType<T>;

  const type = $type || $$type;

  return (
    <BaseEntityList {...props}>
      {type === EntitiesListType.LIST
        ? <List toTokens={toTokens} {...props} />
        : <Tiles toTokens={toTokens} {...props} />}
    </BaseEntityList>
  );
}
