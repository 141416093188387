import cx from 'classnames';
import { identity, noop } from 'lodash/fp';
import { BaseEntityWithPk, Moment as $Moment } from 'weplayed-typescript-api';

import { formatDate } from 'common/utils/dates';
import { formatDistance } from 'common/utils/time';

import { GameLabel } from 'cms/components/GameLabel';
import { ItemPreview } from 'cms/components/ItemPreview';
import { SportLabel } from 'cms/components/SportLabel';
import {
  MomentDescriptionReadonly,
} from 'consumer/components/MomentDescription';

import { defaults } from '../../constants';
import { EntitiesSize, MomentItemProps } from '../../types';
import { getBlock, getPin } from '../../utils';
import { Block, Creator, Like, Link, Menu, Pin } from '../Controls';
import { EntityLink } from '../EntityLink';
import { moments as def } from '../links';
import { useLink } from '../useLink';
import { Base } from './Base';
import { Blocked } from './Blocked';
import * as s from './Items.m.less';

const d = defaults.tiles.moments;

export const Moment = function Moment<
  T extends BaseEntityWithPk = $Moment,
  D extends $Moment = $Moment
>({
  base = d.base,
  blocking,
  created = d.created,
  creator = d.creator,
  edited: _edited = d.edited,
  game = d.game,
  getData = identity,
  item: $item,
  link,
  onBlock,
  onLike,
  onLink,
  onMenu,
  onPin,
  pinning,
  position,
  sport = d.sport,
  ...props
}: MomentItemProps<T, D>): JSX.Element {
  const item = getData($item);
  const url = useLink({ item, link, def });

  const {
    thumbnail, name, game: { sport: gameSport }, curator, is_base,
    preview_webp, ctime, liked_by_user, is_hidden,
  } = item;

  const thumb = (
    <>
      {thumbnail && (
        <ItemPreview
          alt={name}
          animated={preview_webp}
          className={s.thumbnail}
          enable
          src={thumbnail}
        />
      )}
      {typeof position === 'number' && <span className={s.position}>{position}</span>}
      {base && is_base && <span className={s.base}>base</span>}
      {is_hidden && <Blocked />}
    </>
  );

  const cdate = created && ctime ? Date.parse(ctime) : null;

  return (
    <Base<T>
      {...props}
      className={s.moment}
      controls={[
        <Creator
          className={s.creator}
          creator={curator}
          key="creator"
          onClick={creator && noop}
        />,
        <Pin
          className={s.visible}
          key="pin"
          loading={pinning}
          onClick={onPin}
          pin={getPin(item)}
        />,
        <Block
          block={getBlock(item)}
          className={s.visible}
          key="block"
          loading={blocking}
          onClick={onBlock}
        />,
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          key="link"
          onClick={onLink}
        />,
        <Like
          className={cx(liked_by_user && s.visible)}
          key="like"
          like={liked_by_user}
          onClick={onLike}
        />,
        <Menu
          className={s.visible}
          key="menu"
          onClick={onMenu}
        />,
      ]}
      item={$item}
    >
      <EntityLink
        className={cx(s.rtile, gameSport?.slug)}
        url={url}
      >
        {thumb}
      </EntityLink>
      <div className={s.extra}>
        <div className={s.name} title={item.name}>
          <MomentDescriptionReadonly
            className={s.momentDescription}
            inline
            moment={item}
          />
        </div>
        <div className={s.info}>
          {sport && <SportLabel sport={gameSport} />}
          {game && <GameLabel game={item.game} className={s.score} />}
          {created && cdate && (
            <span className={s.created} title={formatDate(cdate)}>
              {formatDistance(cdate, props.size === EntitiesSize.COMPACT)}
            </span>
          )}
        </div>
      </div>

    </Base>
  );
};
