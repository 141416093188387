import {
  EntityWithPublication, ID, PaginatedResponse, PinConfigEntry,
  PublishDestination, SportSlug, What,
} from 'weplayed-typescript-api';

import { Kind } from './types';

export const getKey = (
  where: PublishDestination,
  what: What.MOMENTS | What.COLLECTIONS,
  id: ID | SportSlug,
  sport: SportSlug | undefined,
  kind: Kind,
  count?: number,
): string[] => [
  `top100-${where}-${what}`,
  id,
  sport || '',
  kind,
].concat(count ? [String(count)] : []);

/**
 * Small helper to process paginated response with the given postprocessor
 * @param response
 * @param postprocess
 * @returns
 */
export const processResponse = <T>(
  { items, total }: PaginatedResponse<T>,
  postprocess: (item: T) => T = null,
): [T[], number] => [
  items.map(
    (entity) => {
      const item = { ...entity, blocked: false };
      return postprocess ? postprocess(item) : item;
    },
  ),
  total,
];

/**
 * Set/Unset item pin in the list
 * @param items List of items
 * @param item Item to pin, could be outside of the list
 * @param pin Pin info
 * @returns Updated list of items
 */
export const injectPin = <T extends EntityWithPublication>(
  items: T[],
  item: T,
  pin: Omit<PinConfigEntry, 'id'> | false,
): T[] => {
  const temp = [...items];

  if (pin) {
    // if the destination position already have pinned item, unpin it first
    if (temp[pin.position]?.pin) {
      temp[pin.position] = {
        ...temp[pin.position],
        pin: false,
      };
    }

    const idx = temp.findIndex(({ pk }) => pk === item.pk);
    if (idx !== -1) {
      temp[idx] = { ...temp[idx], pin };
    } else {
      temp.push({ ...item, pin });
    }

    const pinned = [];
    for (let x = 0; x < temp.length; x += 1) {
      if (temp[x].pin) {
        pinned.push(temp.splice(x, 1).shift());
        x -= 1;
      }
    }

    pinned.sort((a, b) => a.pin.position - b.pin.position);

    const ret = [];

    while (pinned.length && temp.length) {
      if (pinned[0].pin.position === ret.length) {
        ret.push(pinned.shift());
      } else {
        ret.push(temp.shift());
      }
    }

    ret.push(...pinned, ...temp);

    return ret;
  }

  return temp.map(($item) => ($item.pk === item.pk ? { ...$item, pin: false } : $item));
};
