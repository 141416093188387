import { BaseEntityWithPk, Moment as $Moment } from 'weplayed-typescript-api';

import { defaults } from '../constants';
import { Moment } from '../Items/Tiles/Moment';
import { MomentsProps, TilesExtraProps, WithGetData } from '../types';
import { useControls } from '../useControls';
import { getBlock, getLike, getPin } from '../utils';
import { Base } from './Base';

const d = defaults.tiles.moments;

// eslint-disable-next-line react/function-component-definition
export function Moments<
  T extends BaseEntityWithPk = $Moment,
  D extends $Moment = $Moment,
>({
  base = d.base,
  created = d.created,
  creator = d.creator,
  edited = d.edited,
  game = d.game,
  getData,
  link,
  onBlock,
  onLike,
  onLink,
  onMenu,
  onPin,
  position,
  sport = d.sport,
  status,
  ...props
}: Omit<MomentsProps<T, D>, 'type'> & TilesExtraProps & WithGetData<T, D>): JSX.Element {
  const handlers = useControls(
    { onBlock, onLike, onLink, onMenu, onPin, getBlock, getLike, getPin },
  );

  const renderer = (p): JSX.Element => (
    <Moment<T, D>
      {...p}
      {...handlers}
      base={base}
      blocking={status?.publish.includes(p.item.pk)}
      created={created}
      creator={creator}
      edited={edited}
      game={game}
      getData={getData}
      link={link}
      pinning={status?.pin.includes(p.item.pk)}
      position={typeof position === 'number' ? position + p.index : null}
      sport={sport}
    />
  );

  return (
    <Base<T>
      {...props}
      renderer={renderer}
    />
  );
}
