import { Collection, What } from 'weplayed-typescript-api';

import { makeTokens } from 'common/utils/strings';

import { Base } from './Base';
import { Collections as List } from './Lists/Collections';
import { Collections as Tiles } from './Tiles/Collections';
import { CollectionsProps } from './types';

const renderers = [Tiles, List] as const;
const toTokens = <T extends Collection>({ name }: T): string[] => makeTokens(name);

// eslint-disable-next-line react/function-component-definition
export function Collections<
  T extends Collection = Collection
>(props: CollectionsProps<T>): JSX.Element {
  return (
    <Base
      renderers={renderers}
      toTokens={toTokens}
      what={What.COLLECTIONS}
      {...props}
    />
  );
}
