import { uniq, without } from 'lodash/fp';
import * as React from 'react';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import {
  BlockEntity, ID, Moment, PublishDestination, What,
} from 'weplayed-typescript-api';

import { useApplication } from 'common/hooks/useApplication';
import { BroadcastType } from 'common/hooks/useCreateAppContext/types';
import { event } from 'common/utils/analytics';
import { pkify } from 'common/utils/helpers';
import { format } from 'common/utils/strings';

import { AddToCollectionModal } from 'cms/components/AddToCollectionModal';
import { ConfirmationModal } from 'cms/components/ConfirmationModal';
import { EmbedModal } from 'cms/components/EmbedModal';
import { EntityInfo } from 'cms/components/EntityInfo';
import { MomentPublishSidebar } from 'cms/components/PublishSidebar';
import { useExports } from 'cms/hooks/useExports';
import { useMoments } from 'cms/hooks/useMoments';
import {
  MomentPinPayload, MomentsCollectionsUpdate,
} from 'cms/hooks/useMoments/types';
import { CLIENT_URLS } from 'cms/routes';

import { AuthRequired, defaultState } from './constants';
import * as messages from './messages';
import {
  Action, ActionStatus, ActionType, BulkAction, ResultType, SingleAction,
  UseMomentActionsType,
} from './types';
import { isAction } from './utils';

const config = {
  throwOnError: true,
};

export const useMomentActions: UseMomentActionsType = function useMomentActions<
  T extends ActionType = ActionType
>({
  onAccept,
  onDecline,
  onDone,
  onStart,
  onUnauthorized,
  sendDownloadEmail,
  user,
}) {
  // statusRef is a source of states between updates,
  // status/setStatus is used for notifying context only
  const statusRef = React.useRef<ActionStatus>(defaultState);
  const [status, setStatus] = React.useState<ActionStatus>(statusRef.current);
  const [note, setNote] = React.useState('');

  const history = useHistory();
  const { download } = useExports();

  const [action, setAction] = React.useState<Action<T>>(null);
  const [showFlagDone, setShowFlagDone] = React.useState(false);
  const {
    block: [block],
    bulkLike: [bulkLike],
    bulkRemove: [bulkRemove],
    collections: [collections],
    create: [create],
    flag: [flag],
    like: [like],
    pin: [pin],
    remove: [deleteMoment],
    review: [review],
    uncollect: [uncollect],
    update: [update],
    hide: [hide],
  } = useMoments();

  const { broadcast } = useApplication();

  const updateStatus = React.useCallback(
    (momentIds: ID[], where: keyof ActionStatus, raise: boolean) => {
      statusRef.current = {
        ...statusRef.current,
        [where]: raise
          ? uniq(statusRef.current[where].concat(momentIds))
          : without(momentIds, statusRef.current[where]),
      };

      setStatus(statusRef.current);
    },
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const engage = React.useCallback((
    momentIds: ID[],
  ) => (
    where: keyof ActionStatus,
  ): (
    ) => void => {
    updateStatus(momentIds, where, true);

    return (): void => updateStatus(momentIds, where, false);
  }, [updateStatus]);

  const run = React.useCallback(
    async (act: Action<T>): Promise<boolean> => {
      const statusPk = ((act as BulkAction).moments?.map(pkify) || []);
      if ('moment' in act && act.moment) {
        statusPk.push(act.moment.pk || null); // clone and create
      }
      const up = engage(statusPk);
      const message = (type: BroadcastType, msg: string): void => {
        broadcast(type, (
          (type === 'success' && act.success)
          || (type === 'error' && act.fail)
        ) || msg);
      };

      const report = (result?: ResultType<T>): boolean => {
        if (onDone) {
          onDone(act, result);
        }

        return true;
      };

      if (onAccept && await onAccept(act, up) === false) {
        return;
      }

      let result = false;
      let reset: () => void = null;

      if (isAction(act, 'download')) {
        download(What.MOMENTS, [act.moment.pk], sendDownloadEmail);
      } else if (isAction(act, 'bulk_download')) {
        download(What.MOMENTS, act.moments.map(pkify), sendDownloadEmail);
      } else if (isAction(act, 'like')) {
        // because of the nature how we do likes
        // we report done right now
        result = report();

        try {
          await like({ uid: act.moment.pk, like: act.like }, config);
        } catch (e) {
          message('error', 'Moment like failed');
        }
      } else if (isAction(act, 'remove') && !statusRef.current.remove.includes(act.moment.pk)) {
        reset = up('remove');

        try {
          await uncollect({ uid: act.moment.pk, collections: act.collectionIDs }, config);
          result = report();
          message('success', 'Moment has been removed from collection');
        } catch (error) {
          message('success', 'Cannot remove moment from collection');
        }
      } else if (isAction(act, 'flag') && !statusRef.current.flag.includes(act.moment.pk)) {
        reset = up('flag');

        try {
          await flag({ uid: act.moment.pk, note: act.note }, config);
          setShowFlagDone(true);
          result = report();
        } catch (error) {
          message('error', 'Cannot flag moment');
        }
      } else if (isAction(act, 'delete') && !statusRef.current.delete.includes(act.moment.pk)) {
        reset = up('delete');

        try {
          await deleteMoment({ uid: act.moment.pk }, config);
          message('success', 'Moment deleted');
          result = report();
        } catch (error) {
          message('error', 'Cannot delete moment');
        }
      } else if (isAction(act, 'bulk_delete') && !statusRef.current.delete.length) {
        reset = up('delete');
        try {
          await bulkRemove({ uids: act.moments.map(pkify) }, config);
          message('success', 'Moments deleted');
          result = report();
        } catch (error) {
          message('error', 'Cannot delete moments');
        }
      } else if (isAction(act, 'share') && act.target) {
        switch (act.target) {
          case 'native': {
            event({
              category: 'Sharing',
              action: 'Moment Mobile-Shared',
              label: act.moment.pk,
            });
            break;
          }

          case 'facebook': {
            event({
              category: 'Sharing',
              action: 'Moment Shared on FB',
              label: act.moment.pk,
            });
            break;
          }

          case 'twitter': {
            event({
              category: 'Sharing',
              action: 'Moment Shared on TW',
              label: act.moment.pk,
            });
            break;
          }

          case 'copy': {
            event({
              category: 'Sharing',
              action: 'Moment URL Copied',
              label: act.moment.pk,
            });
            break;
          }

          default:
            //
        }
        result = report();
      } else if (isAction(act, 'game')) {
        const { moment } = act;

        history.push(CLIENT_URLS.GAME.VIEW.buildPath({
          uid: moment.game?.pk || moment.game_id,
          muid: moment.pk,
        }));
        result = report();
      } else if (isAction(act, 'pin') && !statusRef.current.pin.includes(act.moment.pk)) {
        reset = up('pin');

        try {
          await pin({ ...act.pin, uid: act.moment.pk } as MomentPinPayload, config);
          result = report();
          message('success', act.pin.pin
            ? messages.pinnedSuccess
            : messages.unpinnedSuccess);
        } catch (error) {
          message('error', 'Cannot pin moment');
        }
      } else if (isAction(act, 'block') && !statusRef.current.publish.includes(act.moment.pk)) {
        reset = up('publish');

        try {
          await block({ ...act.block, uid: act.moment.pk }, config);
          result = report();
          message('success', act.block.blocked
            ? messages.unpublishedSuccess
            : messages.publishedSuccess);
        } catch (error) {
          message('error', 'Cannot publish moment');
        }
      } else if (isAction(act, 'create') && !statusRef.current.create.includes(null)) {
        reset = up('create');

        try {
          const m: Moment = await create({ moment: act.moment }, config);
          message('success', 'Moment created successfully');
          result = report(m.pk as ResultType<T>);
        } catch (error) {
          message('error', 'Cannot create moment');
        }
      } else if (isAction(act, 'update') && !statusRef.current.update.includes(act.moment.pk)) {
        reset = up('update');

        try {
          await update({ uid: act.moment.pk, moment: act.moment }, config);
          message('success', 'Moment updated successfully');
          result = report();
        } catch (error) {
          message('error', 'Cannot update moment');
        }
      } else if (isAction(act, 'bulk_like')) {
        // because of the nature how we do likes
        // we report done right now
        report();

        try {
          await bulkLike({ uids: act.moments.map(pkify), like: act.like }, config);
        } catch (error) {
          message('error', 'Bulk moment like failed');
        }
      } else if (isAction(act, 'collections') && !statusRef.current.collections.length) {
        reset = up('collections');

        try {
          await collections(act.update, config);
          message('success', format(
            messages.collectionsMoments,
            act.update.add.length + act.update.remove.length,
          ));
          result = report();
        } catch (error) {
          message('error', 'Cannot update collections');
        }
      } else if (isAction(act, 'review') && !statusRef.current.review.includes(act.moment.pk)) {
        reset = up('review');

        try {
          const { moment: { pk: uid, reviewed } } = act;
          await review({ uid, reviewed: !reviewed }, config);
          message('success', `Moment marked as ${reviewed ? 'not reviewed' : 'reviewed'}`);
          result = report();
        } catch (error) {
          message('error', 'Cannot update moment');
        }
      } else if (isAction(act, 'hide') && !statusRef.current.hide.includes(act.moment.pk)) {
        reset = up('hide');

        try {
          const { moment: { pk: uid }, hidden } = act;
          const h = await hide({ uid, hidden }, config);
          message('success', `Moment ${h ? 'blocked' : 'visible'}`);
          result = report();
        } catch (error) {
          message('error', 'Cannot update moment');
        }
      }

      if (reset) {
        reset();
      }

      return result;
    },
    [
      engage, onAccept, onDone, download, broadcast, like, uncollect,
      flag, deleteMoment, history, pin, create, update, bulkLike, bulkRemove,
      block, collections, review, hide,
    ],
  );

  const invoke = React.useCallback(
    (act) => {
      if (AuthRequired.includes(act.action) && !user) {
        if (onUnauthorized) {
          onUnauthorized(act);
        }
        return;
      }

      if (onStart && act.notify !== false && onStart(act) === false) {
        return;
      }

      if (act.notify) {
        return;
      }

      if (isAction(act, 'flag')) {
        setNote('');
      }

      // these actions require confirmation so store params
      // and set action to raise modal
      if (
        ([
          'bulk_delete',
          'delete',
          'embed',
          'flag',
          'info',
          'pin',
          'publish',
          'remove',
        ] as ActionType[]).includes(act.action)
        || (isAction(act, 'collections') && !act.update)
        || (isAction(act, 'pin') && !act.pin)) {
        setAction(act);
      } else {
        run(act);
      }
    },
    [onUnauthorized, run, user, onStart],
  );

  const handleConfirm = React.useCallback(
    (): void => {
      setAction(null);
      if (isAction(action, 'flag') && user?.is_staff && note) {
        run({ ...action, note });
      } else {
        run(action);
      }
    },
    [action, user?.is_staff, note, run],
  );

  const handleDecline = React.useCallback(
    (): void => {
      setAction(null);

      if (onDecline) {
        onDecline(action);
      }
    },
    [onDecline, action],
  );

  const handleCollectionsClose = React.useCallback(
    async (): Promise<void> => {
      if (onDecline && action) {
        onDecline(action);
      }

      // a way to avoid problem with collection modal close
      setTimeout(() => {
        setAction(null);
      }, 0);
    },
    [onDecline, action],
  );

  const handleCollectionsUpdate = React.useCallback(
    async (data: MomentsCollectionsUpdate) => {
      const result = await run({ ...action, update: data });

      if (result) {
        setAction(null);
      }
    },
    [action, run],
  );

  const publishAction = isAction(action, 'publish') ? action : null;

  const handlePublishLoading = React.useCallback((isLoading) => {
    if (isAction(action, 'pin') || isAction(action, 'publish')) {
      updateStatus([action.moment.pk], action.action, isLoading);
    }
  }, [action, updateStatus]);

  const handleBlock = React.useCallback(
    async ($block: BlockEntity<PublishDestination>) => {
      const $action = {
        action: 'block',
        moment: (action as SingleAction).moment,
        block: $block,
      } as Action<T>;

      await run($action);
    },
    [action, run],
  );

  const handleChangeNote = React.useCallback(
    ({ currentTarget: { value } }): void => setNote(value),
    [],
  );

  const modals = React.useMemo(() => (
    <>
      {isAction(action, 'info') && (
        <EntityInfo
          uid={action.moment.pk}
          type={What.MOMENTS}
          onClose={handleDecline}
        />
      )}
      {user?.can_publish_content && (
        <MomentPublishSidebar
          onBlock={publishAction && handleBlock}
          onClose={handleDecline}
          onLoading={handlePublishLoading}
          status={publishAction && status}
          uid={publishAction?.moment.pk}
        />
      )}
      {isAction(action, 'bulk_delete') && (
        <ConfirmationModal
          onConfirm={handleConfirm}
          onCancel={handleDecline}
          title="Delete Moments"
          message={format(messages.bulkDeleteQuestion, action.moments.length)}
        />
      )}
      {isAction(action, 'delete') && (
        <ConfirmationModal
          onConfirm={handleConfirm}
          onCancel={handleDecline}
          title="Delete Moment"
          message="Are you sure you want to completely delete this moment?"
        />
      )}
      {isAction(action, 'collections') && !action.update && (
        <AddToCollectionModal
          collectionIDs={action.collectionIDs}
          moment={action.moment}
          moments={action.moments}
          onUpdate={handleCollectionsUpdate}
          onClose={handleCollectionsClose}
          updating={Boolean(status.collections.length)}
          user={user}
        />
      )}
      {isAction(action, 'remove') && (
        <ConfirmationModal
          onConfirm={handleConfirm}
          onCancel={handleDecline}
          title="Remove Moment from Playlist"
          message="Are you sure you want to remove this moment from the playlist?"
        />
      )}
      {isAction(action, 'flag') && (
        <ConfirmationModal
          cancelText="No, Cancel"
          confirmText="Yes, Flag"
          onConfirm={handleConfirm}
          onCancel={handleDecline}
          title="Flag Content"
        >
          Are you sure you want to flag this content as invalid?
          {user?.is_staff && (
            <Form.Control
              as="textarea"
              className="mt-3"
              rows={4}
              placeholder="Add note..."
              value={note}
              onChange={handleChangeNote}
            />
          )}
        </ConfirmationModal>
      )}
      {isAction(action, 'embed') && (
        <EmbedModal
          momentId={action.moment.pk}
          onClose={handleDecline}
        />
      )}

      {showFlagDone && (
        <ConfirmationModal
          onConfirm={(): void => setShowFlagDone(false)}
          title="Flag Content"
          message={'Thank you for flagging this content as invalid. '
                                + 'We will review and take action.'}
        />
      )}
    </>
  ), [
    action,
    handleBlock,
    handleChangeNote,
    handleCollectionsClose,
    handleCollectionsUpdate,
    handleConfirm,
    handleDecline,
    handlePublishLoading,
    note,
    publishAction,
    showFlagDone,
    status,
    user,
  ]);

  return { modals, invoke, status };
};
