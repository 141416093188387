import * as React from 'react';
import { Collection, What } from 'weplayed-typescript-api';

import { useSearch } from 'cms/hooks/useSearch';

import { UseData, UseDataReturnType } from './types';

export const useData: UseData = function useData({
  text, sort, uid,
}) {
  const { collections: { data: result } } = useSearch({
    empty: true,
    enabled: What.COLLECTIONS,
    facets: false,
    summary: false,
    query: {
      text,
      sort,
      created_by_id: uid,
    },
  });

  const collections: UseDataReturnType['collections'] = React.useMemo(() => ({
    ...result,
    data: result.data?.map(({ items }) => items),
    fetchMore: (...args): Promise<Collection[][]> => result.fetchMore(...args)
      .then((value) => value.map(({ items }) => items)),
    refetch: (...args): Promise<Collection[][]> => result.fetchMore(...args)
      .then((value) => value.map(({ items }) => items)),
  }), [result]);

  return { collections };
};
