import { ReactComponent as HideIcon } from '@mdi/svg/svg/cancel.svg';
import * as React from 'react';

import * as s from './Items.m.less';

export const Blocked: React.FC = function Blocked() {
  return (
    <div className={s.blocked}>
      <HideIcon />
    </div>
  );
};
