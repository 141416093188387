import * as cx from 'classnames';
import { ReactComponent as Spinner } from 'images/spinner.svg';
import * as React from 'react';
import { Button as BsButton } from 'react-bootstrap';

import * as s from './Button.m.less';
import { ButtonType, Props } from './types';

// eslint-disable-next-line prefer-arrow-callback
const ButtonFwd: React.ForwardRefRenderFunction<'button', Props> = function Button({
  children,
  className,
  loading,
  over,
  ...rest
}, ref): JSX.Element {
  return (
    <BsButton
      {...rest}
      ref={ref}
      className={cx(s.root, over && s.over, className)}
    >
      {children}
      {loading && <Spinner className={children && s.padded} />}
    </BsButton>
  );
};

export const Button: ButtonType = React.forwardRef(ButtonFwd);
