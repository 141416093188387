import { ReactComponent as Facebook } from '@mdi/svg/svg/facebook.svg';
import { ReactComponent as LinkedIn } from '@mdi/svg/svg/linkedin.svg';
import { ReactComponent as Twitter } from '@mdi/svg/svg/twitter.svg';
import * as React from 'react';
import { Table } from 'react-bootstrap';
import {
  FacebookShareButton, LinkedinShareButton, TwitterShareButton,
} from 'react-share';

import * as s from './ShareSidebar.m.less';
import { WebShareProps } from './types';

export const WebShare: React.FC<WebShareProps> = function WebShare({ social }) {
  return (
    <Table className={s.tableShare}>
      <tbody>
        <tr>
          <td className={s.tdShare}>
            <Twitter />
            Share on Twitter
          </td>
          <td>
            <TwitterShareButton {...social('twitter')} className={s.share}>
              Share on Twitter
            </TwitterShareButton>
          </td>
        </tr>
        <tr>
          <td className={s.tdShare}>
            <Facebook />
            Share on Facebook
          </td>
          <td>
            <FacebookShareButton {...social('facebook')} className={s.share}>
              Share on Facebook
            </FacebookShareButton>
          </td>
        </tr>
        <tr>
          <td className={s.tdShare}>
            <LinkedIn />
            Share on LinkedIn
          </td>
          <td>
            <LinkedinShareButton {...social('linkedin')} className={s.share}>
              Share on LinkedIn
            </LinkedinShareButton>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
