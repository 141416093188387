import * as React from 'react';

import LabelError from '../LabelError';

interface Props {
  errors: { [key: string]: any };
}

const NonFieldErrors: React.FC<Props> = function NonFieldErrors({ errors }) {
  const nonFieldErrors = (errors?.errors || [])
    .filter(
      (error) => error.source?.pointer === '/data/attributes/non_field_errors',
    );
  return (
    <>
      {nonFieldErrors.map((error) => (
        <LabelError key={error.detail} error={error.detail} />
      ))}
    </>
  );
};

export default NonFieldErrors;
