import * as React from 'react';
import { useQuery } from 'react-query';
import { moments as api, What } from 'weplayed-typescript-api';

import { signalIs } from '../useDataSignal';
import { useQueryCacheUpdate } from '../useQueryCacheUpdate';
import { QueryCacheInvalidator } from '../useQueryCacheUpdate/types';
import { UseMomentReturnType, UseMomentType } from './types';
import { getKey, getPinsKey } from './utils';

const defaultOptions = {
  refetchOnWindowFocus: false,
};

export const useMoment: UseMomentType = function useMoment({
  uid, enabled: isEnabled = true, pins, ...options
}): UseMomentReturnType {
  const enabled = Boolean(uid && isEnabled);

  const key = React.useMemo(() => getKey(uid, options), [options, uid]);
  const config = React.useMemo(
    () => {
      if (uid) {
        const invalidate: QueryCacheInvalidator = (signal) => {
          // reload pins on moment publishing status update
          if (enabled && signalIs.published(signal, What.MOMENTS)
              && pins && signal.uid.includes(uid)
          ) {
            return [[getPinsKey(uid)], true];
          }

          return [[], false];
        };

        return [{ what: What.MOMENTS, predicate: key, invalidate }];
      }

      return [];
    },
    [enabled, key, pins, uid],
  );

  useQueryCacheUpdate(config);

  const moment: UseMomentReturnType['moment'] = useQuery(
    getKey(uid, options),
    async () => api.get({ uid, ...options, cached: window.weplayed_skip_auth || false }),
    {
      ...defaultOptions,
      enabled: Boolean(uid && isEnabled),
    },
  );

  const momentPins: UseMomentReturnType['pins'] = useQuery(
    getPinsKey(uid),
    () => api.pins({ uid }),
    {
      ...defaultOptions,
      enabled: Boolean(uid && pins),
    },
  );

  return { moment, pins: momentPins };
};
