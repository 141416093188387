import { QueryCache, QueryKey } from 'react-query';
import {
  Collection, Moment, SportSlug, What, WhatType,
} from 'weplayed-typescript-api';

import {
  updateFollow, updateItem, updateLike, updateRemove,
} from 'common/hooks/utils';
import {
  isBlocked as collectionIsBlocked, isPinned as isCollectionPinned,
} from 'common/utils/collections';
import {
  isBlocked as momentIsBlocked, isPinned as isMomentPinned,
} from 'common/utils/moments';

import { signalIs } from '../useDataSignal';
import { Signal } from '../useDataSignal/types';
import {
  QueryCacheUpdateEntityConfig, QueryCacheUpdateEntityConfigWithoutType,
  QueryCacheUpdateEntityConfigWithType,
} from './types';

export const PAGINATED_PATH = ['items'];

const { MOMENTS, COLLECTIONS, GAMES, PLAYERS, SPORTS } = What;

type Updater<T> = (item: T) => T;

/**
 * Returns query cache updater for given signal
 * @param signal
 */
export function getUpdater<
  W extends What,
  T extends WhatType<W>
>(
  signal: Signal<unknown, W, unknown>,
): Updater<T> {
  const { uid } = signal;

  if (signalIs.liked(signal, MOMENTS)
      || signalIs.liked(signal, COLLECTIONS)
      || signalIs.liked(signal, GAMES)
  ) {
    return updateLike(uid, signal.liked);
  }

  if (signalIs.liked(signal, PLAYERS)) {
    return updateFollow(uid, signal.liked);
  }

  if (signalIs.hidden(signal, MOMENTS)
      || signalIs.hidden(signal, COLLECTIONS)
  ) {
    return updateItem<Moment | Collection>(
      uid,
      { is_hidden: signal.hidden },
    ) as Updater<T>;
  }

  if (signalIs.pinned(signal, MOMENTS)) {
    const { pin, pinning } = signal;

    return updateItem<Moment>(uid, ({ pk }) => ({
      pin: isMomentPinned(
        pinning,
        (pin.where === SPORTS ? pin.what : undefined) as SportSlug,
        pk,
      ),
    })) as Updater<T>;
  }

  if (signalIs.pinned(signal, COLLECTIONS)) {
    const { pin, pinning } = signal;

    return updateItem<Collection>(uid, ({ pk }) => ({
      pin: isCollectionPinned(
        pinning,
        (pin.where === SPORTS ? pin.what : undefined) as SportSlug,
        pk,
      ),
    })) as Updater<T>;
  }

  if (signalIs.published(signal, MOMENTS)) {
    const { publish, publication } = signal;

    return updateItem<Moment>(uid, {
      blocked: publish
        ? momentIsBlocked(publish.where, publication, publish.what)
        : publication.blocked,
      promoted: publication.promoted,
      publication,
      reviewed: publication.reviewed,
    }) as Updater<T>;
  }

  if (signalIs.published(signal, COLLECTIONS)) {
    const { publish, publication } = signal;

    return updateItem<Collection>(uid, {
      ...signal.publication,
      blocked: publish
        ? collectionIsBlocked(publish.where, publication, publish.what)
        : false,
    }) as Updater<T>;
  }

  if (signalIs.removed(signal, MOMENTS) || signalIs.removed(signal, COLLECTIONS)) {
    return updateRemove(uid);
  }

  if (signalIs.reviewed(signal, MOMENTS)) {
    const { reviewed } = signal;
    return updateItem<Moment>(uid, { reviewed }) as Updater<T>;
  }

  if (signalIs.updated(signal, MOMENTS) || signalIs.updated(signal, COLLECTIONS)) {
    return updateItem(uid, signal.item) as Updater<T>;
  }
}

/**
 * Traverses data in the cache and updates them by applying updater. If the data is unchanged,
 * the same value will be returned.
 * @param path Path where to look for values to update, a list of property names.
 *             If somewhere in the path value is an array, it will be traversed as well
 *             with applying the rest of the path
 * @param data Cache data
 * @param updater Updater function
 * @returns Updated cache data
 */
export function traverse<T>(
  path: string[],
  data: T,
  updater: (item: unknown) => unknown,
): T {
  if (Array.isArray(data)) {
    let changed = false;

    const result = data.map((d) => {
      const ret = !path || path.length === 0 ? updater(d) : traverse(path, d, updater);
      if (ret !== d) { changed = true; }
      return ret;
    }).filter(Boolean) as unknown as T;

    return changed ? result : data;
  }

  if (data) {
    if (!path || path.length === 0) {
      return updater(data) as T;
    }

    const p = path[0];
    const d = data[p];
    const ret = traverse(path.slice(1), d, updater);

    if (ret !== d) {
      return { ...data, [p]: ret };
    }
  }

  return data;
}

export const isConfigWithoutType = <
  W extends What
>(
  config: QueryCacheUpdateEntityConfig<W>,
): config is QueryCacheUpdateEntityConfigWithoutType<W> => (
  !(config as QueryCacheUpdateEntityConfigWithType<W>).what
);

export function update<W extends What, T extends WhatType<W>>(
  qc: QueryCache,
  config: QueryCacheUpdateEntityConfig<W>,
  signal: Signal<unknown, W, unknown>,
): QueryKey[] {
  const updater = getUpdater<W, T>(signal);
  const queries = qc.getQueries<T>(config.predicate);

  const keys = queries
    .map(({ queryKey, state: { data } }) => {
      let after = data;
      let process = true;

      if (config.updater) {
        [after, process] = config.updater(data, signal);
      }

      if (!isConfigWithoutType(config) && process) {
        after = traverse(config.path, after, updater);
      }

      if (after !== data) {
        qc.setQueryData(queryKey, after);
        return queryKey;
      }

      return null;
    }).filter(Boolean);

  return keys;
}

/**
 * Some requests include moments as well, this just enhances config
 * with the moments path
 * @param config Config to update
 * @returns Updated config
 */
export const getIncludedMomentsConfig = <W extends What>(
  config: QueryCacheUpdateEntityConfigWithType<W>,
): QueryCacheUpdateEntityConfigWithType<W> => ({
  ...config,
  path: [...(config.path || []), 'moments'],
});
