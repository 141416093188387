import { ID } from 'weplayed-typescript-api';

export const getKey = (
  uid?: ID,
  ...args: unknown[]
): string[] => ['moment', uid, ...args].filter(Boolean) as string[];

export const getPinsKey = (
  uid: ID,
): string[] => ['moment-pins', uid];
