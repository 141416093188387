import * as cx from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { formatDate } from 'common/utils/dates';

import { GameInfo } from 'cms/components/GameInfo';
import { CLIENT_URLS } from 'cms/routes';

import * as s from './GameHeadline.m.less';
import { Props } from './types';

export const GameHeadline: React.FC<Props> = function GameHeadline({
  className, children, game, disableLinks, hideDate, linkNewWindow,
}) {
  return (
    <div
      className={cx(s.root, className, disableLinks && hideDate && !game.name_extra && s.center)}
    >
      {!disableLinks && (
        <Link
          className={s.link}
          target={linkNewWindow ? '_blank' : undefined}
          to={CLIENT_URLS.GAME.VIEW.toPath({ uid: game.pk })}
        />
      )}
      {!hideDate && (
        <div className={s.text}>{formatDate(game.date_played)}</div>
      )}
      {children}
      <div>
        <GameInfo game={game} newWindow={linkNewWindow} disableLinks={disableLinks} />
      </div>
      {game.name_extra && (
        <div className={s.extra}>
          <span>{game.name_extra}</span>
        </div>
      )}
    </div>
  );
};
