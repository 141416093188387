import { QueryResult } from 'react-query';
import {
  Collection, EntityWithPublication, HttpError, ID, Moment, PinConfigEntry,
  PublishDestination, SportSlug, What,
} from 'weplayed-typescript-api';

export enum Kind {
  FEATURED = 'featured',
  RECOMMENDED = 'recommended',
}

export interface UseTop100TypeEntry<T> {
  featured: QueryResult<T[], HttpError>;
  recommended: QueryResult<T[], HttpError>;
  pin(item: T, pin: Omit<PinConfigEntry, 'id'> | false): void;
}

export interface UseTop100ReturnType {
  collections: UseTop100TypeEntry<Collection & EntityWithPublication>;
  isLoading: boolean;
  moments: UseTop100TypeEntry<Moment & EntityWithPublication>;
}

interface UseTop100Args {
  count?: number;
  enabled: What.MOMENTS | What.COLLECTIONS;
  recommended?: boolean;
  sport?: SportSlug;
  type: PublishDestination;
  uid: ID;
}

export type UseTop100Type = (args: UseTop100Args) => UseTop100ReturnType;
