import { LinkType, User, What, WhatType } from 'weplayed-typescript-api';

import { SocialTarget } from 'common/types';

import { Recipient } from 'cms/hooks/useRecipients/types';
import { ShareMeta } from 'cms/hooks/useShare';

export interface ShareData<T extends What> {
  type: T;
  items: Array<WhatType<T>>;
}

export type ShareSidebarProps<T extends What> = {
  type: T;
  onClose(): void;
  user?: User;
} & ({
  item?: never;
  items: Array<WhatType<T>>;
} | {
  item: WhatType<T>;
  items?: never;
});

export enum Tabs {
  EMAIL = 'email',
  SOCIAL = 'social',
  EMBED = 'embed',
}

export interface Messages {
  pluralItems: string;
}

export interface RecipientWithLink extends Recipient {
  link: LinkType;
}

export interface WebShareProps {
  social(target: SocialTarget): ShareMeta;
}

export interface DirectShareProps<T extends What> {
  user: User;
  context: ShareData<T>;
  onClose(): void;
}

export interface DirectShareLimitation {
  textLength: number;
  videoCount: number;
  videoLength: number;
}
