import { Base } from './Base';
import { Collections } from './Collections';
import { Games } from './Games';
import { Moments } from './Moments';
import { Players } from './Players';

export const Tiles = {
  Base,
  Collections,
  Games,
  Moments,
  Players,
};
