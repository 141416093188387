import { useQuery } from 'react-query';
import { HttpError, users } from 'weplayed-typescript-api';

import {
  ListUserRole, UseUserRolesReturnType, UseUserRolesType,
} from './types';
import { getKey } from './utils';

/**
 * Provides a list of user roles attached to organization
 */
export const useUserRoles: UseUserRolesType = function useUserRoles({
  enabled = true,
  status,
} = {}) {
  const user_roles: UseUserRolesReturnType['user_roles'] = useQuery<ListUserRole[], HttpError>(
    getKey(status),
    () => users.roles({ status }),
    {
      enabled,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    user_roles,
  };
};
