import cx from 'classnames';
import { ReactComponent as Loading } from 'images/spinner.svg';
import * as React from 'react';

import * as s from './Menus.m.less';
import { ActionName, MenuButtonProps } from './types';

type HocProps<A extends ActionName> = Pick<
  MenuButtonProps<A>,
  'onClick' | 'pk' | 'prefix' | 'status'
>;

const MenuButton = function MenuButton<A extends ActionName>({
  action,
  children,
  className,
  danger,
  icon: Icon,
  onClick,
  pk,
  prefix,
  show,
  status,
}: React.PropsWithChildren<MenuButtonProps<A>>): JSX.Element {
  if (!show) {
    return null;
  }

  const active = status?.[action]?.includes(pk);

  return (
    <button
      className={cx(s.button, danger && s.danger, className)}
      disabled={active}
      id={`${prefix}-${action}-${pk}`}
      name={action}
      onClick={onClick}
      type="button"
    >
      {active ? <Loading /> : <Icon />}
      {children}
    </button>
  );
};

export function MenuButtonHoC<A extends ActionName>(
  $props: HocProps<A>,
): React.FC<Omit<MenuButtonProps<A>, keyof HocProps<A>>> {
  return function _(props): JSX.Element {
    return <MenuButton<A> {...$props} {...props} />;
  };
}
