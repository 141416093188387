import * as cx from 'classnames';
import * as React from 'react';

import * as s from './FadeInImage.m.less';

export const FadeInImage: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement>
> = function FadeInImage({
  className,
  src,
  ...props
}) {
  const ref = React.useRef<HTMLImageElement>(null);

  React.useEffect(() => {
    if (src && ref.current) {
      const load = (): void => {
        if (ref.current) {
          ref.current.classList.add(s.loaded);
          ref.current.removeEventListener('load', load);
        }
      };

      ref.current.addEventListener('load', load);
      ref.current.src = src;
    }
  }, [src]);

  return src ? (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      ref={ref}
      draggable={false}
      className={cx(s.root, className)}
      {...props}
    />
   ) : null;
};
