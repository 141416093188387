import cx from 'classnames';

import * as s from './Controls.m.less';
import { MenuProps } from './types';

export const Menu = function Menu({
  disabled,
  hidden,
  onClick,
}: MenuProps): JSX.Element {
  return (
    <button
      className={cx(s.control, s.menu, hidden && s.hidden)}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      Show Menu
    </button>
  );
};
