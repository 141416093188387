import * as React from 'react';
import { useQueryCache } from 'react-query';
import { What } from 'weplayed-typescript-api';

import { signalIs, useDataSignal } from '../useDataSignal';
import { Signal } from '../useDataSignal/types';
import { QueryCacheUpdateEntityConfig, UseQueryCacheUpdateType } from './types';
import { getIncludedMomentsConfig, isConfigWithoutType, update } from './utils';

const { MOMENTS, COLLECTIONS, GAMES, PLAYERS, ATHLETES, SCHOOLS, TEAMS, ORGS, SPORTS } = What;

const timers = new WeakMap<() => void, NodeJS.Timer>();

export const useQueryCacheUpdate: UseQueryCacheUpdateType = function useQueryCacheUpdate(
  configs,
) {
  const { subscribe } = useDataSignal();
  const cache = useQueryCache();
  const invalidators = React.useRef<Array<() => void>>([]);

  React.useEffect(() => (): void => {
    invalidators.current.forEach((invalidator) => {
      clearTimeout(timers.get(invalidator));
      timers.delete(invalidator);
    });
  }, []);

  const up = React.useCallback(
    <W extends What>(
      config: QueryCacheUpdateEntityConfig<W>,
      signal: Signal<unknown, What, unknown>,
    ): void => {
      const updated = update(cache, config, signal);
      const [keys, inv] = typeof config.invalidate === 'function'
        ? config.invalidate(signal, updated)
        : [updated, config.invalidate];

      if (!inv || !keys.length) {
        return;
      }

      const invalidate = (): void => {
        const triggerInvalidate = (): void => {
          keys.forEach((key) => cache.invalidateQueries(key));
        };

        if (typeof inv === 'number') {
          const invalidator = (): void => {
            timers.delete(invalidator);
            const idx = invalidators.current.indexOf(invalidator);
            if (idx !== -1) {
              invalidators.current.splice(idx, 1);
            }

            triggerInvalidate();
          };
          timers.set(invalidator, setTimeout(invalidator, inv));
          invalidators.current.push(invalidator);
        } else if (inv) {
          triggerInvalidate();
        }
      };

      if (signal.optimistic) {
        signal.optimistic.then(invalidate);
      } else {
        invalidate();
      }
    },
    [cache],
  );

  React.useEffect(() => subscribe((signal) => {
    configs.forEach((config) => {
      if (isConfigWithoutType(config)) {
        up(config, signal);
      } else {
        const { what: cWhat } = config;

        // liked
        if (signalIs.liked(signal, MOMENTS)) {
          if (cWhat === MOMENTS) {
            up(config, signal);
          } else if (cWhat === COLLECTIONS) {
            up(getIncludedMomentsConfig(config), signal);
          } else if (cWhat === GAMES) {
            up(getIncludedMomentsConfig(config), signal);
          }
        } else if (signalIs.liked(signal, COLLECTIONS)) {
          if (cWhat === COLLECTIONS) {
            up(config, signal);
          }
        } else if (signalIs.liked(signal, GAMES)) {
          if (cWhat === GAMES) {
            up(config, signal);
          }
        } else if (signalIs.liked(signal, PLAYERS)) {
          if (cWhat === PLAYERS) {
            up(config, signal);
          }
        // hidden
        } else if (signalIs.hidden(signal, MOMENTS)) {
          if (cWhat === MOMENTS) {
            up(config, signal);
          } else if (cWhat === COLLECTIONS) {
            up(getIncludedMomentsConfig(config), signal);
          } else if (cWhat === GAMES) {
            up(getIncludedMomentsConfig(config), signal);
          }
        } else if (signalIs.hidden(signal, COLLECTIONS)) {
          if (cWhat === COLLECTIONS) {
            up(config, signal);
          }
        // pinned
        } else if (config.context && (
              signalIs.pinned(signal, MOMENTS)
              || signalIs.pinned(signal, COLLECTIONS)
        )) {
          const { athleteId, orgId, sport, teamId } = config.context;
          const { what, where, orgId: org } = signal.pin;

          if ((where === ATHLETES && what === athleteId)
            || (where === TEAMS && what === teamId)
            || (where === SPORTS && orgId === org && what === sport)
            || (where === ORGS && what === orgId && org === orgId)
            || (where === SCHOOLS && what === orgId)
          ) {
            if (signalIs.pinned(signal, MOMENTS)) {
              if (cWhat === MOMENTS) {
                up(config, signal);
              } else if (cWhat === COLLECTIONS) {
                up(getIncludedMomentsConfig(config), signal);
              } else if (cWhat === GAMES) {
                up(getIncludedMomentsConfig(config), signal);
              }
            } else if (signalIs.pinned(signal, COLLECTIONS)) {
              if (cWhat === COLLECTIONS) {
                up(config, signal);
              }
            }
          }

        // published
        } else if (config.context && (
              signalIs.published(signal, MOMENTS)
              || signalIs.published(signal, COLLECTIONS)
        )) {
          const { athleteId, orgId, sport, teamId } = config.context;
          const { what, where } = signal.publish;

          if ((where === ATHLETES && what === athleteId)
              || (where === TEAMS && what === teamId)
              || (where === SPORTS && what === sport)
              || (where === ORGS && what === orgId)
              || (where === SCHOOLS && what === orgId)
          ) {
            if (signalIs.published(signal, MOMENTS)) {
              if (cWhat === MOMENTS) {
                up(config, signal);
              } else if (cWhat === COLLECTIONS) {
                up(getIncludedMomentsConfig(config), signal);
              } else if (cWhat === GAMES) {
                up(getIncludedMomentsConfig(config), signal);
              }
            } else if (signalIs.published(signal, COLLECTIONS)) {
              if (cWhat === COLLECTIONS) {
                up(config, signal);
              }
            }
          }
        // removed
        } else if (signalIs.removed(signal, MOMENTS)) {
          if (cWhat === MOMENTS) {
            up(config, signal);
          } else if (cWhat === COLLECTIONS) {
            up(getIncludedMomentsConfig(config), signal);
          } else if (cWhat === GAMES) {
            up(getIncludedMomentsConfig(config), signal);
          }
        } else if (signalIs.removed(signal, COLLECTIONS)) {
          if (cWhat === COLLECTIONS) {
            up(config, signal);
          }
        // reviewed
        } else if (signalIs.reviewed(signal, MOMENTS)) {
          if (cWhat === MOMENTS) {
            up(config, signal);
          } else if (cWhat === COLLECTIONS) {
            up(getIncludedMomentsConfig(config), signal);
          } else if (cWhat === GAMES) {
            up(getIncludedMomentsConfig(config), signal);
          }
        // updated
        } else if (signalIs.updated(signal, MOMENTS)) {
          if (cWhat === MOMENTS) {
            up(config, signal);
          } else if (cWhat === COLLECTIONS) {
            up(getIncludedMomentsConfig(config), signal);
          } else if (cWhat === GAMES) {
            up(getIncludedMomentsConfig(config), signal);
          }
        } else if (signalIs.updated(signal, COLLECTIONS)) {
          if (cWhat === COLLECTIONS) {
            up(config, signal);
          }
        }
      }
    });
  }), [configs, up, subscribe]);
};
