import './Avatar.less';

import * as cx from 'classnames';
import * as React from 'react';

import { Props } from './types';

export const Avatar: React.FC<Props> = function Avatar({
  avatarUrl,
  children,
  className,
  hideVerified = false,
  imageId,
  size = 'small',
  user,
}) {
  const url = avatarUrl ?? (['small', 'xx-small', 'x-small'].includes(size)
    ? (user.avatar_64x64_url || user.avatar_url || user.avatar_480_480_url)
    : (user.avatar_480_480_url || user.avatar_url || user.avatar_64x64_url));

  return (
    <div
      aria-label={user.full_name}
      className={cx('avatar-container', size, className)}
      data-testid="avatar-container"
      title={user.full_name}
    >
      <div className="avatar-image-container" aria-hidden>
        {url ? (
          <img
            alt={user.full_name}
            id={imageId}
            src={url}
            data-testid="avatar-image"
          />
        ) : (
          <svg className="avatar-svg" viewBox="0 0 36 36" data-testid="avatar-image">
            <text x="18" y="26" textAnchor="middle" fontSize="20">
              {(user.full_name || '').split(/\s+/).slice(0, 2)
                .map((s) => s.substring(0, 1).toUpperCase()).join(' ')}
            </text>
          </svg>
        )}
      </div>
      {user.is_verified && !hideVerified ? (
        <b className=".avatar-badge" data-testid="verified-badge" />
      ) : undefined}
      {children}
    </div>
  );
};
