import { useMutation } from 'react-query';
import { players, What } from 'weplayed-typescript-api';

import { logger } from 'common/utils/logger';

import { about, useDataSignal } from '../useDataSignal';
import { UsePlayersReturnType, UsePlayersType } from './types';

export const usePlayers: UsePlayersType = function useGames() {
  const { notify } = useDataSignal();

  const like: UsePlayersReturnType['like'] = useMutation(
    (params: players.follow.Params): Promise<players.follow.Response> => notify(
      about.liked(What.PLAYERS, params.uid, params.like, players.follow(params)),
    ),
    {
      onError: (error, { uid, like: liked }) => {
        notify(about.liked(What.PLAYERS, uid, !liked));
        logger.error('Player following failed', { message: error.message });
      },
    },
  );

  const bulkLike: UsePlayersReturnType['bulkLike'] = useMutation(
    ({ uids, like: liked }) => notify(
      about.liked(What.PLAYERS, uids, liked, players.follow({ uid: uids, like: liked })),
    ),
    {
      onError: (error, { uids, like: liked }) => {
        notify(about.liked(What.PLAYERS, uids, !liked));
        logger.error('Bulk player following failed', { message: error.message });
      },
    },

  );

  return { like, bulkLike };
};
