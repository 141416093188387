import { Hash } from 'fast-sha256';
import { throttle } from 'lodash/fp';
import * as React from 'react';
import { games as api, HttpError, ID } from 'weplayed-typescript-api';

import { GameBasic } from 'cms/types';

import { BUFFER_SIZE, DONE_TIMEOUT, THREADS } from './constants';
import { reducer } from './reducer';
import {
  Action, ActionType, State, Stats, Status, UseUploadsReturnType,
} from './types';

enum ChunkStatus {
  WAITING,
  UPLOADING,
  DONE,
  ERROR,
}

interface ChunkInfo {
  part: number;
  length: number;
  status: ChunkStatus;
}

type StateInfo = Record<ID, {
  uploads: XMLHttpRequest[];
  active: boolean;
}>;

export const useUploads = function useUploads<
  T extends GameBasic = GameBasic
>(): UseUploadsReturnType<T> {
  const status = React.useRef<StateInfo>({});
  const [state, dispatch] = React.useReducer<(
    s: State<T>, a: ActionType<T>) => State<T>>(
    reducer,
    {},
  );

  const stop = React.useCallback((pk: ID) => {
    if (status.current[pk]) {
      status.current[pk].active = false;
      status.current[pk].uploads.forEach((xhr) => xhr.abort());
    }
  }, []);

  const append: UseUploadsReturnType<T>['append'] = React.useCallback(
    (game, $$file): void => {
      const { pk } = game;
      let checksum: string;
      const { size, name } = $$file;

      const upload = async (): Promise<void> => {
        dispatch({
          action: Action.START_UPLOAD,
          game,
        });

        status.current[pk].active = true;

        const parts = await api.upload.create({ name, size, digest: checksum });

        const chunks: ChunkInfo[] = parts.map((info) => ({
          ...info,
          status: info.length ? ChunkStatus.DONE : ChunkStatus.WAITING,
        }));

        const getLoaded = (): number => chunks.reduce((pp, { length }) => pp + length, 0);

        const preloaded = getLoaded();

        const stats = (): void => {
          const loaded = getLoaded();

          if (loaded !== preloaded) {
            dispatch({
              game,
              action: Action.PROGRESS,
              left: size - loaded,
            });
          }
        };

        stats();
        const uploadProgress = throttle(500, stats);

        const nextUpload = async (): Promise<void> => {
          const waiting = chunks.filter(({ status: s }) => s === ChunkStatus.WAITING).length;
          const uploading = chunks.filter(({ status: s }) => s === ChunkStatus.UPLOADING).length;

          if (uploading < THREADS && waiting) {
            const chunk = chunks.find(({ status: s }) => s === ChunkStatus.WAITING);

            if (chunk) {
              chunk.status = ChunkStatus.UPLOADING;

              const onError = (): void => {
                chunk.status = ChunkStatus.ERROR;
                stop(pk);
                dispatch({
                  action: Action.END_UPLOAD,
                  error: 'Problem with upload, try again in a few minutes',
                  game,
                });
                chunk.length = 0;
              };

              try {
                const { start, end, url } = await api.upload.chunk({
                  chunk: chunk.part,
                  digest: checksum,
                  name,
                  size,
                });

                const xhr = new XMLHttpRequest();
                status.current[pk].uploads.push(xhr);

                xhr.upload.addEventListener('progress', (e) => {
                  chunk.length = (e as Event & { loaded: number }).loaded;
                  uploadProgress();
                });

                xhr.upload.addEventListener('abort', onError);
                xhr.upload.addEventListener('timeout', onError);
                xhr.upload.addEventListener('error', onError);

                xhr.upload.addEventListener('load', () => {
                  chunk.length = end - start;
                  chunk.status = ChunkStatus.DONE;
                });

                xhr.upload.addEventListener('loadend', () => {
                  const idx = status.current[pk].uploads.findIndex((x) => x === xhr);
                  if (idx !== -1) {
                    status.current[pk].uploads.splice(idx, 1);
                  }

                  uploadProgress();
                  if (status.current[pk].active) {
                    nextUpload();
                  }
                });

                xhr.onreadystatechange = (): void => {
                  if (xhr.readyState === 4 && xhr.status === 200) {
                    // throw?
                  }
                  uploadProgress();
                };

                xhr.open('PUT', url);
                xhr.send($$file.slice(start, end));
              } catch (e) {
                onError();
              }
            }
          } else if (!waiting && !uploading) {
            dispatch({
              action: Action.COMMIT_START,
              game,
            });

            // this is required to wait for some time before finalize after
            // the last chunk is uploaded
            // otherwise API won't see all chunks ready to merge.
            setTimeout(async () => {
              try {
                const { success } = await api.upload.done({
                  name, size, digest: checksum, game_id: pk,
                });
                dispatch({ action: Action.COMMIT_END, game, success });
              } catch (e) {
                dispatch({
                  action: Action.COMMIT_END,
                  game,
                  success: false,
                  error: Object.values(
                    (e as HttpError<api.upload.done.DoneError>).json?.errors || {},
                  ).shift() || e.message,
                });
              } finally {
                status.current[pk].active = false;
              }
            }, DONE_TIMEOUT);
          }
        };

        for (let x = 0; x < Math.min(chunks.length, THREADS); x += 1) {
          nextUpload();
        }
      };

      const digest = (): void => {
        dispatch({ action: Action.START_DIGEST, game, file: $$file });
        status.current[pk] = { uploads: [], active: true };

        const sha = new Hash();
        const reader = new FileReader();
        let bytes = 0;

        const nextDigest = (): void => {
          if (status.current[pk].active) {
            const end = Math.min(bytes + BUFFER_SIZE, size);
            reader.readAsArrayBuffer($$file.slice(bytes, end));
          }
        };

        const digestProgress = throttle(
          500,
          (left) => dispatch({ action: Action.PROGRESS, left, game }),
        );

        reader.addEventListener('load', (e): void => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          bytes += data.length;
          sha.update(data);

          digestProgress(size - bytes);

          if (bytes === size) {
            digestProgress.cancel();
            checksum = Array.from(sha.digest()).map((c) => `0${c.toString(16)}`.slice(-2)).join('');
            dispatch({
              action: Action.END_DIGEST,
              digest: checksum,
              game,
            });
            status.current[pk].active = false;
            upload();
          } else {
            nextDigest();
          }
        });

        reader.addEventListener('error', (e): void => {
          stop(pk);
          dispatch({
            action: Action.END_DIGEST,
            digest: '',
            error: String(e),
            game,
          });
        });

        nextDigest();
      };

      if (!state[pk]?.digest) {
        digest();
      } else {
        checksum = state[pk].digest;
        upload();
      }
    },
    [state, stop],
  );

  const clear: UseUploadsReturnType<T>['clear'] = React.useCallback(
    (game) => {
      if (state[game.pk]?.status === Status.IDLE) {
        dispatch({ action: Action.CLEAR, game });
        delete status.current[game.pk];
      }
    },
    [state],
  );

  const abort: UseUploadsReturnType<T>['abort'] = React.useCallback(
    (game) => {
      stop(game.pk);
      dispatch({ action: Action.RESET, game });
    },
    [stop],
  );

  const resume: UseUploadsReturnType<T>['resume'] = React.useCallback(
    (game) => {
      if (state[game.pk].status === Status.IDLE && !state[game.pk].success) {
        append(game, state[game.pk].file);
      }
    },
    [append, state],
  );

  let d: number | number[] = Object.values(state)
    .filter(({ status: s }) => s === Status.DIGEST)
    .map(({ progress }) => progress);

  d = Math.floor((d.reduce((p, pp) => p + pp, 0)) / (d.length || 1));

  let u: number | number[] = Object.values(state)
    .filter(({ status: s }) => s === Status.UPLOAD)
    .map(({ progress }) => progress);

  u = Math.floor((u.reduce((p, pp) => p + pp, 0)) / (u.length || 1));

  const e = Object.values(state).reduce(
    (p, { status: s, error }) => p + (s === Status.IDLE && error ? 1 : 0),
    0,
  );

  const stats = React.useMemo<Stats>(
    () => ({ digest: d as number, upload: u as number, error: e }),
    [d, u, e],
  );

  const active = Object
    .values(state)
    .filter(({ status: s }) => s === Status.UPLOAD || s === Status.COMMIT).length;

  return {
    abort,
    active,
    append,
    clear,
    resume,
    state,
    stats,
  };
};
