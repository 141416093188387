import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { ItemsWrapper } from '../../ItemsWrapper';
import { ItemsWrapperProps } from '../../types';
import { Empty } from './Empty';
import * as s from './Items.m.less';

// eslint-disable-next-line prefer-arrow-callback
export const Wrapper = React.forwardRef(function Wrapper<T extends BaseEntityWithPk>(
  props: React.ComponentProps<'tbody'> & Omit<ItemsWrapperProps<'tbody', T>, 'as' | 'activeClassName' | 'empty'>,
  ref,
): JSX.Element {
  return (
    <ItemsWrapper
      activeClassName={s.dragover}
      as="tbody"
      empty={Empty}
      ref={ref}
      {...props}
    />
  );
});
