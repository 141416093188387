import cx from 'classnames';
import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { makeTokens } from 'common/utils/strings';

import { EntitiesListContext } from './constants';
import {
  EntitiesListContextType, ItemsWrapperProps, ItemsWrapperRendererProps,
} from './types';

/**
 * Base item list wrapper, controls drag/drop, text filtering
 * and actual rendering of items
 */
// eslint-disable-next-line prefer-arrow-callback
export const ItemsWrapper = React.forwardRef(function ItemsWrapper<
  E extends React.ElementType,
  T extends BaseEntityWithPk
>({
  activeClassName,
  as: As,
  children,
  className,
  empty: Empty,
  filter,
  renderer,
  toTokens,
  ...props
}: ItemsWrapperProps<E, T> & React.ComponentProps<E>, ref): JSX.Element {
  const {
    content, dragPosition, items, dragData, max, handleContainerDrag, setExpanded,
    handleContainerDrop, dragIndex, dropAllowed, expanded,
    checkbox, disabled, highlighted, selected, config: { size },
  } = React.useContext(EntitiesListContext) as EntitiesListContextType<T>;

  const hasDrop = Boolean(handleContainerDrop);
  const dragOver = dragPosition !== -1;
  const pk = dragData?.pk;
  const terms = filter && makeTokens(filter);
  const ret: JSX.Element[] = [];

  for (let index = 0; index < items.length; index += 1) {
    const item = items[index];

    // filter items
    if (terms && toTokens) {
      const tokens = toTokens(item);

      if (!terms.every((t) => tokens.some((to) => to.includes(t)))) {
        // eslint-disable-next-line no-continue
        continue;
      }
    }

    if (hasDrop
      ? (dragOver && dragPosition === index) || (!dragOver && pk === item.pk)
      : pk === item.pk
    ) {
      ret.push(<Empty
        key={`empty-${item.pk}`}
        allowDrop={hasDrop && dragPosition !== -1 && dropAllowed}
      />);
    }

    const hidden = (dragIndex === index)
      || (dragOver && dragIndex === -1 && index >= (max ?? items.length) - 1);

    const rendererProps: ItemsWrapperRendererProps<T> = {
      checkbox: checkbox ? checkbox(item) : null,
      content: expanded === item.pk ? content?.(item, setExpanded) : null,
      disabled: typeof disabled === 'function' ? disabled(item) : disabled,
      hidden,
      highlighted: Array.isArray(highlighted) ? highlighted.includes(item) : highlighted === item,
      index,
      item,
      key: item.pk,
      selected: selected?.includes(item),
      size,
    };

    ret.push(renderer(rendererProps));
  }

  if (hasDrop && dragPosition === items.length) {
    ret.push(<Empty key={`empty-${items.length}`} allowDrop visible />);
  }

  return (
    <As
      className={cx(className, dragData && activeClassName)}
      onDragEnd={handleContainerDrag}
      onDragEnter={handleContainerDrop}
      onDragLeave={handleContainerDrop}
      onDragOver={handleContainerDrop}
      onDragStart={handleContainerDrag}
      onDrop={handleContainerDrop}
      ref={ref}
      {...props}
    >
      {ret}
      {children}
    </As>
  );
});
