import {
  CollectionPublication, ID, PinConfig, PinConfigEntry, PublishDestination,
  SportSlug, What,
} from 'weplayed-typescript-api';

/**
 * Converts provided publishing status into collection blocked status
 * according to provided context
 * @param type Type where collection is published, team, org, school etc
 * @param publication Publication content
 * @param uid ID of where publishing is changed, e.g. team ID, athlete ID etc
 * @returns Blocked in the given context or not
 */
export const isBlocked = <T extends PublishDestination>(
  type: T,
  publication: CollectionPublication,
  uid: T extends What.SPORTS ? SportSlug : ID,
): boolean => {
  const {
    blocked_from_team,
    blocked_from_team_athlete,
    blocked_from_game,
    blocked_from_org,
    blocked_from_org_sports,
    blocked_from_org_schools,
  } = publication;

  switch (type) {
    case What.ORGS: {
      return blocked_from_org || false;
    }

    case What.SPORTS: {
      return blocked_from_org_sports?.includes(uid as SportSlug) || false;
    }

    case What.SCHOOLS: {
      return blocked_from_org_schools?.includes(uid) || false;
    }

    case What.TEAMS: {
      return blocked_from_team || false;
    }

    case What.GAMES: {
      return blocked_from_game || false;
    }

    case What.ATHLETES: {
      return blocked_from_team_athlete || false;
    }

    default: return false;
  }
};

/**
 * Detects is collection pinned in given context
 * @param config Context pin config
 * @param sport Sport slug if present
 * @param uid Collection ID
 * @returns Pin status
 */
export const isPinned = (
  config: PinConfig,
  sport: SportSlug | undefined,
  /**
   * Moment ID to search for in config
   */
  uid: ID,
): PinConfigEntry | false => {
  const path: keyof PinConfig = sport ? 'top-collection-sport-pins' : 'top-collection-pins';
  const pins: PinConfigEntry[] = sport ? config[path][sport] : config[path];
  const map = Object.fromEntries((pins || []).map((c) => [c.id, c]));

  return map?.[uid] || false;
};
