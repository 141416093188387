import * as React from 'react';
import { QueryResult } from 'react-query';
import {
  BaseEntityWithPk, Collection, CollectionTheme, HttpError, ID, Moment, User,
} from 'weplayed-typescript-api';

import { CreateParams, UpdateParams } from 'cms/hooks/useCollections/types';

export type CollectionModalProps = {
  clone?: boolean;
  onClose?(): void;
  onMomentSave?(moment: Moment): void;
  onSave?(uid: ID, collection: CreateParams | UpdateParams): void;
  onSuccess?(uid?: ID): void;
  saving?: boolean;
  user: User;
} & ({
  moments: Moment[];
  uid?: never;
} | {
  moments?: never;
  uid: ID;
});

export type ReducerAction = {
  action: 'set';
  collection: Collection;
} | ({
  action: 'update';
} & CreateParams);

export interface CollectionSectionProps {
  border?: boolean;
  disabled?: boolean;
}

export interface CollectionSectionRowProps {
  children: React.ReactNode | [React.ReactNode, React.ReactNode];
  disabled?: boolean;
  note?: React.ReactNode;
  title?: React.ReactNode;
}

interface UseDataArgs {
  athletes: BaseEntityWithPk[],
  game: BaseEntityWithPk,
  team: BaseEntityWithPk,
  theme: CollectionTheme;
}

export interface UseDataReturnType {
  availability: QueryResult<Record<ID, boolean>, HttpError>;
}

export type UseData = (args: UseDataArgs) => UseDataReturnType;

export enum Section {
  DETAILS, MOMENTS,
}

export type MomentAction = 'remove' | 'top' | 'bottom' | 'edit';
export interface SortableMenuProps {
  className?: string;
  hasEdit?: boolean;
  style?: React.CSSProperties;
  onClick(type: MomentAction): void;
}

export interface MomentProps {
  className?: string;
  momentIndex: number;
  value: Moment;
  onMenu(e: React.MouseEvent<HTMLButtonElement>): void;
}

export interface MomentsContainerProps {
  disabled?: boolean;
  editable?: boolean;
  hasEdit?: boolean;
  edit?: ID;
  items: Moment[];
  onAction(moment: Moment, action: MomentAction): void;
}
