import cx from 'classnames';
import { identity } from 'lodash/fp';
import { BaseEntityWithPk, Player as Player$ } from 'weplayed-typescript-api';

import { momentsCount } from 'common/utils/moments';

import { FadeInImage } from 'cms/components/FadeInImage';
import { SportLabel } from 'cms/components/SportLabel';
import { TeamImage } from 'cms/components/TeamImage';

import { defaults } from '../../constants';
import { PlayerItemProps } from '../../types';
import { Like, Link, Menu } from '../Controls';
import { EntityLink } from '../EntityLink';
import { players as def } from '../links';
import { useLink } from '../useLink';
import { Base } from './Base';
import * as s from './Items.m.less';

const d = defaults.tiles.players;

export const Player = function Player<
  T extends BaseEntityWithPk = Player$,
  D extends Player$ = Player$
>({
  className,
  getData = identity,
  item: $item,
  link,
  sport: $sport = d.sport,
  onLike,
  onLink,
  onMenu,
  ...props
}: PlayerItemProps<T, D>): JSX.Element {
  const item = getData($item);
  const url = useLink({ item, link, def });

  const {
    moment_count, name, photo_url, sport, number, position, team, followed_by_user,
  } = item;

  const thumb = (
    <>
      {photo_url && <FadeInImage src={photo_url} alt={name} className={s.photo} />}
      <div className={s.thumbnail}><TeamImage team={team} size="large" /></div>
    </>
  );

  const text = `${name} ${number ? ` - #${number} ${position}` : ` - ${position}`}`;

  return (
    <Base<T>
      {...props}
      className={cx(s.player, className)}
      controls={[
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link key="link" onClick={onLink} />,
        <Like
          className={cx(followed_by_user && s.visible)}
          key="like"
          like={followed_by_user}
          onClick={onLike}
        />,
        <Menu key="menu" onClick={onMenu} className={s.visible} />,
      ]}
      item={$item}
    >
      <EntityLink
        className={cx(s.rtile, sport?.slug)}
        url={url}
      >
        {thumb}
      </EntityLink>
      <div className={s.extra}>
        <div className={s.name} title={text}>{text}</div>
        <div className={s.info}>
          {$sport && <span><SportLabel sport={sport} /></span>}
          {moment_count && <span>{momentsCount(moment_count)}</span>}
        </div>
      </div>
    </Base>
  );
};
