import { intersection, uniq, without } from 'lodash/fp';

import { ReducerAction, ReducerState } from './types';

export const reducer = (
  state: ReducerState,
  action: ReducerAction,
): ReducerState => {
  const { remove, add } = state;

  if (typeof action === 'string') {
    return { add: uniq([...add, action]), remove };
  }

  const { collection: { pk, moment_ids }, momentIDs: ids } = action;

  const isLength = intersection(moment_ids, ids).length;
  const mLength = ids.length;
  const hasAdd = add.includes(pk);
  const hasRemove = remove.includes(pk);

  if (isLength === 0) {
    if (hasAdd) {
      return { add: without([pk], add), remove };
    }

    return { add: uniq([...add, pk]), remove };
  }

  if (isLength === mLength) {
    if (hasRemove) {
      return { add, remove: without([pk], remove) };
    }

    return { add, remove: uniq([...remove, pk]) };
  }

  if (hasAdd) {
    return { add: without([pk], add), remove: uniq([...remove, pk]) };
  }

  if (hasRemove) {
    return { add, remove: without([pk], remove) };
  }

  return { add: uniq([...add, pk]), remove };
};
