import { noop } from 'lodash/fp';
import * as React from 'react';

import { ExportsContextType } from './types';

export const ExportsContext = React.createContext<ExportsContextType>({
  clear: noop,
  dismiss: noop,
  download: noop,
  share: noop,
  state: [],
});

export const INTERVAL = 3000;
