import * as cx from 'classnames';
import * as React from 'react';

import * as s from './SectionNav.m.less';
import { SectionNavProps } from './types';

export const SectionNav: React.FC<SectionNavProps> = function SectionNav({
  children,
  className,
  disabled,
  underline = true,
}) {
  return (
    <ul className={cx(s.root, className, underline && s.underline, disabled && s.disabled)}>
      {children}
    </ul>
  );
};
