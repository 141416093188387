import cx from 'classnames';
import { Overlay } from 'react-bootstrap';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import * as s from './Menus.m.less';
import { BaseMenuProps } from './types';

export const Menu = function Menu<T extends BaseEntityWithPk>({
  className,
  children,
  item,
  onClose,
  placement = 'auto-start',
  target,
}: React.PropsWithChildren<BaseMenuProps<T>>): JSX.Element {
  return (
    <Overlay
      flip
      onHide={onClose}
      placement={placement}
      rootClose
      rootCloseEvent="click"
      show={Boolean(target && item)}
      target={target}
    >
      {({ className: cs, style, ref }): React.ReactNode => (
        <div
          className={cx(s.root, className, cs)}
          ref={ref}
          style={style}
        >
          {children}
        </div>
      )}
    </Overlay>
  );
};
