import * as cx from 'classnames';
import * as React from 'react';

import { getLabelForSlug } from 'common/utils/sports';

import * as s from './SportLabel.m.less';
import { SportLabelProps } from './types';

export const SportLabel: React.FC<SportLabelProps> = function SportLabel({
  sport, children,
}) {
  return (
    <span className={cx(s.root, sport?.slug)}>
      {getLabelForSlug(sport?.slug) || <>&nbsp;</>}
      {children}
    </span>
  );
};
