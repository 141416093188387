import { Moment, What } from 'weplayed-typescript-api';

import { makeTokens } from 'common/utils/strings';

import { Base } from './Base';
import { Moments as List } from './Lists/Moments';
import { Moments as Tiles } from './Tiles/Moments';
import { MomentsProps } from './types';

const renderers = [Tiles, List] as const;

const toTokens = <T extends Moment>({ name }: T): string[] => makeTokens(name);

// eslint-disable-next-line react/function-component-definition
export function Moments<
  T extends Moment = Moment
>(props: MomentsProps<T>): JSX.Element {
  return (
    <Base
      renderers={renderers}
      toTokens={toTokens}
      what={What.MOMENTS}
      {...props}
    />
  );
}
