import cx from 'classnames';

import * as s from './Controls.m.less';
import { LinkProps } from './types';

export const Link = function Link({
  className,
  disabled,
  hidden,
  onClick,
}: LinkProps): JSX.Element {
  return (
    <button
      className={cx(s.control, s.link, hidden && s.hidden, className)}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      Copy URL
    </button>
  );
};
