import { Collection, CollectionTheme } from 'weplayed-typescript-api';

export const REQUIRES_TEAM = Object.values(CollectionTheme);
export const REQUIRES_GAME = [
  CollectionTheme.GAME_DIGEST,
  CollectionTheme.IN_THE_ZONE,
  CollectionTheme.PIVOTAL_MOMENTS,
];
export const REQUIRES_ATHLETE = [CollectionTheme.ULTIMATE_REEL, CollectionTheme.IN_THE_ZONE];

export const emptyPublish: Pick<
  Collection,
  'blocked_from_game' | 'blocked_from_team_athlete' | 'blocked_from_team'
> = {
  blocked_from_team: true,
  blocked_from_game: true,
  blocked_from_team_athlete: true,
};

export const privateText = 'When a playlist is set to private, it can only be viewed by the '
  + 'creator. Private playlists cannot be published or shared';

export const publicText = 'This playlist can be viewed by all CMS users as well '
  + 'as any outside recipients who receive a public link';

export const automaticText = 'When set to Automatically Updated, the WePlayed system '
  + 'maintains the moments that make up that playlist';

export const CollectionThemeName: Record<CollectionTheme, string> = {
  [CollectionTheme.BREAKDOWN]: 'Breakdown',
  [CollectionTheme.GAME_DIGEST]: 'Game Digest',
  [CollectionTheme.IN_THE_ZONE]: 'In The Zone',
  [CollectionTheme.PIVOTAL_MOMENTS]: 'Pivotal Moments',
  [CollectionTheme.ULTIMATE_REEL]: 'Ultimate Reel',
};
