import cx from 'classnames';
import { Link } from 'react-router-dom';

import * as s from './Controls.m.less';
import { MoreProps } from './types';

export const More = function More({
  disabled,
  hidden,
  open = false,
  onClick,
  title = 'details',
  url,
}: MoreProps): JSX.Element {
  const text = `${open ? 'Hide' : 'Show'} ${title}`;

  if (url) {
    return (
      <Link
        className={cx(s.control, s.more, open && s.open, hidden && s.hidden)}
        onClick={onClick}
        title={text}
        to={url}
      >
        {text}
      </Link>
    );
  }

  return (
    <button
      className={cx(s.control, s.more, open && s.open, hidden && s.hidden)}
      disabled={disabled}
      onClick={onClick}
      title={text}
      type="button"
    >
      {text}
    </button>
  );
};
