import cx from 'classnames';

import * as s from './Controls.m.less';
import { VisibilityProps } from './types';

export const Visibility = function Visibility({
  disabled,
  hidden,
  onClick,
  visible,
}: VisibilityProps): JSX.Element {
  return (
    <button
      className={cx(s.control, s.visibility, !visible && s.priv, hidden && s.hidden)}
      disabled={disabled}
      onClick={onClick}
      title="Private visibility"
      type="button"
    >
      private
    </button>
  );
};
