import cx from 'classnames';
import * as React from 'react';
import { Table } from 'react-bootstrap';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { NothingFound } from 'cms/components/NothingFound';
import { TableLoading } from 'cms/components/TableLoading';

import { EntitiesListContext } from '../constants';
import * as i from '../Items/Lists/Items.m.less';
import { Wrapper } from '../Items/Lists/Wrapper';
import * as h from './Header.m.less';
import * as s from './Lists.m.less';
import { BaseEntitiesProps, HeaderDescription } from './types';

// eslint-disable-next-line react/function-component-definition
export function Base<T extends BaseEntityWithPk>({
  className,
  filter,
  headers,
  loading,
  renderer,
  showNotFound,
  toTokens,
  ...props
}: BaseEntitiesProps<T>): JSX.Element {
  const {
    selected,
    config: { size },
    handleExpand,
    handleSelect,
  } = React.useContext(EntitiesListContext);

  const selecting = Boolean(selected?.length || props.checkbox);

  return (
    <>
      <Table
        className={cx(
          s.table,
          props.disabled === true && s.disabled,
          className,
        )}
        data-size={size}
      >
        {headers && (
          <thead>
            <tr>
              {/* checkbox is not included in `headers` */}
              {handleSelect && <th className={h.checkbox}>&nbsp;</th>}
              {headers.filter(Boolean).map(([header, cname]: HeaderDescription, idx) => (
                // since the header may have the same value, involve index in
                // key generation
                // eslint-disable-next-line react/no-array-index-key
                <th className={cname} key={`${idx}-${String(header)}`}>
                  {header === true ? ' ' : header}
                </th>
              ))}
              {handleExpand && <th className={h.control}>&nbsp;</th>}
            </tr>
            <tr className={s.spacer}><td colSpan={100} /></tr>
          </thead>
        )}
        <Wrapper
          className={cx(selecting && i.selecting)}
          filter={filter}
          renderer={renderer}
          toTokens={toTokens}
        />
      </Table>
      <TableLoading
        padTop={!props.items}
        count={typeof loading === 'number' ? loading : undefined}
        show={Boolean(loading)}
      />
      {showNotFound && !loading && props.items?.length === 0 && (
        <NothingFound text={showNotFound} />
      )}
    </>
  );
}
