import cx from 'classnames';
import * as React from 'react';
import { Button, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { exprts, ID, What } from 'weplayed-typescript-api';

import { useApplication } from 'common/hooks/useApplication';
import { usePrevious } from 'common/hooks/usePrevious';

import { ExportsState, useExports } from 'cms/hooks/useExports';
import { COLLECTION$$, MOMENT$$ } from 'cms/routes';

import { BlockingWarning } from './constants';
import * as s from './ExportsMenu.m.less';
import { ExportsMenuProps } from './types';

interface OverlayRenderer {
  dismiss(jonUid: ID, uid: ID): void;
  notification: exprts.NotificationType;
  onClear(e: React.MouseEvent);
  state: ExportsState[],
}

const renderOverlay = ({
  dismiss,
  notification,
  onClear,
  state,
}: OverlayRenderer) => function renderOverlayFactory({
  show: _1, arrowProps: _2, ...props
}): JSX.Element {
  return (
    <div className={s.root} {...props}>
      <ul className={s.list}>
      {state.filter(({ hidden }) => !hidden).map(({ errors, jobUid, name, type, uid, url }) => (
        <li key={`${jobUid}-${uid}`}>
          <div>
            <span
              className={cx(
                s.icon,
                (type === What.MOMENTS && s.moment) || (type === What.COLLECTIONS && s.collection),
              )}
            />
            <Link
              to={type === What.MOMENTS
                ? MOMENT$$.buildPath({ uid })
                : COLLECTION$$.buildPath({ uid })}
              className={s.name}
            >
              {name}
            </Link>
            {url && notification === exprts.NotificationType.DOWNLOAD && (
              <a href={url} className={s.download} target="_blank" rel="noreferrer">Download</a>
            )}
            <button
              type="button"
              className={(errors || url) ? s.dismiss : s.processing}
              disabled={!url && !errors}
              onClick={(): void => (url || errors) && dismiss(jobUid, uid)}
            >
              {(url || errors) ? 'Remove from list' : 'Preparing...'}
            </button>
          </div>
          {errors && (
            <ul className={s.errors}>
              {errors.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          )}
        </li>
      ))}
      </ul>
      <Button
        type="button"
        className={s.clear}
        onClick={onClear}
        data-type={notification}
      >
        Clear
      </Button>
    </div>
  );
};

export const ExportsMenu: React.FC<ExportsMenuProps> = function ExportsMenu({
  className,
}) {
  const warning = React.useRef(false);

  const { broadcast } = useApplication();

  const { state, clear, dismiss } = useExports();
  const [showDownloads, setShowDownloads] = React.useState(false);
  const [showShares, setShowShares] = React.useState(false);
  const downloadButton = React.useRef<HTMLButtonElement>();
  const shareButton = React.useRef<HTMLButtonElement>();

  const prevState = usePrevious(state);

  React.useEffect(() => {
    state.forEach((item) => {
      const prev = prevState.find(({ uid, jobUid }) => uid === item.uid && jobUid === item.jobUid);

      // try to open window for ready export
      if (item.notification_type.includes(exprts.NotificationType.DOWNLOAD)
          && ((!prev && item.url) || (prev && (!prev.url && item.url)))) {
        const win = window.open(item.url, '_blank');

        if (!warning.current && (!win || win.closed || typeof win.closed === 'undefined')) {
          warning.current = true;
          broadcast('error', { jsx: BlockingWarning, message: '' });
        }
      }

      if (item.notification_type.includes(exprts.NotificationType.SOCIAL)) {
        if ((!prev && item.errors) || (prev && (!prev.errors && item.errors))) {
          const text = (
            <div>
              Share request resulted with error:
              <ul className={s.berrors}>
                {item.errors.map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            </div>
          );
          broadcast('error', { message: '', jsx: text });
        } else if ((!prev && item.url) || (prev && (!prev.url && item.url))) {
          broadcast('success', 'Share completed');
        }
      }
    });
  }, [state, prevState, broadcast]);

  const stateDownloads = state.filter(
    ({ notification_type }) => notification_type.includes(exprts.NotificationType.DOWNLOAD),
  );
  const stateShares = state.filter(
    ({ notification_type }) => notification_type.includes(exprts.NotificationType.SOCIAL),
  );

  const hasDownloads = stateDownloads.length !== 0;
  const hasShares = stateShares.length !== 0;

  const downloadsLen = stateDownloads.length;
  const prevDownloadsLen = usePrevious(stateDownloads.length);

  if (downloadsLen > prevDownloadsLen && downloadButton.current) {
    if (downloadButton.current.classList.contains(s.none)) {
      downloadButton.current.classList.remove(s.none);
    } else {
      downloadButton.current.classList.add(s.none);
      setTimeout(() => downloadButton.current.classList.remove(s.none), 0);
    }
  }

  const sharesLen = stateShares.length;
  const prevSharesLen = usePrevious(stateShares.length);

  if (sharesLen > prevSharesLen && shareButton.current) {
    if (shareButton.current.classList.contains(s.none)) {
      shareButton.current.classList.remove(s.none);
    } else {
      shareButton.current.classList.add(s.none);
      setTimeout(() => shareButton.current.classList.remove(s.none), 0);
    }
  }

  const pendingDownloads = stateDownloads.some(({ url, errors }) => !url && !errors);
  const errorsDownloads = stateDownloads.some(({ errors }) => Boolean(errors));

  const pendingShares = stateShares.some(({ url, errors }) => !url && !errors);
  const errorsShares = stateShares.some(({ errors }) => Boolean(errors));

  const handleClear = React.useCallback(({ target }: React.MouseEvent<HTMLButtonElement>) => {
    const type = (target as HTMLButtonElement)
      .getAttribute('data-type') as exprts.NotificationType;
    clear(type);
  }, [clear]);

  const renderDownloads = React.useMemo(
    () => renderOverlay({
      state: stateDownloads,
      onClear: handleClear,
      dismiss,
      notification: exprts.NotificationType.DOWNLOAD,
    }),
    [dismiss, handleClear, stateDownloads],
  );

  const renderShares = React.useMemo(
    () => renderOverlay({
      state: stateShares,
      onClear: handleClear,
      dismiss,
      notification: exprts.NotificationType.SOCIAL,
    }),
    [dismiss, handleClear, stateShares],
  );

  return (
    <>
      {hasDownloads && (
        <OverlayTrigger
          onToggle={setShowDownloads}
          overlay={renderDownloads}
          placement="bottom-end"
          rootClose
          show={showDownloads}
          trigger="click"
        >
          <button
            className={cx(s.downloads, s.none, className)}
            ref={downloadButton}
            type="button"
          >
            Downloads
            {pendingDownloads
              ? <span className={s.btnActive} />
              : (errorsDownloads && <span className={s.btnError} />)}
          </button>
        </OverlayTrigger>
      )}
      {hasShares && (
        <OverlayTrigger
          onToggle={setShowShares}
          overlay={renderShares}
          placement="bottom-end"
          rootClose
          show={showShares}
          trigger="click"
        >
          <button
            className={cx(s.shares, s.none, className)}
            ref={shareButton}
            type="button"
          >
            Shares
            {pendingShares
              ? <span className={s.btnActive} />
              : (errorsShares && <span className={s.btnError} />)}
          </button>
        </OverlayTrigger>
      )}
    </>
  );
};
