import pool from '@ricokahler/pool';
import { useQuery } from 'react-query';
import { search, What } from 'weplayed-typescript-api';

import { SearchParams } from 'cms/hooks/useSearch/types';

import { UseData, UseDataReturnType } from './types';

type QueryType = Pick<
  SearchParams<What.COLLECTIONS>,
  'dedicated_team_id' | 'dedicated_game_id' | 'dedicated_team_athlete_id' | 'theme'
>;

const getTotal = async (query: QueryType): Promise<boolean> => {
  const { total } = await search.collections.data({ ...query, text: '' }, 1);

  return total === 0;
};

export const useData: UseData = function useData({
  athletes,
  game,
  team,
  theme,
}) {
  const availability: UseDataReturnType['availability'] = useQuery(
    ['collections-summary', team, game, ...athletes],
    async () => {
      const collection = ([
        ...(athletes?.map(({ pk }) => ({
          dedicated_team_id: team.pk,
          dedicated_team_athlete_id: pk,
          dedicated_game_id: game?.pk,
          theme: [theme],
          pk,
        })) || []),
        game && (!athletes || athletes.length === 0) ? {
          dedicated_team_id: team.pk,
          dedicated_game_id: game.pk,
          theme: [theme],
          pk: game.pk,
        } : null,
      ]).filter(Boolean);

      return Object.fromEntries(await pool({
        collection,
        maxConcurrency: 3,
        task: async (query) => {
          const { pk, ...qq } = query;
          const available = await getTotal(qq);
          return [pk, available];
        },
      }));
    },
    {
      enabled: theme && team && (athletes?.length || game),
    },
  );

  return { availability };
};
