import cx from 'classnames';

import * as s from './Controls.m.less';
import { PinProps } from './types';

export const Pin = function Pin({
  disabled,
  hidden,
  loading,
  onClick,
  pin,
}: PinProps): JSX.Element {
  return (
    <button
      className={cx(
        s.control,
        s.pin,
        pin && s.pinned,
        loading && s.loading,
        hidden && s.hidden,
      )}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      Pin
    </button>
  );
};
