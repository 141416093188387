import * as React from 'react';
import Helmet from 'react-helmet';

import { useProfile } from 'common/hooks/useProfile';

import { Button } from 'cms/components/Button';
import FormField from 'cms/components/Field/FormField';
import NonFieldErrors from 'cms/components/Field/NonFieldErrors';

import { Form } from './types';

export const ResetPassword: React.FunctionComponent = function ResetPassword() {
  const { reset_password: [update, { isLoading, error, isSuccess }] } = useProfile();
  const handleSubmit = React.useCallback(
    (e: React.FormEvent<Form>): void => {
      e.preventDefault();

      update({ email: e.currentTarget.elements.email.value });
    },
    [update],
  );

  const errors = error?.json;

  return (
    <div className="container">
      <Helmet>
        <title>WePlayed: Reset Password</title>
      </Helmet>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <h1>Reset Password</h1>

          {isSuccess ? (
            <p>Please check your email inbox and follow the instructions.</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <NonFieldErrors errors={errors} />
              <FormField label="Email" name="email" errors={errors} required>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  required
                />
              </FormField>
              <Button type="submit" size="lg" disabled={isLoading} loading={isLoading}>
                Reset Password
              </Button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
