/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as cx from 'classnames';
import * as React from 'react';

import { PageHeader } from '../PageHeader';
import * as s from './PageSidebar.m.less';
import { PageSidebarProps } from './types';

export const PageSidebar: React.FC<PageSidebarProps> = function PageSidebar({
  className,
  close,
  children,
  disabled,
  onClose,
  show,
  title,
  // width = '600px',
}) {
  React.useEffect(() => {
    if (show) {
      document.body.classList.add(s.body);
      return (): void => document.body.classList.remove(s.body);
    }
  }, [show]);

  return (
    <>
      <div className={cx(s.backdrop, show && s.active)} onClick={onClose} />
      <div className={cx(s.root, className, !show && s.hidden, disabled && s.disabled)}>
        <div className={s.slider}>
          {title && (
            <div className={s.title}>
              <PageHeader className={s.titleText}>{title}</PageHeader>
            </div>
          )}
          {close && onClose && (
            <button className={s.close} type="button" onClick={onClose}>
              Close
            </button>
          )}
          {children}
        </div>
      </div>
    </>
  );
};
