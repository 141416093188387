import * as React from 'react';
import { Table } from 'react-bootstrap';
import {
  ID, PublishDestination, SportSlug, What,
} from 'weplayed-typescript-api';

import { ucFirst } from 'common/utils/helpers';

import { EntitiesListType } from '../Entities';
import { PageSidebar, PageSidebarSection } from '../PageSidebar';
import { TableLoading } from '../TableLoading';
import {
  HOMEPAGE_HELP_TEXT, ROSTER_HELP_TEXT, SCHOOL_HELP_TEXT, SPORT_HELP_TEXT,
  TEAM_HELP_TEXT,
} from './constants';
import * as s from './PublishSidebar.m.less';
import { PublishSidebarControls } from './PublishSidebarControls';
import { PublishableType, PublishSidebarProps } from './types';

export const PublishSidebar = function PublishSidebar<
  T extends PublishableType,
  W extends PublishDestination
>({
  onBlock,
  onClose,
  onLoading,
  renderer: Renderer,
  status,
  uid,
  useData,
}: PublishSidebarProps<T, W>): JSX.Element {
  const [what, onWhat] = React.useState<W extends What.SPORTS ? SportSlug : ID>(null);
  const [where, onWhere] = React.useState<W>(null);

  React.useEffect(() => {
    onWhere(null);
    onWhat(null);
  }, [uid]);

  const {
    athletes, blocking, item, org, teams, sports, schools,
    names, loading, onPin,
  } = useData({ status, uid, what, where });

  const [, plural] = names;

  const onPinEdit = React.useCallback(
    ($where: W, $what: W extends What.SPORTS ? SportSlug : ID) => {
      onWhere($where);
      onWhat($what);
    },
    [],
  );

  React.useEffect(() => {
    if (onLoading) {
      onLoading(loading);
    }
  }, [onLoading, loading]);

  const handleBlock = React.useCallback(
    <D extends PublishDestination>(
      $where: D,
      $what: D extends What.SPORTS ? SportSlug : ID,
      blocked: boolean,
    ) => {
      if (!onBlock) {
        return;
      }

      onBlock({
        blocked,
        where: $where,
        what: $what,
      });
    },
    [onBlock],
  );

  const help = React.useMemo(() => (
    <ul className={s.help}>
    {[
      org && <li key="org">{HOMEPAGE_HELP_TEXT}</li>,
      sports && <li key="sports">{SPORT_HELP_TEXT}</li>,
      schools && <li key="schools">{SCHOOL_HELP_TEXT}</li>,
      teams && <li key="teams">{TEAM_HELP_TEXT}</li>,
      athletes && <li key="athletes">{ROSTER_HELP_TEXT}</li>,
    ].filter(Boolean)}
    </ul>
  ), [athletes, org, schools, sports, teams]);

  const items = React.useMemo(() => (item ? [item] : undefined), [item]);

  return (
    <PageSidebar
      className={s.root}
      show={Boolean(uid)}
      onClose={onClose}
      close
      title={(
        <>
          Publish
          <span>{`/ ${ucFirst(plural)}`}</span>
        </>
      )}
    >
      <PageSidebarSection>
        <Renderer
          created={false}
          edited={false}
          header={false}
          items={items}
          loading={items ? false : 1}
          sport={false}
          status={status}
          type={EntitiesListType.LIST}
        />
      </PageSidebarSection>

      <PageSidebarSection
        help={help}
        title="Select where you would like to publish this content"
      >
        <p>
          Unpublishing content will prevent it from showing up in the widget but
          you will still be able to find it in the CMS
        </p>
        {!items ? (
          <TableLoading />
        ) : (
          <Table className={s.table}>
            <tbody>
              <PublishSidebarControls<T>
                blocking={blocking}
                entries={org}
                item={item}
                name="Home page"
                names={names}
                onBlock={handleBlock}
                onPin={onPin}
                onPinEdit={onPinEdit}
                where={What.ORGS}
              />
              <PublishSidebarControls<T>
                blocking={blocking}
                entries={sports}
                item={item}
                name="Team pages"
                names={names}
                onBlock={handleBlock}
                onPin={onPin}
                onPinEdit={onPinEdit}
                where={What.SPORTS}
              />
              <PublishSidebarControls<T>
                blocking={blocking}
                entries={schools}
                item={item}
                name="School pages"
                names={names}
                onBlock={handleBlock}
                onPin={onPin}
                onPinEdit={onPinEdit}
                where={What.SCHOOLS}
              />
              <PublishSidebarControls<T>
                blocking={blocking}
                entries={athletes}
                item={item}
                name="Roster pages"
                names={names}
                onBlock={handleBlock}
                onPin={onPin}
                onPinEdit={onPinEdit}
                where={What.ATHLETES}
              />
              <PublishSidebarControls<T>
                blocking={blocking}
                entries={teams}
                item={item}
                name="Team page"
                names={names}
                onBlock={handleBlock}
                onPin={onPin}
                onPinEdit={onPinEdit}
                where={What.TEAMS}
              />
            </tbody>
          </Table>
        )}
      </PageSidebarSection>
    </PageSidebar>
  );
};
