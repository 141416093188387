import * as cx from 'classnames';
import * as React from 'react';
import ClipboardButton from 'react-clipboard.js';
import { LinkType, What } from 'weplayed-typescript-api';

import { Button } from 'cms/components/Button';

import * as s from './CopyLinkDropdown.m.less';
import { LinkTypeButton } from './LinkTypeButton';
import { CopyLinkFormProps } from './types';
import { useCopyLink } from './useCopyLink';

export const CopyLinkForm = function CopyLinkForm<T extends What>({
  className,
  context,
  item,
  orgId,
  type,
  userId,
  value,
}: CopyLinkFormProps<T>): JSX.Element {
  const { links, handleSuccess } = useCopyLink({ type, item, context, userId, orgId });

  const [copyLinkType, setCopyLinkType] = React.useState<LinkType>(
    () => value || Object.entries(links).filter(([, l]) => l).map(([t]) => t).shift() as LinkType,
  );

  return (
    <div className={cx(s.form, className)}>
      <LinkTypeButton
        description
        disabled={Object.values(links).filter(Boolean).length === 1}
        onChange={setCopyLinkType}
        value={copyLinkType}
      />
      <Button
        as={ClipboardButton}
        data-clipboard-text={links?.[copyLinkType]}
        onSuccess={handleSuccess}
      >
        Copy Link
      </Button>
    </div>
  );
};
