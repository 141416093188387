import { omit } from 'lodash/fp';

import { GameBasic } from 'cms/types';

import { defaultState } from './constants';
import { Action, ActionType, State, Status } from './types';

export const reducer = <T extends GameBasic>(
  s: State<T>,
  { game, ...action }: ActionType<T>,
): State<T> => {
  const ss = s[game.pk];

  switch (action.action) {
    case Action.RESET: {
      return {
        ...s,
        [game.pk]: {
          ...ss,
          error: null,
          game,
          progress: 0,
          status: Status.IDLE,
          success: false,
        },
      };
    }

    case Action.START_DIGEST: {
      return {
        ...s,
        [game.pk]: {
          ...defaultState,
          game,
          file: action.file,
          name: action.file.name,
          size: action.file.size,
          status: Status.DIGEST,
        },
      };
    }

    case Action.END_DIGEST: {
      return {
        ...s,
        [game.pk]: {
          ...ss,
          digest: action.digest,
          error: action.error ? [action.error] : null,
          progress: 0,
          status: Status.IDLE,
          success: false,
        },
      };
    }

    case Action.START_UPLOAD: {
      return {
        ...s,
        [game.pk]: {
          ...ss,
          error: null,
          progress: 0,
          status: Status.UPLOAD,
          success: false,
        },
      };
    }

    case Action.END_UPLOAD: {
      return {
        ...s,
        [game.pk]: {
          ...ss,
          error: action.error ? [action.error] : null,
          status: Status.IDLE,
        },
      };
    }

    case Action.COMMIT_START: {
      return {
        ...s,
        [game.pk]: {
          ...ss,
          status: Status.COMMIT,
        },
      };
    }

    case Action.COMMIT_END: {
      return {
        ...s,
        [game.pk]: {
          ...ss,
          error: action.error ? [action.error] : null,
          progress: 1,
          status: Status.IDLE,
          success: action.success,
        },
      };
    }

    case Action.PROGRESS: {
      if (ss.status === Status.DIGEST || ss.status === Status.UPLOAD) {
        const progress = Math.floor(10000 * (1 - action.left / s[game.pk].size)) / 100;

        return progress !== s[game.pk].progress
          ? {
            ...s,
            [game.pk]: {
              ...s[game.pk],
              progress,
            },
          }
          : s;
      }

      return s;
    }

    case Action.CLEAR: {
      return omit(game.pk, s);
    }

    default: return s;
  }
};
