import { useMutation, useQuery, useQueryCache } from 'react-query';
import { users } from 'weplayed-typescript-api';

import { getKey as getUserRolesKey } from 'common/hooks/useUserRoles/utils';

import { UseUserInviteReturnType, UseUserInviteType } from './types';

const getKey = (uid: string): string => `user-invite-${uid}`;

/**
 * User role invitation related functions
 *
 * @param uid Invitation ID
 * @param load Flag indicating to load invitation from backend on init
 */
export const useUserInvite: UseUserInviteType = function useUserInvite(uid, load = true) {
  const cache = useQueryCache();

  const invitation: UseUserInviteReturnType['invitation'] = useQuery(
    getKey(uid),
    () => users.role.invite.get({ uid }),
    {
      enabled: uid && load,
      retry: !load,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const reinvite: UseUserInviteReturnType['reinvite'] = useMutation(
    async () => {
      await users.role.invite.reinvite({ uid });

      cache.invalidateQueries(getKey(uid));
    },
  );

  const accept: UseUserInviteReturnType['accept'] = useMutation(
    (data) => users.role.invite.accept({ uid, ...data }),
  );

  const invite: UseUserInviteReturnType['invite'] = useMutation(
    async (req) => {
      await users.role.invite.invite(req);

      cache.invalidateQueries(getUserRolesKey());
    },
  );

  return { accept, invitation, reinvite, invite };
};
