import { AspectRatio } from './types';

/**
 * Real aspect ratio value per name
 */
export const AspectRatioProportions: Record<AspectRatio, number> = {
  [AspectRatio.AR9x16]: 9 / 16,
  [AspectRatio.AR10x16]: 10 / 16,
  [AspectRatio.AR4x3]: 4 / 3,
  [AspectRatio.AR1x1]: 1,
};
