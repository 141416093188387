import { debounce } from 'lodash/fp';
import * as React from 'react';

import { UseSliderType } from './types';

const fix = 20;

export const useSlider: UseSliderType = function useSlider({
  container,
  enabled = true,
}) {
  const [[hasPrevious, hasNext], setHas] = React.useState<[boolean, boolean]>([false, false]);

  const ref = enabled && container;

  const go = React.useCallback((idx: number, smooth = true) => {
    if (!ref) return;

    const element = Array.from(ref.children)[idx] as HTMLElement;

    if (element) {
      const left = element.offsetLeft;

      // this needs to be wrapped into setTimeout because of possible
      // interactions with the children which prevents container from scrolling
      setTimeout(
        () => {
          if (!smooth) {
            ref.style.scrollBehavior = 'auto';
          }
          ref.scrollTo({ left, behavior: smooth ? 'smooth' : 'auto' });
          if (!smooth) {
            ref.style.scrollBehavior = null;
          }
        },
        0,
      );
    }
  }, [ref]);

  const onPrevious = React.useCallback(() => {
    if (ref && hasPrevious) {
      const elements = Array.from(ref.children) as HTMLElement[];
      const { scrollLeft } = ref;
      const idx = elements.findIndex((e) => e.offsetLeft >= scrollLeft - fix) - 1;
      go(idx);
    }
  }, [go, hasPrevious, ref]);

  const onNext = React.useCallback(() => {
    if (ref && hasNext) {
      const elements = Array.from(ref.children) as HTMLElement[];
      const { scrollLeft } = ref;
      const idx = elements.findIndex((e) => e.offsetLeft >= scrollLeft - fix) + 1;
      go(idx);
    }
  }, [go, hasNext, ref]);

  const handleScroll = React.useMemo(() => debounce({ wait: 500 }, () => {
    let hasP = false;
    let hasN = false;
    const elements = Array.from(ref.children || []) as HTMLElement[];

    if (elements?.length) {
      hasP = ref.scrollLeft > 0;
      const { offsetLeft, offsetWidth } = elements[elements.length - 1];
      hasN = ref.scrollLeft + ref.offsetWidth < offsetLeft + offsetWidth;
    }

    if (hasP !== hasPrevious || hasN !== hasNext) {
      setHas([hasP, hasN]);
    }
  }), [hasNext, hasPrevious, ref]);

  React.useEffect(() => {
    if (ref) {
      const observer = new ResizeObserver(handleScroll);
      observer.observe(ref);
      ref.addEventListener('scroll', handleScroll);

      return (): void => {
        observer.unobserve(ref);
        ref.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll, ref]);

  return {
    go,
    hasNext,
    hasPrevious,
    onPrevious,
    onNext,
  };
};
