import { PublishDestination } from 'weplayed-typescript-api';

import * as s from './PublishSidebar.m.less';
import { PublishSidebarEntry } from './PublishSidebarEntry';
import { PublishableType, PublishSidebarControlsProps } from './types';

export const PublishSidebarControls = function PublishSidebarControls<
  T extends PublishableType,
  W extends PublishDestination = PublishDestination,
>({
  entries,
  name,
  ...props
}: PublishSidebarControlsProps<T, W>): JSX.Element {
  if (!entries || entries.length === 0) {
    return null;
  }

  return (
    <>
      <tr>
        <td colSpan={3} className={s.tdHelp}>{name}</td>
      </tr>
      {entries.map((entry) => (
        <PublishSidebarEntry<T, W>
          entry={entry}
          key={entry.pk}
          {...props}
        />
      ))}
    </>
  );
};
