import * as cx from 'classnames';
import * as React from 'react';

import * as s from './Slider.m.less';
import { Props, SliderRefType } from './types';
import { useSlider } from './useSlider';

const SliderRF: React.ForwardRefRenderFunction<SliderRefType, Props> = function Slider({
  children,
  containerClassName,
  itemClassName,
  scrollbar = 'hover',
}, ref) {
  const rootRef = React.useRef<HTMLDivElement>();

  const {
    hasPrevious, hasNext,
    onPrevious, onNext,
    go,
  } = useSlider({
    container: rootRef.current,
  });

  React.useImperativeHandle(ref, () => ({ go }), [go]);

  return (
    <div
      className={cx(
        s.root,
        scrollbar && s.withScroll,
      )}
    >
      <div
        className={cx(
          s.container,
          containerClassName,
          (hasNext && hasPrevious && s.hasBoth)
            || (hasNext && s.hasNext)
            || (hasPrevious && s.hasPrev),
        )}
        ref={rootRef}
      >
        {children && Array.from(children as React.ReactElement[]).map((c, i) => (
          <div
            className={itemClassName}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            {c}
          </div>
        ))}
      </div>
      <button
        className={s.prev}
        disabled={!hasPrevious}
        onClick={onPrevious}
        type="button"
      >
        Previous
      </button>
      <button
        className={s.next}
        disabled={!hasNext}
        onClick={onNext}
        type="button"
      >
        Next
      </button>
    </div>
  );
};

export const Slider = React.forwardRef<
  SliderRefType,
  React.PropsWithChildren<Props>
>(SliderRF);
