/* eslint-disable
  jsx-a11y/click-events-have-key-events,
  jsx-a11y/no-static-element-interactions
*/
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { EntityLinkProps } from '../types';

const stop = (e: React.MouseEvent): void => { e.stopPropagation(); };

/**
 * Renders link or link-like control depending on parameters passed
 */
export const EntityLink: React.FC<EntityLinkProps> = function EntityLink({
  children,
  className,
  url,
}) {
  if (url) {
    return (
      <RouterLink
        className={className}
        draggable={false}
        onClick={stop}
        to={url}
      >
        {children}
      </RouterLink>
    );
  }

  return (
    <div className={className}>
      {children}
    </div>
  );
};
