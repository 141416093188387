import * as React from 'react';
import { BaseEntityWithPk, Game as Game$ } from 'weplayed-typescript-api';

import { defaults } from '../constants';
import { Game } from '../Items/Lists/Game';
import { GamesProps, ListsExtraProps, WithGetData } from '../types';
import { useControls } from '../useControls';
import { getLike } from '../utils';
import { Base } from './Base';
import { ListHeaders } from './constants';

const d = defaults.lists.games;

// eslint-disable-next-line react/function-component-definition
export function Games<
  T extends BaseEntityWithPk = Game$,
  D extends Game$ = Game$,
>({
  getData,
  header = true,
  hideLabel = d.hideLabel,
  link,
  onLike,
  onLink,
  onMenu,
  ...props
}: Omit<GamesProps<T, D>, 'type'> & ListsExtraProps<T> & WithGetData<T, D>): JSX.Element {
 const handlers = useControls({ onLike, onLink, onMenu, getLike });

  const headers = React.useMemo(() => header && [
    ListHeaders.THUMBNAIL,
    ListHeaders.NAME,
    ListHeaders.TEAMS,
    ListHeaders.SCORE,
    ListHeaders.SPORT,
    ListHeaders.DATE,
    handlers.onLink && ListHeaders.LINK,
    handlers.onLike && ListHeaders.LIKE,
    handlers.onMenu && ListHeaders.MENU,
  ], [handlers.onLike, handlers.onLink, handlers.onMenu, header]);

  const renderer = (p): JSX.Element => (
    <Game<T, D>
      {...p}
      {...handlers}
      getData={getData}
      hideLabel={hideLabel}
      link={link}
    />
  );

  return (
    <Base<T>
      {...props}
      headers={headers}
      renderer={renderer}
    />
  );
}
