import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { EntitiesListContext } from '../constants';
import { UseLinkArgs, UseLinkReturnType } from './types';

/**
 * Handy helper for generation of item links
 */
export const useLink = function useLink<T extends BaseEntityWithPk>({
  def,
  item,
  link,
}: UseLinkArgs<T>): UseLinkReturnType {
  const { link: $link } = React.useContext(EntitiesListContext);
  const location = useLocation();

  const to = link || $link;
  const pathname = typeof to === 'function' ? to(item) : to;
  return link === false ? null : (pathname ?? def(item, location));
};
