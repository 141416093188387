import cx from 'classnames';
import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { ItemsWrapper } from '../../ItemsWrapper';
import { ItemsWrapperProps } from '../../types';
import { Empty } from './Empty';
import * as s from './Items.m.less';

// eslint-disable-next-line prefer-arrow-callback
export const Wrapper = React.forwardRef(function Wrapper<T extends BaseEntityWithPk>(
  { className, ...props }: React.ComponentProps<'div'> & Omit<ItemsWrapperProps<'div', T>, 'as' | 'activeClassName' | 'empty'>,
  ref,
): JSX.Element {
  return (
    <ItemsWrapper
      activeClassName={s.dragover}
      as="div"
      className={cx(s.tiles, className)}
      empty={Empty}
      ref={ref}
      {...props}
    />
  );
});
