/**
 * A set of default link generators for certain types of entities
 */
import { Location, LocationDescriptorObject } from 'history';
import { BaseEntityWithPk, Game, What } from 'weplayed-typescript-api';

import { COLLECTION$$, DISCOVER$, GAME$, MOMENT$$ } from 'cms/routes';

export const moments = (
  { pk }: BaseEntityWithPk,
  location: Location,
): LocationDescriptorObject => ({
  ...location,
  hash: MOMENT$$.buildPath({ uid: pk }),
});

export const collections = (
  { pk }: BaseEntityWithPk,
  location: Location,
): LocationDescriptorObject => ({
  ...location,
  hash: COLLECTION$$.buildPath({ uid: pk }),
});

export const games = ({ pk, live_now }: Game): LocationDescriptorObject => ({
  pathname: live_now
    ? GAME$.toPath({ uid: pk })
    : DISCOVER$.toPath({
      section: What.GAMES,
      uid: pk,
    }),
});

export const players = ({ pk }: BaseEntityWithPk): LocationDescriptorObject => ({
  pathname: DISCOVER$.buildPath({
    section: What.PLAYERS,
    uid: pk,
  }),
});
