import { ReactComponent } from 'images/loading-block.svg';
import { range } from 'lodash/fp';
import * as React from 'react';

import { TilesLoadingProps } from './types';

export const TilesLoading: React.FC<TilesLoadingProps> = function TilesLoading({
  className,
  component: Component = ReactComponent,
  count = 4,
  show,
}) {
  const op = React.useMemo<React.CSSProperties[]>(() => {
    const step = 1 / count;
    return range(0, count).map((idx) => ({ opacity: 1 - idx * step }));
  }, [count]);

  if (!show) {
    return null;
  }

  return (
    <>
      {op.map((style, idx) => (
        <Component
          className={className}
          // eslint-disable-next-line react/no-array-index-key
          key={`tile-${idx}`}
          style={style}
        />
      ))}
    </>
  );
};
