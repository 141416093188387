import { BaseEntityWithPk, ID, What } from 'weplayed-typescript-api';

interface DragScrollOptions {
  zone?: number;
  speed?: number;
}

export interface DragData {
  name: string;
  pk: ID;
  what: string | What;
}

const dataAttribute = 'data-pk';

export const enableDragScroll = (options?: DragScrollOptions): () => void => {
  let clientY = 0;
  let scrollTimer: ReturnType<typeof setTimeout>;

  const zone = options?.zone || Math.ceil(visualViewport.height / 5);
  const speed = options?.speed || 50;
  const timeout = 50;

  // returns two values in range 0...1 for cases when drag is near the top
  // edge of window (first value) and the bottom (second)
  // bigger value means cursor is closer to the window edge
  // if document is already reached the edge, return value will always be 0
  const getValues = (): [number, number] => [
    ((visualViewport.pageTop > 0 && clientY >= 0
        && clientY < zone && zone - clientY) || 0) / zone,
    ((Math.ceil(visualViewport.pageTop
      + visualViewport.height) < Math.ceil(document.body.clientHeight)
      && clientY > visualViewport.height - zone
      && clientY - visualViewport.height + zone) || 0) / zone,
  ];

  const scrollBody = (): void => {
    const [top, bottom] = getValues();
    if (top) {
      window.scrollBy({ top: -top * speed, behavior: 'instant' as ScrollBehavior });
      scrollTimer = setTimeout(scrollBody, timeout);
    } else if (bottom) {
      window.scrollBy({ top: bottom * speed, behavior: 'instant' as ScrollBehavior });
      scrollTimer = setTimeout(scrollBody, timeout);
    }
  };

  const dragoverListener = (e: DragEvent): void => {
    if (clientY !== e.clientY) {
      clientY = e.clientY;
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(scrollBody, timeout);
    }
  };

  const dragStartEndListener = (e): void => {
    if (e.type === 'dragstart') {
      document.body.addEventListener('dragover', dragoverListener);
    } else {
      document.body.removeEventListener('dragover', dragoverListener);
      clearTimeout(scrollTimer);
      clientY = -1;
    }
  };

  document.body.addEventListener('dragstart', dragStartEndListener);
  document.body.addEventListener('dragend', dragStartEndListener);

  return (): void => {
    document.body.removeEventListener('dragstart', dragStartEndListener);
    document.body.removeEventListener('dragend', dragStartEndListener);
  };
};

export const getDataPk = (
  e: React.MouseEvent | React.KeyboardEvent | React.TouchEvent | React.ChangeEvent,
): ID | undefined => {
  const { target } = e;

  let element = target as HTMLElement;
  while (element && !element.hasAttribute(dataAttribute)) element = element.parentElement;
  return element?.getAttribute(dataAttribute);
};

export const getDataAttributes = <
  T extends BaseEntityWithPk
>(item: T): React.HTMLAttributes<HTMLElement> => ({
  [dataAttribute]: item.pk,
}) as React.HTMLAttributes<HTMLElement>;

const keyPrefix = 'x-weplayed-item/';

// atob and btoa do not work with unicode, so lets hope that
// = won't be used

export const getDragItemType = (
  key: DragData,
): string => `${keyPrefix}${[key.what, key.name, key.pk].join('=')}`;

export const parseDragItemType = (
  type: string,
): DragData | void => {
  if (type.startsWith(keyPrefix)) {
    try {
      const data = type.slice(keyPrefix.length).split('=');
      return { what: data.shift(), name: data.shift(), pk: data.shift() };
    } catch (e) {
      //
    }
  }
};
