import * as React from 'react';
import { Moment, PublishDestination } from 'weplayed-typescript-api';

import { Entities } from 'cms/components/Entities';

import { PublishSidebar } from './PublishSidebar';
import { PublishSidebarProps } from './types';
import { useData } from './useMomentsData';

export const MomentPublishSidebar: React.FC<
  Omit<PublishSidebarProps<Moment, PublishDestination>, 'useData' | 'renderer'>
> = function MomentPublishSidebar(props) {
  return (
    <PublishSidebar<Moment, PublishDestination>
      {...props}
      useData={useData}
      renderer={Entities.Moments}
    />
  );
};
