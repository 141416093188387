import { castArray } from 'lodash/fp';
import { ID, What } from 'weplayed-typescript-api';

import { Signal } from '../types';

const signal = 'liked' as const;

interface LikedSignal<
  W extends What.MOMENTS | What.COLLECTIONS | What.GAMES | What.PLAYERS,
  T
> extends Signal<typeof signal, W, T> {
  liked: boolean;
  what: W,
}

export const test = <W extends What.MOMENTS | What.COLLECTIONS | What.GAMES | What.PLAYERS>(
  s: Signal<unknown, What, unknown>,
  w: W,
): s is LikedSignal<W, unknown> => s.signal === signal && s.what === w;

export const notify = <W extends What.MOMENTS | What.COLLECTIONS | What.GAMES | What.PLAYERS, T>(
  what: W,
  uid: ID | ID[],
  liked: boolean,
  optimistic?: Promise<T>,
): LikedSignal<W, T> => ({
  optimistic,
  signal,
  liked,
  uid: castArray(uid),
  what,
});
