export const downloadSuccess = 'Email with download with be sent shortly';
export const downloadFail = 'Sorry, you do not have the necessary content rights to get this download';
export const collectionDeleteSuccess = 'Playlist deleted';
export const collectionDeleteFail = 'Cannot remove moment from playlist';

const changes = 'Changes will apply to your site in 24 hours.';
export const pinnedSuccess = `Playlist pinned. ${changes}`;
export const unpinnedSuccess = `Playlist unpinned. ${changes}`;

export const publishedSuccess = `Playlist published. ${changes}`;
export const unpublishedSuccess = `Playlist unpublished. ${changes}`;

export const visibilityFail = 'Playlist visibility set failed';
export const visibleSuccess = 'Playlist is now visible for customer.';
export const hiddenSuccess = 'Playlist blocked from viewing.';
