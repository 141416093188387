import { parse } from 'query-string';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { useLoadOrg } from 'common/hooks/useLoadOrg';
import { initFullStory } from 'common/utils/analytics';
import { CMS_CONTEXT } from 'common/utils/cms';

// import { FacebookLogin } from 'cms/components/FacebookLogin';
import { LoginForm } from 'cms/components/LoginForm';
import { TeamImage } from 'cms/components/TeamImage';

import * as s from './Login.m.less';
import { UrlParams } from './types';

export const Login: React.FC = function Login() {
  const { org_slug } = useParams<UrlParams>();
  const { redirected } = parse(document.location.search);

  const data = useLoadOrg(org_slug || CMS_CONTEXT());
  useHandleQuery(data);

  const hasOrg = Boolean(!data.isLoading && data.data);

  // toggle fullstory on for CMS login page
  React.useEffect(() => {
    if (hasOrg) {
      initFullStory(true);
    }
  }, [hasOrg]);

  if (data.isLoading || data.isError) {
    return null;
  }

  const org = data.data;

  return (
    <div className="container">
      <Helmet>
        <title>
          {org?.name ? `Sign In to the Home of ${org.name}` : 'WePlayed: Sign In'}
        </title>
      </Helmet>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          {redirected !== undefined && (
            <p className="alert alert-warning">
              The product you are trying to sign into has moved to this URL.
              Please Bookmark this page and SIGN IN again. Thank you.
            </p>
          )}
          {org
            ? (
              <div className={s.org}>
                <h1>{`${org.name} Athletics Videos`}</h1>
                <TeamImage team={org} size="x-large" className={s.logo} />
                <LoginForm showRegister={false} />
                <h1>When the game ends, The story begins</h1>
              </div>
            )
            : (
              <>
                <h1>SIGN IN</h1>
                {/* <FacebookLogin />
                <p className={s.orMessage}>
                  Or, if you registered using your email address, please use the form below:
                </p> */}
                <LoginForm showRegister={false} />
              </>
            )}
        </div>
      </div>
    </div>
  );
};
