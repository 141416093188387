import * as React from 'react';
import { BaseEntityWithPk, ID } from 'weplayed-typescript-api';

import { usePrevious } from 'common/hooks/usePrevious';
import {
  DragData, getDataPk, getDragItemType, parseDragItemType,
} from 'common/utils/drag';

import { EntitiesListContext, EntitiesListGroupContext } from './constants';
import {
  BaseEntityListProps, EntitiesListConfig, EntitiesListContextType,
  EntitiesListGroupContextType, EntitiesListType, EntitiesSize,
} from './types';

const empty = [];

/**
 * Context generator for the lists
 */
export const BaseEntityList = function BaseEntityList<
  T extends BaseEntityWithPk = BaseEntityWithPk
>({
  accept,
  checkbox,
  children,
  content,
  disabled,
  hasExpand,
  highlighted,
  items = empty,
  max,
  name,
  onClick,
  onDrag,
  onDrop,
  onExpand,
  size: $size,
  type: $type,
  what,
}: React.PropsWithChildren<BaseEntityListProps<T>>): JSX.Element {
  const {
    selected, onSelect: $handleSelect, config: $config,
  } = React.useContext(EntitiesListGroupContext) as EntitiesListGroupContextType<T>;

  const config = React.useMemo<EntitiesListConfig>(() => ({
    ...$config,
    type: $type || $config.type || EntitiesListType.TILE,
    size: $size || $config.size || EntitiesSize.LARGE,
  }), [$config, $size, $type]);

  // since every element in the list produces own "dragenter" event,
  // we need to track the "height" of the stack to know when the "real"
  // "dragleave" and "dragenter" events happened
  const domLevel = React.useRef(0);

  // Pk of an item which is started drag or is dragging over.
  // In case of items drag has been started from this items list,
  // pk should never become null until drop.
  const [dragData, setDragData] = React.useState<DragData>(null);

  // Current drag position, in case of item from the current list
  // should never be equal to its position
  // Combination of present dragPk and dragPosition == -1 means
  // that drag started from this list but item is not over
  const [dragPosition, setDragPosition] = React.useState<number>(-1);

  // contains flag which describes is current drag source allowed to drop
  // anything on current list or not
  const [dropAllowed, setDropAllowed] = React.useState(false);

  // keeps item with the currently opened more section
  const [expanded, setExpanded] = React.useReducer(
    (s: ID, item: T) => (s === item?.pk ? null : item?.pk),
    null,
  );

  // set expanded to null if current item disappears from list
  React.useEffect(() => {
    if (expanded && !items.find(({ pk }) => pk === expanded)) {
      setExpanded(null);
    }
  }, [expanded, items]);

  React.useEffect(() => {
    if (onExpand) {
      onExpand(items.find(({ pk }) => pk === expanded));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, onExpand]);

  // reset drag, accepts resetPk key for the situations when
  // start list and drag over lists are the same
  const dragReset = React.useCallback((resetData = true) => {
    domLevel.current = 0;
    if (resetData) {
      setDragData(null);
    }
    setDragPosition(-1);
    setDropAllowed(false);
  }, []);

  const dragIndex = dragData ? items.findIndex(({ pk }) => pk === dragData.pk) : -1;

  /**
   * For the cases when onDrag modifies items list so dragend
   * event never fires
   */
  const prevDragIndex = usePrevious(dragIndex);
  React.useEffect(() => {
    if (prevDragIndex !== -1 && dragIndex === -1) {
      dragReset();
    }
  }, [dragIndex, dragReset, prevDragIndex]);

  const findItem = React.useCallback(
    (e: React.MouseEvent | React.KeyboardEvent | React.TouchEvent | React.ChangeEvent): T => {
      const id = getDataPk(e);
      return items.find(({ pk }) => pk === id);
    },
    [items],
  );

  const handleSelect = React.useMemo(
    () => ($handleSelect
      ? (e: React.ChangeEvent | React.MouseEvent): void => {
        const item = findItem(e);
        $handleSelect(item);
      }
      : null),
    [findItem, $handleSelect],
  );

  const handleDragEvents = React.useCallback(
    (e: React.DragEvent): void => {
      if (e.type === 'dragstart') {
        const item = findItem(e);
        const data: DragData = { name, pk: item.pk, what };
        const key = getDragItemType(data);

        e.dataTransfer.setData(key, JSON.stringify(item));
        e.dataTransfer.dropEffect = 'move';

        // timeout here is really needed to let browser create drag item UI
        // before it will be hidden by drag rules
        setTimeout(() => setDragData(data), 0);

        onDrag(item);
      } else if (e.type === 'dragend') {
        e.preventDefault();
        dragReset();
      } else if (e.type === 'dragover') {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';

        if (!dropAllowed) {
          return;
        }

        const id = getDataPk(e);
        let pos = items.findIndex(({ pk }) => pk === id);

        if (pos !== -1) {
          if (pos === dragPosition) {
            pos += 1;
          }

          // in case next item is the one we started drag from,
          // switch to the next position to the active one
          if (pos === dragIndex) {
            pos += 1;
          }

          // if position is less then max allowed amount,
          // leave dragPosition unchanged
          if (pos <= (max ?? items.length) - (dragIndex === -1 ? 1 : 0)) {
            setDragPosition(pos);
          }
        }
      } else if (e.type === 'drop') {
        e.preventDefault();

        if (dropAllowed) {
          // this is really important to run next cycle to let dragend event to be fired
          // otherwise because of source item manipulations source DOM item could be
          // removed so no dragend will be fired
          const $data = JSON.parse(e.dataTransfer.getData(getDragItemType(dragData)));
          const $position = dragPosition - (dragIndex !== -1 && dragPosition > dragIndex ? 1 : 0);
          const $name = dragData.name;

          onDrop($data, $position, $name);
          // setTimeout(() => onDrop($data, $position, $name), 0);
        }

        dragReset();
      } else {
        // the tricky part with domLevel, see the description of the
        // ref for details
        domLevel.current += e.type === 'dragenter' ? 1 : -1;

        if (e.type === 'dragenter' && domLevel.current === 1) {
          e.preventDefault();

          // since dataTransfer.getData is not available until drop
          // we keep all the data to test accept directly in the key
          const data: DragData | void = e.dataTransfer.types
            .map(parseDragItemType)
            .filter(Boolean)
            .shift();

          if (data) {
            setDragData(data);
            setDropAllowed(data.what === what && (!accept || accept.includes(data.name)));
            setDragPosition(items.findIndex(({ pk }) => pk === data.pk));
          }
        } else if (e.type === 'dragleave' && domLevel.current === 0) {
          dragReset(dragIndex === -1);
        }
      }
    },
    [
      accept, dragData, dragIndex, dragPosition, dragReset, dropAllowed,
      findItem, items, max, name, onDrag, onDrop, what,
    ],
  );

  const handleClick = React.useMemo(
    () => (onClick
      ? (e: React.MouseEvent): void => onClick(findItem(e))
      : null
    ),
    [findItem, onClick],
  );

  const handleEnter = React.useMemo(
    () => (onClick
      ? (e: React.KeyboardEvent): void => e.key === 'Enter' && onClick(findItem(e))
      : null
    ),
    [findItem, onClick],
  );

  const handleExpand = React.useMemo(
    () => (content
      ? (e: React.MouseEvent): void => {
        e.stopPropagation();
        setExpanded(findItem(e));
      }
      : null
    ),
    [findItem, content],
);

  const value = React.useMemo<EntitiesListContextType<T>>(() => ({
    checkbox,
    config,
    content,
    disabled,
    dragData,
    dragIndex,
    dragPosition,
    dropAllowed,
    expanded,
    findItem,
    handleClick,
    handleContainerDrag: onDrag && handleDragEvents,
    handleContainerDrop: onDrop && handleDragEvents,
    handleEnter,
    handleExpand,
    handleSelect,
    hasExpand,
    highlighted,
    items,
    max,
    selected,
    setExpanded,
    what,
  }), [
    checkbox, config, content, disabled, dragData, dragIndex, dragPosition, dropAllowed, expanded,
    findItem, handleClick, onDrag, handleDragEvents, onDrop, handleEnter, handleExpand,
    handleSelect, highlighted, items, max, selected, what, hasExpand,
  ]);

  return (
    <EntitiesListContext.Provider value={value}>
      {children}
    </EntitiesListContext.Provider>
  );
};
