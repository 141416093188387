import { Player as Player$, What } from 'weplayed-typescript-api';

import { makeTokens } from 'common/utils/strings';

import { Base } from './Base';
import { Players as List } from './Lists/Players';
import { Players as Tiles } from './Tiles/Players';
import { PlayersProps } from './types';

const renderers = [Tiles, List] as const;

const toTokens = <T extends Player$>({ name }: T): string[] => makeTokens(name);

// eslint-disable-next-line react/function-component-definition
export function Players<
  T extends Player$ = Player$
>(props: PlayersProps<T>): JSX.Element {
  return (
    <Base
      renderers={renderers}
      toTokens={toTokens}
      what={What.PLAYERS}
      {...props}
    />
  );
}
