import * as cx from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import * as s from './SectionNav.m.less';
import { SectionNavItemProps } from './types';

export const SectionNavItem: React.FC<SectionNavItemProps> = function SectionNavItem({
  active,
  children,
  className,
  disabled,
  isActive,
  onClick,
  to,
}) {
  const link = to
    ? (
      <NavLink
        activeClassName={s.active}
        className={cx(active && s.active)}
        isActive={isActive}
        onClick={onClick}
        to={to}
      >
        {children}
      </NavLink>
    )
    : (
      <button
        className={cx(active && s.active)}
        onClick={onClick}
        type="button"
      >
        {children}
      </button>
    );

  return (
    <li className={cx(s.item, className, disabled && s.disabled)}>
      {link}
    </li>
  );
};
