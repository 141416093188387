import { BaseEntityWithPk, Player as Player$ } from 'weplayed-typescript-api';

import { defaults } from '../constants';
import { Player } from '../Items/Tiles/Player';
import { PlayersProps, TilesExtraProps, WithGetData } from '../types';
import { useControls } from '../useControls';
import { getFollow } from '../utils';
import { Base } from './Base';

const d = defaults.tiles.players;

// eslint-disable-next-line react/function-component-definition
export function Players<
  T extends BaseEntityWithPk = Player$,
  D extends Player$ = Player$,
>({
  getData,
  link,
  onLike,
  onLink,
  onMenu,
  sport = d.sport,
  ...props
}: Omit<PlayersProps<T, D>, 'type'> & TilesExtraProps & WithGetData<T, D>): JSX.Element {
  const handlers = useControls({ onLike, onLink, getLike: getFollow, onMenu });

  const renderer = (p): JSX.Element => (
    <Player<T, D>
      {...p}
      {...handlers}
      getData={getData}
      link={link}
      sport={sport}
    />
  );

  return (
    <Base<T>
      {...props}
      renderer={renderer}
    />
  );
}
