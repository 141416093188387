import * as cx from 'classnames';
import { ReactComponent } from 'images/loading-table.svg';
import { range } from 'lodash/fp';
import * as React from 'react';

import * as s from './TableLoading.m.less';
import { TableLoadingProps } from './types';

export const TableLoading: React.FC<TableLoadingProps> = function TableLoading({
  className,
  count = 3,
  component = ReactComponent,
  fit = true,
  padTop,
  show = true,
  ...rest
}) {
  const opacity = React.useMemo(
    () => range(0, count + 1).map((c) => ({ opacity: (count - c) / count })).slice(0, count),
    [count],
  );

  if (!show) {
    return null;
  }

  const Component = component;

  return (
    <div className={cx(s.root, className, padTop && s.pad, fit && s.fit)} {...rest}>
      {range(0, count).map((key) => <Component key={key} style={opacity[key]} />)}
    </div>
  );
};
