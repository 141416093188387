import * as React from 'react';
import { queryCache, useQuery, useQueryCache } from 'react-query';
import { collections, What } from 'weplayed-typescript-api';

import { signalIs } from '../useDataSignal';
import { useQueryCacheUpdate } from '../useQueryCacheUpdate';
import { QueryCacheUpdateEntityConfig } from '../useQueryCacheUpdate/types';
import { perPage } from './constants';
import { UseCollectionReturnType, UseCollectionType } from './types';
import { getKey, getPinsKey } from './utils';

const defaultOptions = {
  refetchOnWindowFocus: false,
};

export const useCollection: UseCollectionType = function useCollection({
  enabled = true,
  infinite = false,
  pins = false,
  summary = false,
  uid,
  wlOrgId,
}) {
  const cache = useQueryCache();
  const [page, setPage] = React.useState(infinite ? 0 : -1);

  const getCollectionKey = React.useMemo(
    () => (p?: number): unknown[] => getKey(
      uid,
      wlOrgId,
      infinite,
      infinite ? (Math.max(0, p ?? page)) : -1,
      summary ? 'no-moments' : 'moments',
    ),
    [uid, wlOrgId, infinite, page, summary],
  );

  const config = React.useMemo(
    () => ([{
      what: What.COLLECTIONS,
      predicate: getCollectionKey(),
      invalidate: (signal, keys) => {
        const { uid: uids } = signal;

        // reload pins on moment publishing status update
        if (enabled
            && signalIs.published(signal, What.COLLECTIONS)
            && pins && uids.includes(uid)
        ) {
          return [[...keys, getPinsKey(uid)], true];
        }

        return [keys, true];
      },
    } as QueryCacheUpdateEntityConfig<What.COLLECTIONS>]),
    [enabled, getCollectionKey, pins, uid],
  );

  useQueryCacheUpdate(config);

  const collection: UseCollectionReturnType['collection'] = useQuery(
    getCollectionKey(),
    async ($_, $uid: string, $wlOrgId: string, $infinite: boolean, p: number) => {
      const params = { uid: $uid, wl_org_id: $wlOrgId };

      if (p === -1 || summary) {
        return summary ? collections.summary(params) : collections.view(params);
      }

      const updateCount = queryCache.getQuery(
        [$_, $uid, $wlOrgId, $infinite, p],
      )?.state?.updateCount;

      const size = (p === 0 || updateCount === 0) ? perPage : perPage * (p + 1);
      const number = (p === 0 || updateCount === 0) ? p + 1 : 1;

      const promises: [
        Promise<collections.moments.get.Response>,
        Promise<collections.summary.Response<never>>?
      ] = [
        collections.moments.get({ uid: $uid }, size, number),
      ];

      if (p === 0) {
        promises.push(collections.summary(params));
      }

      const [m, s] = await Promise.all(promises);
      let $collection;

      if (p === 0) {
        $collection = s;
      } else {
        $collection = { ...cache.getQueryData(getCollectionKey(p - 1)) };
      }

      $collection.moments = (p === 0 || updateCount === 0)
        ? [...$collection.moments, ...m.items]
        : m.items;

      return $collection;
    },
    {
      ...defaultOptions,
      enabled: Boolean(uid && enabled),
      keepPreviousData: infinite,
    },
  );

  const loadMore = React.useCallback(() => {
    setPage(page + 1);
  }, [page]);

  React.useEffect(() => {
    if (infinite && page === -1) {
      setPage(0);
    } else if (!infinite && page !== -1) {
      setPage(-1);
    }
  }, [infinite, page]);

  const hasLoadMore = infinite && collection.data
    && !collection.isFetching
    && collection.data.moment_count > collection.data.moments.length;

  const collectionPins: UseCollectionReturnType['pins'] = useQuery(
    getPinsKey(uid),
    () => collections.pins({ uid }),
    {
      ...defaultOptions,
      enabled: Boolean(uid && pins),
    },
  );

  return {
    collection,
    loadMore: hasLoadMore ? loadMore : undefined,
    pins: collectionPins,
  };
};
