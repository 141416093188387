import pool from '@ricokahler/pool';
import { useMutation, useQueryCache } from 'react-query';
import {
  collections as capi, moments as api, What,
} from 'weplayed-typescript-api';

import { event } from 'common/utils/analytics';
import { pkify } from 'common/utils/helpers';
import { logger } from 'common/utils/logger';

import { getKey as getCollectionKey } from 'cms/hooks/useCollection/utils';

import { about, useDataSignal } from '../useDataSignal';
import { UseMomentsReturnType, UseMomentsType } from './types';

const { COLLECTIONS, MOMENTS } = What;

export const useMoments: UseMomentsType = function useMoments() {
  const cache = useQueryCache();

  const { notify } = useDataSignal();

  const like: UseMomentsReturnType['like'] = useMutation(
    ({ uid, like: l }) => notify(about.liked(MOMENTS, uid, l, api.like({ uid, like: l }))),
    {
      onError: (error, { uid, like: liked }) => {
        notify(about.liked(MOMENTS, uid, !liked));
        logger.error('Moment liking failed', { message: error.message });
      },
    },
  );

  const bulkLike: UseMomentsReturnType['bulkLike'] = useMutation(
    ({ uids, like: l }) => notify(about.liked(MOMENTS, uids, l, api.like({ uid: uids, like: l }))),
    {
      onError: (error, { uids, like: liked }) => {
        notify(about.liked(MOMENTS, uids, !liked));
        logger.error('Bulk Moment liking failed', { message: error.message });
      },
    },
  );

  const bulkRemove: UseMomentsReturnType['bulkRemove'] = useMutation(
    ({ uids }) => api.remove({ uid: uids }),
    {
      onSuccess: (_, { uids }) => notify(about.removed(MOMENTS, uids)),
      onError: (error) => {
        logger.error('Bulk moment delete failed', { message: error.message });
      },
    },
  );

  const remove: UseMomentsReturnType['remove'] = useMutation(
    ({ uid }) => api.remove({ uid }),
    {
      onSuccess: (_e, { uid }) => notify(about.removed(MOMENTS, uid)),
      onError: (error) => {
        logger.error('Moment removing failed', { message: error.message });
      },
    },
  );

  const flag: UseMomentsReturnType['flag'] = useMutation(
    api.flag,
    {
      onError: (error) => {
        logger.error('Moment flag failed', { message: error.message });
      },
    },
  );

  const pin: UseMomentsReturnType['pin'] = useMutation(
    api.pin,
    {
      onSuccess: (pinning, { uid, ...pins }) => {
        notify(about.pinned(MOMENTS, uid, pins, pinning));
      },
      onError: (error) => {
        logger.error('Moment pin failed', { message: error.message });
      },
    },
  );

  const uncollect: UseMomentsReturnType['uncollect'] = useMutation(
    async ({ uid: momentId, collections }) => {
      await pool({
        collection: collections,
        maxConcurrency: 3,
        task: async (uid) => {
          const { moments } = await capi.view({ uid });

          const momentIds = moments
            .map(pkify)
            .filter((pk) => pk !== momentId);

          await capi.moments.update({ uid, moments: momentIds });
        },
      });
    },
    {
      onSuccess: (_r, { collections }) => {
        collections.forEach((uid) => cache.invalidateQueries(getCollectionKey(uid)));
      },
      onError: (error) => {
        logger.error('Moment uncollect failed', { message: error.message });
      },
    },
  );

  const update: UseMomentsReturnType['update'] = useMutation(
    async ({ uid, moment }) => {
      const nmoment = await api.update({ uid, moment });

      nmoment.reviewed = true;
      nmoment.copied_pbp_id = moment.copied_pbp_id;

      return nmoment;
    },
    {
      onSuccess: (nmoment, { uid, moment }) => notify(
        about.updated(MOMENTS, uid, { ...moment, ...nmoment }),
      ),
      onError: (error) => {
        logger.error('Cant update moment', { message: error.message });
      },
    },
  );

  const review: UseMomentsReturnType['review'] = useMutation(
    ({ uid, reviewed }) => notify(
      about.reviewed(MOMENTS, uid, reviewed, api.review({ uid, reviewed })),
    ),
    {
      onError: (error, { uid, reviewed }) => {
        notify(about.reviewed(MOMENTS, uid, !reviewed));
        logger.error('Moment mark as reviewed failed', { message: error.message });
      },
    },
  );

  const block: UseMomentsReturnType['block'] = useMutation(
    api.block,
    {
      onSuccess: (publication, { uid, ...publish }) => notify(
        about.published(MOMENTS, uid, publish, publication),
      ),
    },
  );

  const create: UseMomentsReturnType['create'] = useMutation(
    async ({ moment }) => {
      const nmoment = await api.create({ moment });

      event({
        category: 'Curation',
        action: 'Moment Created',
      });

      return nmoment;
    },
    {
      onSuccess: ({ pk, ...nmoment }, { moment }) => notify(
        about.updated(MOMENTS, pk, { ...moment, ...nmoment, pk }),
      ),
    },
  );

  const collections: UseMomentsReturnType['collections'] = useMutation(
    api.collections,
    {
      onSuccess: ({ collections: c }) => {
        Object.keys(c).forEach(
          (uid) => notify(about.updated(COLLECTIONS, uid, { moment_count: c[uid] })),
        );
      },
    },
  );

  const hide: UseMomentsReturnType['hide'] = useMutation(
    api.hide,
    {
      onSuccess: (hidden, { uid }) => {
        notify(about.hidden(MOMENTS, uid, hidden));
      },
      onError: (error) => {
        logger.error('Moment hide failed', { message: error.message });
      },
    },
  );

  return {
    block,
    bulkLike,
    bulkRemove,
    collections,
    create,
    flag,
    hide,
    like,
    pin,
    remove,
    review,
    uncollect,
    update,
  };
};
