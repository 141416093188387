import * as React from 'react';

import { PP_URL, TC_URL } from 'cms/constants';

import * as s from './Footer.m.less';

export const Footer: React.FC = function Footer() {
  return (
    <div className={s.root}>
      <a
        href={TC_URL}
        target="_blank"
        rel="noreferrer"
      >
        Terms and Conditions
      </a>
      |
      <a
        href={PP_URL}
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
    </div>
  );
};
