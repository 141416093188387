import * as React from 'react';
import { BaseEntityWithPk, Moment as $Moment } from 'weplayed-typescript-api';

import { defaults } from '../constants';
import { Moment } from '../Items/Lists/Moment';
import { ListsExtraProps, MomentsProps, WithGetData } from '../types';
import { useControls } from '../useControls';
import { getBlock, getLike, getPin } from '../utils';
import { Base } from './Base';
import { ListHeaders } from './constants';

const d = defaults.lists.moments;

// eslint-disable-next-line react/function-component-definition
export function Moments<
  T extends BaseEntityWithPk = $Moment,
  D extends $Moment = $Moment
>({
  base = d.base,
  created = d.created,
  creator = d.creator,
  edited = d.edited,
  game = d.game,
  getData,
  header = true,
  link,
  onBlock,
  onLike,
  onLink,
  onMenu,
  onPin,
  position,
  sport = d.sport,
  status,
  ...props
}: Omit<MomentsProps<T, D>, 'type'> & ListsExtraProps<T> & WithGetData<T, D>): JSX.Element {
  const handlers = useControls(
    { onBlock, onLike, onLink, onMenu, onPin, getBlock, getLike, getPin },
  );

  const headers = React.useMemo(() => header && [
    position && ListHeaders.POSITION,
    ListHeaders.THUMBNAIL,
    ListHeaders.NAME,
    game && ListHeaders.GAME,
    sport && ListHeaders.SPORT,
    creator && ListHeaders.CREATOR,
    created && ListHeaders.CREATED,
    edited && ListHeaders.EDITED,
    handlers.onPin && ListHeaders.PIN,
    handlers.onBlock && ListHeaders.BLOCK,
    handlers.onLink && ListHeaders.LINK,
    handlers.onLike && ListHeaders.LIKE,
    handlers.onMenu && ListHeaders.MENU,
  ], [
    created, creator, edited, game, handlers.onBlock,
    handlers.onLike, handlers.onLink, handlers.onMenu,
    handlers.onPin, header, position, sport,
  ]);

  const renderer = (p): JSX.Element => (
    <Moment<T, D>
      {...p}
      {...handlers}
      base={base}
      blocking={status?.publish.includes(p.item.pk)}
      created={created}
      creator={creator}
      edited={edited}
      game={game}
      getData={getData}
      link={link}
      pinning={status?.pin.includes(p.item.pk)}
      position={typeof position === 'number' ? position + p.index : null}
      sport={sport}
    />
  );

  return (
    <Base<T>
      {...props}
      headers={headers}
      renderer={renderer}
    />
  );
}
