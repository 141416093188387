import { castArray } from 'lodash/fp';
import { ID, PinConfig, PinEntity, What } from 'weplayed-typescript-api';

import { Signal } from '../types';

const signal = 'pinned' as const;
export interface PinnedSignal<
  W extends What.MOMENTS | What.COLLECTIONS,
  T
> extends Signal<typeof signal, W, T> {
  pin: Omit<PinEntity, 'uid'>,
  pinning: PinConfig;
  what: W;
}

export const test = <W extends What.MOMENTS | What.COLLECTIONS>(
  s: Signal<unknown, What, unknown>,
  w: W,
): s is PinnedSignal<W, unknown> => s.signal === signal && s.what === w;

export const notify = <W extends What.MOMENTS | What.COLLECTIONS, T>(
  what: W,
  uid: ID | ID[],
  pin: Omit<PinEntity, 'uid'>,
  pinning: PinnedSignal<W, T>['pinning'],
): PinnedSignal<W, T> => ({
  pin,
  pinning,
  signal,
  uid: castArray(uid),
  what,
});
