import { useMutation } from 'react-query';
import { collections as api, What } from 'weplayed-typescript-api';

import { event } from 'common/utils/analytics';
import { pkify } from 'common/utils/helpers';
import { logger } from 'common/utils/logger';

import { about, useDataSignal } from '../useDataSignal';
import {
  CollectionUpdateResponse, UseCollectionsReturnType, UseCollectionsType,
} from './types';

const { COLLECTIONS } = What;

export const useCollections: UseCollectionsType = function useCollections() {
  const { notify } = useDataSignal();

  const create: UseCollectionsReturnType['create'] = useMutation(
    async ({ muid, moments, ...collection }) => {
      const pk = await api.create(collection);

      if (muid || moments) {
        await api.moments.update({
          uid: pk,
          moments: muid ?? moments.map(pkify),
        });
      }

      return pk;
    },
  );

  const block: UseCollectionsReturnType['block'] = useMutation(
    api.block,
    {
      onSuccess: (publication, { uid, ...publish }) => notify(
        about.published(COLLECTIONS, uid, publish, publication),
      ),
      onError: (error) => {
        logger.error('Collection block failed', { message: error.message });
      },
    },
  );

  const update: UseCollectionsReturnType['update'] = useMutation(
    async ({ muid, moments, ...payload }): Promise<CollectionUpdateResponse> => {
      const data = await api.update(payload);

      if (muid || moments) {
        const moment_count = await api.moments.update({
          uid: payload.uid,
          moments: muid ?? moments.map(pkify),
        });
        return { ...data, moment_count };
      }

      return data;
    },
    {
      onSuccess: (data, { uid }) => notify(
        about.updated(COLLECTIONS, uid, data),
      ),
      onError: (error) => {
        logger.error('Collection update failed', { message: error.message });
      },
    },
  );

  const clone: UseCollectionsReturnType['clone'] = useMutation(
    async ({ uid, muid, moments, ...rest }) => {
      const ids = muid
            || moments?.map(pkify)
            || (await api.view({ uid })).moments.map(pkify);

      const pk = await api.create(rest);

      if (ids.length !== 0) {
        await api.moments.update({ uid: pk, moments: ids });
      }

      return pk;
    },
    {
      onError: (error) => {
        logger.error('Collection clone failed', { message: error.message });
      },
    },
  );

  const like: UseCollectionsReturnType['like'] = useMutation(
    ({ uid, like: on }) => notify(
      about.liked(COLLECTIONS, uid, on, api.like({ uid, like: on })),
    ),
    {
      onSuccess: (_r, { uid, like: on }) => {
        event({
          category: 'Engagement',
          action: on ? 'Collection Liked' : 'Collection Unliked',
          label: uid,
        });
      },
      onError: (error, { uid, like: on }) => {
        notify(about.liked(COLLECTIONS, uid, !on));
        logger.error('Collection like failed', { message: error.message });
      },
    },
  );

  const bulkLike: UseCollectionsReturnType['bulkLike'] = useMutation(
    ({ uids, like: on }) => notify(
      about.liked(COLLECTIONS, uids, on, api.like({ uid: uids, like: on })),
    ),
    {
      onSuccess: (_r, { uids, like: on }) => {
        event({
          category: 'Engagement',
          action: on ? 'Bulk collections Liked' : 'Bulk collections Unliked',
          label: uids.join(','),
        });
      },
      onError: (error, { uids, like: on }) => {
        notify(about.liked(COLLECTIONS, uids, !on));
        logger.error('Bulk Collections like failed', { message: error.message });
      },
    },
  );

  const bulkRemove: UseCollectionsReturnType['bulkRemove'] = useMutation(
    ({ uids }) => api.remove({ uid: uids }),
    {
      onSuccess: (_r, { uids }) => notify(about.removed(COLLECTIONS, uids)),
    },
  );

  const remove: UseCollectionsReturnType['remove'] = useMutation(
    ({ uid }) => api.remove({ uid }),
    {
      onSuccess: (_r, { uid }) => notify(about.removed(COLLECTIONS, uid)),
    },
  );

  const pin: UseCollectionsReturnType['pin'] = useMutation(
    api.pin,
    {
      onSuccess: (pinning, { uid, ...pins }) => {
        notify(about.pinned(COLLECTIONS, uid, pins, pinning));
      },
      onError: (error) => {
        logger.error('Moment pin failed', { message: error.message });
      },
    },
  );

  const hide: UseCollectionsReturnType['hide'] = useMutation(
    api.hide,
    {
      onSuccess: (hidden, { uid }) => {
        notify(about.hidden(COLLECTIONS, uid, hidden));
      },
      onError: (error) => {
        logger.error('Moment visibility set failed', { message: error.message });
      },
    },
  );

  return {
    block,
    create,
    bulkLike,
    bulkRemove,
    clone,
    hide,
    like,
    pin,
    remove,
    update,
  };
};
