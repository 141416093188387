import { ReactComponent as Hide } from '@mdi/svg/svg/cancel.svg';
import { ReactComponent as MenuIcon } from '@mdi/svg/svg/dots-horizontal.svg';
import * as React from 'react';

import { ConfirmationModal } from '../ConfirmationModal';
import * as s from './StaffEditWarningModal.m.less';
import { StaffEditWarningModalProps } from './types';

export const StaffEditWarningModal: React.FC<
  StaffEditWarningModalProps
> = function StaffEditWarningModal(props) {
  return (
    <ConfirmationModal
      cancelText="No, cancel this action"
      confirmText="Yes, continue"
      showRemember
      title="Edit Client Content"
      {...props}
    >
      <p>
        You are editing a base moment. Any changes made to a base moment could impact multiple
        clients. Please follow one of these recommendations:
      </p>
      <ul>
        <li>
          To edit content for a specific client, please edit the CLIENT moment version
          (client moment does not have a label)
        </li>
        <li>
          To prevent clients from viewing this content in their CMS and widget(s),
          you can use the
          <span className={s.button}>
            <Hide />
            <span>Block</span>
          </span>
          control found in the
          <span className={s.button}>
            <MenuIcon />
          </span>
          .
        </li>
        <li>
          To create your own version of this content that isn’t visible to the client,
          please first clone the selected content before blocking the clone.
          You can find your content in Library
        </li>
      </ul>
    </ConfirmationModal>
  );
};
