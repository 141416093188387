import * as React from 'react';

import { CollectionActionsContextType, CollectionActionStatus } from './types';

export const defaultState: CollectionActionStatus = {
  clone: [],
  create: [],
  hide: [],
  pin: [],
  publish: [],
  remove: [],
  update: [],
};

export const CollectionActionsContext = React.createContext<CollectionActionsContextType>(
  {} as CollectionActionsContextType,
);
