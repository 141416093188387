import * as React from 'react';

import { useApplication } from 'common/hooks/useApplication';
import { BroadcastType } from 'common/hooks/useCreateAppContext/types';

import { usePlayers } from 'cms/hooks/usePlayers';

import { Action, ActionType, UsePlayerActionsType } from './types';

export const usePlayerActions: UsePlayerActionsType = function useGameActions<
T extends ActionType
>({
  onAccept, onStart, onUnauthorized, onDone, user,
}) {
  const {
    like: [like],
    bulkLike: [bulkLike],
  } = usePlayers();

  const { broadcast } = useApplication();

  const run: (act: Action<ActionType>) => void = React.useCallback(
    async (act) => {
      const { uid, uids, action: $action } = act;

      const message = (type: BroadcastType, msg: string): void => {
        broadcast(type, (
          (type === 'success' && act.success)
          || (type === 'error' && act.fail)
        ) || msg);
      };

      const report = (): void => {
       if (onDone) {
          onDone(act);
        }
      };

      if ($action === 'like') {
        like({ uid, like: act.like })
        .catch(() => {
          message('error', 'Playlist like failed');
        });
      } else if ($action === 'bulk_like') {
        report();
        bulkLike({ uids, like: act.like })
        .catch(() => {
          message('error', 'Cannot bulk like playlists');
        });
      }

      if (onAccept) {
        await onAccept(act);
      }
    },
    [
      onAccept, onDone, broadcast, like, bulkLike,
    ],
  );

  const invoke: (act: Action<T>, notify?: boolean) => void = React.useCallback(
    (act, notify) => {
      if (!user) {
        if (onUnauthorized) {
          onUnauthorized(act);
        }
        return;
      }

      if (onStart && notify !== false && onStart(act) === false) {
        return;
      }

      if (notify) {
        return;
      }

      run(act);
    },
    [onUnauthorized, run, user, onStart],
  );

  return React.useMemo(() => ({ invoke }), [invoke]);
};
