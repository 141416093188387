import * as React from 'react';
import { useQuery, useQueryCache } from 'react-query';
import { moments as api, What } from 'weplayed-typescript-api';

import { signalIs, useDataSignal } from '../useDataSignal';
import {
  UseMomentPublicationReturnType, UseMomentPublicationType,
} from './types';

// Do NOT mix this update publication query
export const getKey = (
  uid?: string,
): unknown[] => ['moment-read-publication', uid].filter(Boolean);

export const useMomentPublication: UseMomentPublicationType = function useMomentPublication({
  uid,
}) {
  const cache = useQueryCache();
  const { subscribe } = useDataSignal();

  const key = getKey(uid);

  React.useEffect(() => subscribe((signal) => {
    if (signalIs.published(signal, What.MOMENTS) && signal.uid.includes(uid)) {
      cache.invalidateQueries(getKey(uid));
    }
  }), [cache, subscribe, uid]);

  const publication: UseMomentPublicationReturnType['publication'] = useQuery(
    key,
    () => api.publication({ uid }),
    {
      enabled: Boolean(uid),
      refetchOnWindowFocus: false,
    },
  );

  return { publication };
};
