import cx from 'classnames';
import { identity } from 'lodash/fp';
import { BaseEntityWithPk, Game as Game$ } from 'weplayed-typescript-api';

import { GameStatusLabel } from 'common/components/GameStatusLabel';
import { formatDate } from 'common/utils/dates';

import { FadeInImage } from 'cms/components/FadeInImage';
import { GameLabel } from 'cms/components/GameLabel';
import { SportLabel } from 'cms/components/SportLabel';

import { defaults } from '../../constants';
import { GameItemProps } from '../../types';
import { Like, Link, Menu } from '../Controls';
import { EntityLink } from '../EntityLink';
import { games as def } from '../links';
import { useLink } from '../useLink';
import { Base } from './Base';
import * as s from './Items.m.less';

const d = defaults.lists.games;

export const Game = function Game<
  T extends BaseEntityWithPk = Game$,
  D extends Game$ = Game$,
>({
  getData = identity,
  item: $item,
  link,
  hideLabel = d.hideLabel, // @TODO: needs support
  onLike,
  onLink,
  onMenu,
  ...props
}: GameItemProps<T, D>): JSX.Element {
  const item = getData($item);

  const url = useLink({ item, link, def });

  const {
    name, sport, date_played, liked_by_user,
    team1_score, team2_score, thumbnail,
  } = item;

  const thumb = (
    <>
      {thumbnail && <FadeInImage src={thumbnail} alt={name} />}
      {hideLabel !== true && (
        <GameStatusLabel
          className={s.live}
          game={item}
          text={false}
        />
      )}
    </>
  );

  return (
    <Base<T>
      item={$item}
      controls={[
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link key="link" onClick={onLink} />,
        <Like
          className={cx(liked_by_user && s.visible)}
          key="like"
          like={liked_by_user}
          onClick={onLike}
        />,
        <Menu key="menu" onClick={onMenu} className={s.visible} />,
      ]}
      {...props}
    >
      <td>
        <EntityLink
          className={cx(s.thumbnail, sport?.slug)}
          url={url}
        >
          {thumb}
        </EntityLink>
      </td>
      <td><span className={s.name} title={name}>{name}</span></td>
      <td><GameLabel game={item} noDate className={s.game} /></td>
      <td>{`${team1_score} - ${team2_score}`}</td>
      <td><SportLabel sport={sport} /></td>
      <td>{formatDate(date_played)}</td>
    </Base>
  );
};
