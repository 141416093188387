import * as cx from 'classnames';
import * as React from 'react';

import * as s from './HTMLSwitch.m.less';
import { HTMLSwitchProps } from './types';

export const HTMLSwitch: React.FC<HTMLSwitchProps> = function HTMLSwitch({
  className,
  disabled,
  loading,
  onChange,
  onClick,
  checked,
  label,
  value,
}) {
  const handleAction = React.useCallback((e: React.MouseEvent | React.ChangeEvent) => {
    e.stopPropagation();

    if (onClick) {
      onClick(e as React.MouseEvent);
    }

    if (onChange) {
      onChange(!checked, e as React.ChangeEvent<HTMLInputElement>);
    }
  }, [checked, onChange, onClick]);

  return (
    <label className={cx(s.root, className)}>
      <input
        checked={checked || false}
        className={s.input}
        disabled={disabled || loading}
        onChange={handleAction}
        onClick={handleAction}
        type="checkbox"
        value={value}
      />
      <div className={s.toggle}>
        <span className={cx(loading && s.loading)} />
      </div>
      {label && <span className={s.label}>{label}</span>}
    </label>
  );
};
