import { CollectionTheme } from 'weplayed-typescript-api';

export const THEME: Record<CollectionTheme, string> = {
  [CollectionTheme.BREAKDOWN]: 'Breakdown is a collection of the top moments of a play-type '
    + 'or "tag" from a season, listed reverse-chronologically. The creation of a Breakdown '
    + 'begins after a team has played three games, and checks week to week to see if there are '
    + 'any new moments to add.',
  [CollectionTheme.GAME_DIGEST]: 'Game Digest takes the top moments from both teams and lists them '
    + 'chronologically to tell the full story of the game. Created as soon as the game ends and '
    + 'moments are produced.',
  [CollectionTheme.IN_THE_ZONE]: 'In the Zone takes the top moments of an athlete from a game and '
    + 'lists them chronologically. Created as soon as the game ends and moments are produced.',
  [CollectionTheme.PIVOTAL_MOMENTS]: 'Pivotal Moments takes the top moments from your team in a '
    + 'game and lists them chronologically. Distributed as soon as the game ends and moments are '
    + 'produced.',
  [CollectionTheme.ULTIMATE_REEL]: 'Ultimate Reel is a collection of the top moments of an '
    + 'athlete\'s career that showcases multiple games and seasons. Listed reverse-chronologically '
    + 'with moments from the most recent game showing up first. Ultimate Reel checks week to week '
    + 'to see if there are any new moments to add.',
};
