import { ID } from 'weplayed-typescript-api';

import { GameBasic } from 'cms/types';

export enum Status {
  IDLE = 'IDLE',
  DIGEST = 'DIGEST',
  UPLOAD = 'UPLOAD',
  COMMIT = 'COMMIT',
}

export interface Progress {
  digest: string;
  error?: string[];
  file: File;
  name: string;
  progress: number;
  size: number;
  status: Status;
  success: boolean;
}

export type State<T extends GameBasic> = Record<ID, Progress & {
  game: T;
}>;

export interface Stats {
  digest: number;
  error: number;
  upload: number;
}

export interface UseUploadsReturnType<T extends GameBasic> {
  abort(game: T): void;
  active: number;
  append(game: T, file: File): void;
  clear(game: T): void;
  resume(game: T): void;
  state: State<T>;
  stats: Stats;
}

export enum Action {
  COMMIT_END,
  COMMIT_START,
  CLEAR,
  END_DIGEST,
  END_UPLOAD,
  PROGRESS,
  RESET,
  START_DIGEST,
  START_UPLOAD,
}

export type ActionType<T extends GameBasic> = {
  game: T;
} & ({
  action: Action.RESET
} | {
  action: Action.START_DIGEST;
  file: File;
} | {
  action: Action.END_DIGEST;
  digest: string;
  error?: string;
} | {
  action: Action.START_UPLOAD;
} | {
  action: Action.END_UPLOAD;
  error?: string;
} | {
  action: Action.COMMIT_START;
} | {
  action: Action.COMMIT_END;
  error?: string;
  success: boolean;
} | {
  action: Action.PROGRESS;
  left: number;
} | {
  action: Action.CLEAR;
});
