import { DirectShareDestination, What } from 'weplayed-typescript-api';

import { DirectShareLimitation, Messages } from './types';

export const messages: Record<What, Messages> = {
  [What.MOMENTS]: {
    pluralItems: '{0} Moments',
  },
  [What.COLLECTIONS]: {
    pluralItems: '{0} Collections',
  },
  [What.ATHLETES]: {
    pluralItems: '{0} Athletes',
  },
  [What.PLAYERS]: {
    pluralItems: '{0} Players',
  },
  [What.GAMES]: {
    pluralItems: '{0} Games',
  },
  [What.CHANNELS]: {
    pluralItems: '{0} Channels',
  },
  [What.TEAMS]: {
    pluralItems: '{0} Teams',
  },
  [What.SCHOOLS]: {
    pluralItems: '{0} Schools',
  },
  [What.SPORTS]: {
    pluralItems: '{0} Sports',
  },
  [What.ORGS]: {
    pluralItems: '{0} Conferences',
  },
};

export const EmbedSuccessMessage = 'Embed code was copied to clipboard';
export const EmailSuccessMessage = 'Email was sent';

export const Limitations: Record<DirectShareDestination, DirectShareLimitation> = {
  [DirectShareDestination.FACEBOOK]: {
    textLength: 63206,
    videoCount: 4,
    videoLength: 240 * 60,
  },
  [DirectShareDestination.INSTAGRAM]: {
    textLength: 2200,
    videoCount: 10,
    videoLength: 60 * 60,
  },
  [DirectShareDestination.TIKTOK]: {
    textLength: 2200,
    videoCount: 1,
    videoLength: 30 * 60,
  },
  [DirectShareDestination.TWITTER]: {
    textLength: 280,
    videoCount: 4,
    videoLength: 140,
  },
  [DirectShareDestination.YOUTUBE]: {
    textLength: 5000,
    videoCount: 1,
    videoLength: 15 * 60,
  },
};
