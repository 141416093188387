import cx from 'classnames';
import { identity } from 'lodash/fp';
import { BaseEntityWithPk, Game as Game$ } from 'weplayed-typescript-api';

import { GameStatusLabel } from 'common/components/GameStatusLabel';
import { formatDate } from 'common/utils/dates';
import { momentsCount } from 'common/utils/moments';

import { FadeInImage } from 'cms/components/FadeInImage';
import { SportLabel } from 'cms/components/SportLabel';
import { TeamImage } from 'cms/components/TeamImage';

import { defaults } from '../../constants';
import { GameItemProps } from '../../types';
import { Like, Link, Menu } from '../Controls';
import { EntityLink } from '../EntityLink';
import { games as def } from '../links';
import { useLink } from '../useLink';
import { Base } from './Base';
import * as s from './Items.m.less';

const d = defaults.tiles.games;

export const Game = function Game<
  T extends BaseEntityWithPk = Game$,
  D extends Game$ = Game$
>({
  className,
  getData = identity,
  item: $item,
  link,
  hideLabel = d.hideLabel,
  onLike,
  onLink,
  onMenu,
  ...props
}: GameItemProps<T, D>): JSX.Element {
  const item = getData($item);
  const url = useLink({ item, link, def });

  const {
    sport, thumbnail, name, date_played, live_now, moment_count,
    team1, team1_score, team2, team2_score, liked_by_user,
  } = item;

  const thumb = (
    <>
      {thumbnail && (
        <FadeInImage
          alt={name}
          className={s.thumbnail}
          src={thumbnail}
        />
      )}
      <div className={s.teams}>
        <div className={s.team}>
          <TeamImage team={team1} size="x-large" />
          {!live_now && <span>{team1_score}</span>}
        </div>
        <span>at</span>
        <div className={s.team}>
          <TeamImage team={team2} size="x-large" />
          {!live_now && <span>{team2_score}</span>}
        </div>
      </div>
      {hideLabel !== true && (
        <GameStatusLabel
          className={s.live}
          collapse={false}
          game={item}
        />
      )}
    </>
  );

  return (
    <Base<T>
      {...props}
      className={cx(s.game, className)}
      controls={[
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link key="link" onClick={onLink} />,
        <Like
          className={cx(liked_by_user && s.visible)}
          key="like"
          like={liked_by_user}
          onClick={onLike}
        />,
        <Menu key="menu" onClick={onMenu} className={s.visible} />,
      ]}
      item={$item}
    >
      <EntityLink
        className={cx(s.rtile, sport?.slug)}
        url={url}
      >
        {thumb}
      </EntityLink>
      <div className={s.extra}>
        <div className={s.info}>
          <SportLabel sport={sport} />
          <span className={s.date}>
            {formatDate(date_played)}
            {moment_count ? `・${momentsCount(moment_count)}` : ''}
          </span>
        </div>
      </div>
    </Base>
  );
};
