import { Status } from './types';

export const BUFFER_SIZE = 1024 * 1024;
export const THREADS = 5;
export const DONE_TIMEOUT = 5000;

export const defaultState = {
  digest: '',
  error: null,
  file: undefined,
  name: '',
  progress: 0,
  size: 0,
  status: Status.IDLE,
  success: false,
};
