import { CreatorFilter, SortOrder } from './types';

export const SortOrderValues: {[K in SortOrder]: string} = {
  [SortOrder.ALPHABETICALLY]: 'Alphabetical',
  [SortOrder.MODIFIED]: 'Recently modified',
};

export const CreatorFilterValues: {[K in CreatorFilter]: string} = {
  [CreatorFilter.ALL]: 'All Playlists',
  [CreatorFilter.OWN]: 'Only My Playlists',
};
