import { uniq, without } from 'lodash/fp';
import * as React from 'react';
import {
  BlockEntity, ID, PublishDestination, What,
} from 'weplayed-typescript-api';

import { useApplication } from 'common/hooks/useApplication';
import { BroadcastType } from 'common/hooks/useCreateAppContext/types';
import { event } from 'common/utils/analytics';

import { ConfirmationModal } from 'cms/components/ConfirmationModal';
import { EntityInfo } from 'cms/components/EntityInfo';
import { CollectionPublishSidebar } from 'cms/components/PublishSidebar';
import { useCollections } from 'cms/hooks/useCollections';
import { CollectionPinPayload } from 'cms/hooks/useCollections/types';
import { useExports } from 'cms/hooks/useExports';

import { defaultState } from './constants';
import * as messages from './messages';
import {
  Action, ActionType, BulkAction, CollectionActionStatus, ResultType,
  SingleAction, UseCollectionActionsType,
} from './types';
import { isAction } from './utils';

const config = {
  throwOnError: true,
};

export const useCollectionActions: UseCollectionActionsType = function useCollectionActions<
  T extends ActionType
>({
  onAccept,
  onDecline,
  onDone,
  onStart,
  onUnauthorized,
  sendDownloadEmail,
  user,
}) {
  // statusRef is a source of states between updates,
  // status/setStatus is used for notifying context only
  const statusRef = React.useRef<CollectionActionStatus>(defaultState);
  const [status, setStatus] = React.useState<CollectionActionStatus>(statusRef.current);
  const { download } = useExports();

  const [action, setAction] = React.useState<Action<T>>(null);
  const {
    block: [block],
    bulkLike: [bulkLike],
    bulkRemove: [bulkRemove],
    clone: [clone],
    create: [create],
    like: [like],
    pin: [pin],
    remove: [remove],
    update: [update],
    hide: [hide],
  } = useCollections();

  const { broadcast } = useApplication();

  const updateStatus = React.useCallback(
    (uids: ID[], where: keyof CollectionActionStatus, raise: boolean) => {
      statusRef.current = {
        ...statusRef.current,
        [where]: raise
          ? uniq(statusRef.current[where].concat(uids))
          : without(uids, statusRef.current[where]),
      };

      setStatus(statusRef.current);
    },
    [],
  );

  const engage = React.useCallback((
    uids: ID[],
  ) => (
    where: keyof CollectionActionStatus,
  ): (
  ) => void => {
    updateStatus(uids, where, true);

    return (): void => updateStatus(uids, where, false);
  }, [updateStatus]);

  const run: (act: Action<ActionType>) => void = React.useCallback(
    async (act) => {
      const { uid } = act as SingleAction;
      const { uids = [] } = act as BulkAction;

      const all = [
        ...((act as BulkAction).uids || []),
        (act as SingleAction).uid,
      ].filter(Boolean);

      // required for clone/create operations
      const up = engage(all.length ? all : [null]);

      const message = (type: BroadcastType, msg: string): void => {
        broadcast(type, (
          (type === 'success' && act.success)
          || (type === 'error' && act.fail)
        ) || msg);
      };

      const report = (result?: ResultType<T>): void => {
       if (onDone) {
          onDone(act, result);
        }
      };

      if (isAction(act, 'download')) {
        download(What.COLLECTIONS, [uid], sendDownloadEmail);
      } else if (isAction(act, 'bulk_download')) {
        download(What.COLLECTIONS, uids, sendDownloadEmail);
      } else if (isAction(act, 'like')) {
        report();
        like({ uid, like: act.like })
        .catch(() => {
          message('error', 'Playlist like failed');
        });
      } else if (isAction(act, 'remove')) {
        if (!statusRef.current.remove.includes(uid)) {
          const reset = up('remove');
          remove({ uid }, config).then(() => {
            report();
            message('success', messages.collectionDeleteSuccess);
          }).catch(() => {
            message('success', messages.collectionDeleteFail);
          }).finally(reset);
        }
      } else if (isAction(act, 'share') && act.target) {
        switch (act.target) {
          case 'native': {
            event({
              category: 'Sharing',
              action: 'Playlist Mobile-Shared',
              label: uid,
            });
            break;
          }

          case 'facebook': {
            event({
              category: 'Sharing',
              action: 'Playlist Shared on FB',
              label: uid,
            });
            break;
          }

          case 'twitter': {
            event({
              category: 'Sharing',
              action: 'Playlist Shared on TW',
              label: uid,
            });
            break;
          }

          case 'copy': {
            event({
              category: 'Sharing',
              action: 'Playlist URL Copied',
              label: uid,
            });
            break;
          }

          default:
            //
        }
      } else if (isAction(act, 'create') && act.collection) {
        if (!statusRef.current.create.includes(uid)) {
          const reset = up('create');
          create(act.collection, config)
          .then((nuid: ResultType<T>) => {
            message('success', 'Playlist created successfully');
            setAction(null);
            report(nuid);
          }).catch(() => {
            message('error', 'Cannot create playlist');
          }).finally(reset);
        }
      } else if (isAction(act, 'clone') && act.collection) {
        if (!statusRef.current.clone.includes(uid)) {
          const reset = up('clone');
          clone({ uid, ...act.collection }, config)
          .then((nuid: ResultType<T>) => {
            message('success', 'Playlist cloned successfully');
            setAction(null);
            report(nuid);
          }).catch(() => {
            message('error', 'Cannot clone playlist');
          }).finally(reset);
        }
      } else if (isAction(act, 'update') && act.collection) {
        if (!statusRef.current.update.includes(uid)) {
          const reset = up('update');
          update({ uid, ...act.collection }, config)
          .then(() => {
            message('success', 'Playlist updated successfully');
            setAction(null);
            report();
          }).catch(() => {
            message('error', 'Cannot update playlist');
          }).finally(reset);
        }
      } else if (isAction(act, 'bulk_like')) {
        report();
        bulkLike({ uids, like: act.like })
        .catch(() => {
          message('error', 'Cannot bulk like playlists');
        });
      } else if (isAction(act, 'bulk_delete')) {
        const reset = up('remove');
        bulkRemove({ uids }, config)
        .then(() => {
          message('success', 'Playlists removed successfully');
          report();
        })
        .catch(() => {
          message('error', 'Cannot bulk remove playlists');
        })
        .finally(reset);
      } else if (isAction(act, 'block')) {
        if (!statusRef.current.publish.includes(uid)) {
          const reset = up('publish');

          block({ uid, ...act.block }, config).then(() => {
            report();
            message('success', act.block.blocked
              ? messages.unpublishedSuccess
              : messages.publishedSuccess);
          })
          .finally(reset);
        }
      } else if (isAction(act, 'pin')) {
        if (!statusRef.current.pin.includes(uid)) {
          const reset = up('pin');
          pin({ ...act.pin, uid } as CollectionPinPayload, config)
          .then(() => {
            report();
            message('success', act.pin.pin ? messages.pinnedSuccess : messages.unpinnedSuccess);
          })
          .finally(reset);
        }
      } else if (isAction(act, 'hide') && !statusRef.current.hide.includes(uid)) {
        const reset = up('pin');
        hide(act, config)
        .then((h) => {
          report();
          message('success', h ? messages.hiddenSuccess : messages.visibleSuccess);
        })
        .catch(() => {
          message('error', messages.visibilityFail);
        }).finally(reset);
      }

      if (onAccept) {
        await onAccept(act, up);
      }
    },
    [
      engage, onAccept, onDone, broadcast, like, remove, clone, update,
      bulkLike, bulkRemove, pin, create, block, hide, download, sendDownloadEmail,
    ],
  );

  const invoke: (act: Action<T>, notify?: boolean) => void = React.useCallback(
    (act, notify) => {
      if (!user) {
        if (onUnauthorized) {
          onUnauthorized(act);
        }
        return;
      }

      if (onStart && notify !== false && onStart(act) === false) {
        return;
      }

      if (notify) {
        return;
      }

      // these actions require confirmation so store params
      // and set action to raise modal
      if (isAction(act, 'remove')
        || isAction(act, 'bulk_delete')
        || isAction(act, 'info')
        || isAction(act, 'pin')
        || (isAction(act, 'publish') && !act.publish)
      ) {
        setAction(act);
      } else {
        run(act);
      }
    },
    [onUnauthorized, run, user, onStart],
  );

  const handleConfirm = React.useCallback(
    (): void => {
      setAction(null);
      run(action);
    },
    [run, action],
  );

  const handleDecline = React.useCallback(
    (): void => {
      setAction(null);

      if (onDecline) {
        onDecline(action);
      }
    },
    [onDecline, action],
  );

  const publishAction = isAction(action, 'publish') ? action : null;

  const handleBlock = React.useCallback(
    ($block: BlockEntity<PublishDestination>) => {
      const $action = {
        action: 'block',
        uid: (action as SingleAction).uid,
        block: $block,
      } as Action<T>;

      run($action);
    },
    [run, action],
  );

  const handlePublishLoading = React.useCallback((isLoading) => {
    if (isAction(action, 'pin') || isAction(action, 'publish')) {
      updateStatus([action.uid], action.action, isLoading);
    }
  }, [action, updateStatus]);

  const modals = React.useMemo(() => (
    <>
      {isAction(action, 'info') && (
        <EntityInfo
          onClose={handleDecline}
          type={What.COLLECTIONS}
          uid={action.uid}
        />
      )}
      {isAction(action, 'remove') && (
        <ConfirmationModal
          onConfirm={handleConfirm}
          onCancel={handleDecline}
          title="Delete Collection"
          message="Are you sure you want to completely delete this collection?"
        />
      )}
      {isAction(action, 'bulk_delete') && (
        <ConfirmationModal
          onConfirm={handleConfirm}
          onCancel={handleDecline}
          title="Delete Collections"
          message={
            `Are you sure you want to completely delete ${
              action.uids.length
            } collection${
              action.uids.length !== 1 ? 's' : ''
            }?`
          }
        />
      )}
      {user?.can_publish_content && (
        <CollectionPublishSidebar
          onBlock={publishAction && handleBlock}
          onClose={handleDecline}
          onLoading={handlePublishLoading}
          status={status}
          uid={publishAction?.uid}
        />

      )}

    </>
  ), [
    action,
    handleBlock,
    handleConfirm,
    handleDecline,
    handlePublishLoading,
    publishAction,
    status,
    user?.can_publish_content,
  ]);

  return React.useMemo(() => ({ invoke, modals, status }), [invoke, modals, status]);
};
