import { ReactComponent as Close } from '@mdi/svg/svg/close.svg';
import { ReactComponent as Menu } from '@mdi/svg/svg/dots-horizontal.svg';
import { ReactComponent as CopyLink } from '@mdi/svg/svg/link.svg';
import { ReactComponent as RepeatOff } from '@mdi/svg/svg/repeat-off.svg';
import { ReactComponent as Repeat } from '@mdi/svg/svg/repeat.svg';
import * as cx from 'classnames';
import * as React from 'react';
import { Modal } from 'react-bootstrap';

import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { useProfile } from 'common/hooks/useProfile';
import { CropBox } from 'common/types';
import { EventContext, EVENTS, weplayedEvent } from 'common/utils/events';
import {
  createMomentEventDispatcher, MomentEventDispatcher,
} from 'common/utils/moments';
import { format } from 'common/utils/strings';

import { Avatar } from 'cms/components/Avatar';
import { ItemPreview } from 'cms/components/ItemPreview';
import { LikeButton } from 'cms/components/LikeButton';
import { Slider } from 'cms/components/Slider';
import { SliderRefType } from 'cms/components/Slider/types';
import { useCollection } from 'cms/hooks/useCollection';
import { useMoment } from 'cms/hooks/useMoment';

import * as messages from './messages';
import { MomentPlayer } from './MomentPlayer';
import * as s from './PopupPlayer.m.less';
import { AspectRatio, PlayerMode, PopupPlayerProps } from './types';

// empty crop boxes dict to not trigger update on every re-render when
// crop boxes are not provided
const empty = {};

export const PopupPlayer: React.FC<PopupPlayerProps> = function PopupPlayer({
  collectionId,
  cropBoxes: [__ratio = null, __cropBoxes = empty] = [],
  disabled,
  mode,
  momentId,
  onCancel,
  onClose,
  onCollectionLike,
  onCollectionLink,
  onCollectionMenu,
  onCropBoxes,
  onDone,
  onMomentLike,
  onMomentLink,
  onMomentMenu,
  onMomentSave,
}) {
  // eslint-disable-next-line no-underscore-dangle
  const _ratio = ((momentId || collectionId) && __ratio) || null;
  // eslint-disable-next-line no-underscore-dangle
  const _cropBoxes = ((momentId || collectionId) && __cropBoxes) || null;

  const { settings: { collection_play_repeat }, saveSettings } = useProfile();

  // moment data
  const {
    moment: { data: outerMoment, isLoading: isMomentLoading },
  } = useMoment({ uid: momentId });

  // collection data
  const {
    collection: { data: outerCollection, isLoading: isCollectionLoading },
  } = useCollection({ uid: collectionId });

  // trigger top loading indicator and handle errors
  useHandleQuery(
    { isLoading: isMomentLoading },
    { isLoading: isCollectionLoading },
  );

  // current aspect ratio
  const [ratio, setRatio] = React.useState(_ratio);

  // current crop boxes
  const [cropBoxes, setCropBoxes] = React.useState(_cropBoxes || {});

  // update current aspect ratio and crop boxes
  // in case of incoming parameters change
  React.useEffect(() => {
    if (_ratio !== ratio || _cropBoxes !== cropBoxes) {
      setRatio(_ratio);
      setCropBoxes(_cropBoxes);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_cropBoxes, _ratio]);

  // outer moment
  const [moment, setMoment] = React.useState(outerMoment);

  // outer collection
  const [collection, setCollection] = React.useState(outerCollection);

  // moment index in collection
  const [momentIdx, setMomentIdx] = React.useState(0);

  // ref to slider with moments in collection mode
  const sliderRef = React.useRef<SliderRefType>();

  // video player events dispatcher
  const eventDispatcher: MomentEventDispatcher = React.useMemo(() => {
    if (moment) {
      return createMomentEventDispatcher(moment, EventContext.ADMIN);
    }
  }, [moment]);

  // handle clone mode
  React.useEffect(() => {
    if (momentId && outerMoment) {
      if (mode === PlayerMode.MOMENT_CLONE) {
        setMoment({ ...outerMoment, pk: undefined });
      } else {
        setMoment(outerMoment);
      }
    }
  }, [momentId, outerMoment, mode]);

  // handle collection mode
  React.useEffect(() => {
    if (collectionId && outerCollection) {
      setCollection(outerCollection);

      if (!mode) {
        weplayedEvent({
          context: EventContext.ADMIN,
          type: EVENTS.COLLECTION_VIEW,
          collection_id: outerCollection.pk,
        });
      }
      setMomentIdx(0);
    }
  }, [outerCollection, collectionId, mode]);

  // modal title
  const title = (mode === PlayerMode.MOMENT_EDIT && 'Edit Moment')
    || (mode === PlayerMode.MOMENT_CLONE && 'Clone Moment')
    || (mode === PlayerMode.COLLECTION_EDIT && 'Edit Playlist')
    || (mode === PlayerMode.COLLECTION_CLONE && 'Clone Playlist')
    || (onCropBoxes && 'Set Aspect Ratio')
    || null;

  // modal state
  const show = Boolean(outerMoment || outerCollection);

  // change currently selected moment on slider
  React.useEffect(() => {
    if (collection && momentIdx !== null) {
      setMoment(collection.moments[momentIdx]);
      sliderRef.current?.go(momentIdx, true);
    }
  }, [collection, momentIdx]);

  // hide player automatically when no moment and collection
  // provided
  React.useEffect(() => {
    if (!momentId && !collectionId) {
      setMoment(null);
      setCollection(null);
      if (show) {
        onClose();
      }
    }
  }, [onClose, show, momentId, collectionId]);

  // handle moment menu
  const handleMomentMenu = React.useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
      onMomentMenu(moment, currentTarget);
    },
    [moment, onMomentMenu],
  );

  // handle collection menu
  const handleCollectionMenu = React.useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
      onCollectionMenu(collection, currentTarget);
    },
    [collection, onCollectionMenu],
  );

  // handle moment like
  const handleMomentLike = React.useCallback((like: boolean) => {
    onMomentLike(moment, like);
  }, [moment, onMomentLike]);

  // handle moment link copy request
  const handleMomentLink = React.useCallback(({ currentTarget }) => {
    onMomentLink(moment, currentTarget);
  }, [moment, onMomentLink]);

  // handle collection moment index change
  const handleCollectionMoment = React.useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
      setMomentIdx(parseInt(currentTarget.getAttribute('data-idx'), 10));
      eventDispatcher({
        type: EVENTS.MOMENT_PICK,
      });
      // fix missed outline
      currentTarget.blur();
      if (mode && onCancel) {
        onCancel();
      }
    },
    [mode, onCancel, eventDispatcher],
  );

  // handle collection like
  const handleCollectionLike = React.useCallback((like: boolean) => {
    onCollectionLike(collection, like);
  }, [collection, onCollectionLike]);

  // handle collection link copy request
  const handleCollectionLink = React.useCallback(({ currentTarget }) => {
    onCollectionLink(collection, currentTarget);
  }, [collection, onCollectionLink]);

  // handle click on the next moment button
  const handleNext = React.useCallback((manual) => {
    setMomentIdx(momentIdx + 1);
    eventDispatcher({
      type: EVENTS.MOMENT_NEXT,
      manual,
    });
  }, [momentIdx, eventDispatcher]);

  // handle cancel edit mode
  const handleCancel = React.useCallback(() => {
    if (outerCollection) {
      setCollection(outerCollection);
    } else if (outerMoment) {
      setMoment(outerMoment);
    }
    onCancel?.();
  }, [onCancel, outerCollection, outerMoment]);

  // handle prev moment button click
  const handlePrev = React.useCallback(() => {
    setMomentIdx(momentIdx - 1);
    eventDispatcher({
      type: EVENTS.MOMENT_PREV,
      manual: true,
    });
  }, [momentIdx, eventDispatcher]);

  // handle crop box change for the moment
  const handleCropBox = React.useCallback(($ratio: AspectRatio, $cropBox: CropBox) => {
    // if ratio is not the same, we'll remove all entries from crop boxes
    // and fill them with the copy of a newly provided box

    let $$ratio = ratio;
    let $$cropBoxes = cropBoxes;

    if ($ratio !== ratio) {
      $$ratio = $ratio;

      $$cropBoxes = {};

      if (collection?.moments && $ratio) {
        $$cropBoxes = Object.fromEntries(
          collection.moments.map(({ pk }) => [pk, pk === moment.pk ? $cropBox : { ...$cropBox }]),
        );
      } else if ($ratio) {
        $$cropBoxes = { [moment.pk]: $cropBox };
      }
    } else if ($$cropBoxes[moment.pk] !== $cropBox) {
      $$cropBoxes = { ...cropBoxes, [moment.pk]: $cropBox };
    }

    if ($$cropBoxes !== cropBoxes || $$ratio !== ratio) {
      setRatio($$ratio);
      setCropBoxes($$cropBoxes);
      onCropBoxes($$ratio, $$cropBoxes);
    }
  }, [collection?.moments, cropBoxes, moment?.pk, onCropBoxes, ratio]);

  // handle edit done button
  const handleDone = React.useCallback(() => {
    onDone?.();
  }, [onDone]);

  const handlePlayRepeat = React.useCallback(() => {
    saveSettings({ collection_play_repeat: !collection_play_repeat });
  }, [saveSettings, collection_play_repeat]);

  const handleEnd = React.useCallback(() => {
    if (!collection_play_repeat && !onCropBoxes) {
      handleNext(false);
    }
  }, [collection_play_repeat, handleNext, onCropBoxes]);

  // moment is in edit/clone state flag
  const manageMoment = momentId
    && [PlayerMode.MOMENT_CLONE, PlayerMode.MOMENT_EDIT].includes(mode);

  // collection is in edit/clone state flag
  const manageCollection = collectionId
    && [PlayerMode.COLLECTION_CLONE, PlayerMode.COLLECTION_EDIT].includes(mode);

  const hasNextMoment = collection && momentIdx < collection.moments.length - 1;

  return (
    <Modal
      backdropClassName="modal-backdrop-blackout"
      keyboard
      onEscapeKeyDown={onClose}
      onHide={!manageCollection ? onClose : undefined}
      show={!manageCollection && show}
      size="lg"
    >
      {moment && (
        <Modal.Body>
          <div className={s.header}>
            <button type="button" onClick={onClose}>
              <Close />
            </button>
            <h4 className={s.title}>{title}</h4>
            {moment.pk && !mode && !onCropBoxes && (
              <>
                {onMomentLike && (
                  <LikeButton
                    liked={moment.liked_by_user}
                    onChange={handleMomentLike}
                    className={cx(s.like, moment.liked_by_user && s.liked)}
                  />
                )}
                {!collection && onMomentLink && (
                  <button type="button" onClick={handleMomentLink}>
                    <CopyLink />
                  </button>
                )}
                {onMomentMenu && (
                  <button type="button" onClick={handleMomentMenu}>
                    <Menu />
                  </button>
                )}
              </>
            )}
          </div>
          <div className={s.root}>
            <MomentPlayer
              cropBox={[ratio || null, cropBoxes[moment?.pk] || null]}
              cropHelp={collection && 'You can have different positions for every moment in collection, but after changing aspect ratio they all will be reset to default position'}
              disabled={disabled}
              edit={manageMoment}
              moment={moment}
              onEnd={hasNextMoment && handleEnd}
              onCancel={onCancel && handleCancel}
              onCropBox={onCropBoxes && handleCropBox}
              onDone={handleDone}
              onNext={hasNextMoment && handleNext}
              onPrevious={collection && momentIdx > 0 && handlePrev}
              onSave={onMomentSave}
              paused={Boolean(mode)}
            />
            {collection && (
              <div
                className={
                  cx(
                    s.collection,
                    [PlayerMode.MOMENT_EDIT, PlayerMode.MOMENT_CLONE].includes(mode) && s.disabled,
                  )
                }
              >
                <div className={cx(s.header, s.collectionHeader)}>
                  <Avatar className={s.avatar} user={collection.created_by} />
                  <h4 className={s.title}>{collection.name}</h4>
                  <span className={s.extra}>
                    {format(messages.MOMENTS, collection.moment_count)}
                    {collection.can_see_duration
                      && format(messages.MOMENTS_DURATION, collection.duration.toFixed(2))}
                  </span>
                  {onCollectionLike && (
                    <LikeButton
                      liked={collection.liked_by_user}
                      onChange={handleCollectionLike}
                      className={cx(s.like, collection.liked_by_user && s.liked)}
                    />
                  )}
                  {onCollectionLink && (
                    <button
                      onClick={handleCollectionLink}
                      title="Copy link to playlist"
                      type="button"
                    >
                      <CopyLink />
                    </button>
                  )}
                  {!onCropBoxes && (
                    <button
                      onClick={handlePlayRepeat}
                      title={collection_play_repeat
                              ? 'Play moment in loop'
                              : 'Play moments one by one'}
                      type="button"
                    >
                      {collection_play_repeat ? <Repeat /> : <RepeatOff /> }
                    </button>
                  )}
                  {onCollectionMenu && (
                    <button type="button" onClick={handleCollectionMenu}>
                      <Menu />
                    </button>
                  )}
                </div>
                <div className={s.moments}>
                  <Slider ref={sliderRef}>
                    {collection.moments.map((m, idx) => (
                      <button
                        className={cx(
                          s.moment,
                          moment?.pk === m.pk && s.active,
                          cropBoxes?.[m.pk] && s.own,
                        )}
                        data-idx={idx}
                        key={m.pk}
                        onClick={handleCollectionMoment}
                        type="button"
                      >
                        <ItemPreview
                          alt={m.name}
                          highlight={cropBoxes?.[m.pk]}
                          animated={m.preview_webp}
                          className={s.thumbnail}
                          src={m.thumbnail}
                        >
                          <div className={s.momentIdx}>
                            {idx + 1}
                          </div>
                        </ItemPreview>
                      </button>
                    ))}
                  </Slider>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};
