import cx from 'classnames';
import { identity } from 'lodash/fp';
import { BaseEntityWithPk, Moment as $Moment } from 'weplayed-typescript-api';

import { formatDate } from 'common/utils/dates';

import { Avatar } from 'cms/components/Avatar';
import { FadeInImage } from 'cms/components/FadeInImage';
import { GameLabel } from 'cms/components/GameLabel';
import { SportLabel } from 'cms/components/SportLabel';
import {
  MomentDescriptionReadonly,
} from 'consumer/components/MomentDescription';

import { defaults } from '../../constants';
import { MomentItemProps } from '../../types';
import { getBlock, getPin } from '../../utils';
import { Block, Like, Link, Menu, Pin } from '../Controls';
import { EntityLink } from '../EntityLink';
import { moments as def } from '../links';
import { useLink } from '../useLink';
import { Base } from './Base';
import { Blocked } from './Blocked';
import * as s from './Items.m.less';

const d = defaults.lists.moments;

export const Moment = function Moment<
  T extends BaseEntityWithPk = $Moment,
  D extends $Moment = $Moment
>({
  base = d.base,
  blocking,
  created = d.created,
  creator = d.creator,
  edited = d.edited,
  game = d.game,
  getData = identity,
  item: $item,
  link,
  onBlock,
  onLike,
  onLink,
  onMenu,
  onPin,
  pinning,
  position,
  sport = d.sport,
  ...props
}: MomentItemProps<T, D>): JSX.Element {
  const item = getData($item);
  const url = useLink({ item, link, def });

  const {
    name, thumbnail, game: momentGame, curator, ctime,
    is_base, mtime, liked_by_user, is_hidden,
  } = item;

  const thumb = thumbnail && (
    <>
      <FadeInImage src={thumbnail} alt={name} />
      {base && is_base && <span className={s.base}>base</span>}
      {is_hidden && <Blocked />}
    </>
  );

  return (
    <Base<T>
      item={$item}
      controls={[
        <Pin
          className={s.visible}
          key="pin"
          loading={pinning}
          onClick={onPin}
          pin={getPin(item)}
        />,
        <Block
          block={getBlock(item)}
          className={s.visible}
          key="block"
          loading={blocking}
          onClick={onBlock}
        />,
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          key="link"
          onClick={onLink}
        />,
        <Like
          className={cx(liked_by_user && s.visible)}
          key="like"
          like={liked_by_user}
          onClick={onLike}
        />,
        <Menu
          className={s.visible}
          key="menu"
          onClick={onMenu}
        />,
      ]}
      {...props}
    >
      {typeof position === 'number' && (
        <td>{`${position}.`}</td>
      )}
      <td>
        <EntityLink
          className={cx(s.thumbnail, momentGame.sport?.slug)}
          url={url}
        >
          {thumb}
        </EntityLink>
      </td>
      <td>
        <span className={s.name}>
          <MomentDescriptionReadonly moment={item} inline />
        </span>
      </td>
      {game && (
        <td>
          <GameLabel game={momentGame} className={s.gameLabel} />
        </td>
      )}
      {sport && <td><SportLabel sport={momentGame.sport} /></td>}
      {creator && (<td><Avatar user={curator} className={s.avatar} /></td>)}
      {created && <td>{formatDate(ctime)}</td>}
      {edited && <td>{formatDate(mtime)}</td>}
    </Base>
  );
};
