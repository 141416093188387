import { What } from 'weplayed-typescript-api';

import { clientUrl, dataUrl } from 'common/utils/routes';

export const CLIENT_URLS = {
  AUTH: {
    LOGIN: clientUrl('/login/:org_slug?'),
    REGISTRATION_CONFIRM: clientUrl('/registration-confirm/:key'),
    REGISTRATION: clientUrl('/registration/:invitation_pk?/:org_slug?'),
    RESET_PASSWORD: clientUrl('/reset-password'),
    RESET_PASSWORD_CONFIRM: clientUrl('/reset-password/confirm/:uid/:token'),
  },
  USER: {
    MY_PROFILE: clientUrl('/user/profile'),
    MY_PROFILE_UPDATE: clientUrl('/user/profile/update'),
    CHANGE_PASSWORD: clientUrl('/user/change/password'),
    CHANNEL: clientUrl('/user/:uid/channel'),
    MY_CHANNEL: clientUrl('/user/channel'),
    UPDATE_EMAIL: clientUrl('/user/email'),
  },
  MOMENT: {
    VIEW_MOMENT: clientUrl('/m/:uid'),
    EMBED: clientUrl('/embed.html'),
  },
  GAME: {
    VIEW: clientUrl('/game/:uid/:muid?'),
  },
  PLAYER: {
    VIEW: clientUrl('/player/:uid'),
  },
  SEARCH: {
    PAGE: clientUrl('/search'),
  },
  COLLECTIONS: {
    VIEW_COLLECTION: clientUrl('/collection/:uid/:action?'),
    EDIT_COLLECTION: clientUrl('/collection/:uid/edit'),
  },
  CLIPPER: {
    CLIPPER_VIEW: clientUrl('/video/:uid/clipper'),
  },
  SCHOOLS: {
    LIST_VIEW: clientUrl('/schools'),
    DETAIL_VIEW: clientUrl('/schools/:uid'),
  },
  MAINTENANCE: clientUrl('/502'),
  FORBIDDEN: clientUrl('/403'),
  HOME: {
    INTRO: clientUrl('/intro'),
    DISCOVER_DEFAULT: clientUrl('/'),
    DISCOVER: clientUrl('/team/:uid?'),
  },
  CMS: {
    ADMIN: clientUrl('/cms'),
    INVITE: clientUrl('/cms/invite'),
    PLAYER_MOMENTS: clientUrl('/player/:uid/moments/maanage'),
    TEAM_MOMENTS: clientUrl('/team/:uid/moments/manage'),
    USER: clientUrl('/cms/users/:uid'),
    USERS: clientUrl('/cms/users'),
  },
} as const;

export const WIDGET = dataUrl('/api/viewing/v0/widget/');

export const HOME$ = clientUrl('/:uid?');
export const DISCOVER$ = clientUrl('/discover/:section?/:uid?/:sub?');

export const LIBRARY$ = clientUrl('/library/:type?/:section?');
export const PUBLISH$ = clientUrl('/publish/:type?/:section?/:first?/:second?');

export const GAME$ = clientUrl('/game/:uid/:muid?');

export const MOMENT$ = clientUrl(
  `${DISCOVER$.buildPath({ section: What.MOMENTS })}/#moment=:uid`,
);
export const COLLECTION$ = clientUrl(
  `${DISCOVER$.buildPath({ section: What.COLLECTIONS })}#playlist=:uid`,
);

// special case for UI
export const MOMENT$$ = clientUrl('#moment=:uid');
export const COLLECTION$$ = clientUrl('#playlist=:uid');

export const SETTINGS$ = clientUrl('/settings');
const settings = SETTINGS$.buildPath();

export const SETTINGS_USERS$ = clientUrl(`${settings}/users`);
export const SETTINGS_USER$ = clientUrl(`${settings}/users/edit/:uid`);
export const SETTINGS_INVITE$ = clientUrl(`${settings}/users/invite`);
export const SETTINGS_CAPTURE$ = clientUrl(`${settings}/capture`);
export const SETTINGS_LOCAL$ = clientUrl(`${settings}/local`);
export const SETTINGS_DIRECT_SHARE$ = clientUrl(`${settings}/direct-share`);

export const CLIENTS$ = clientUrl('/clients');
const clients = CLIENTS$.buildPath();

export const CLIENT$ = clientUrl(`${clients}/:uid`);
export const CLIENT_DISCOVER$ = clientUrl(`${clients}/:uid/discover/:section?/:pk?/:sub?`);
export const CLIENT_MANAGE$ = clientUrl(`${clients}/:uid/manage`);
export const CLIENT_PUBLISH$ = clientUrl(`${clients}/:uid/publish/:type?`);
export const CLIENT_SCHEDULE$ = clientUrl(`${clients}/:uid/schedule/:section?`);

export const STUDIO$ = clientUrl('/studio/:section?');
