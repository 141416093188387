import { ID } from 'weplayed-typescript-api';

export const getKey = (
  uid?: ID,
  ...args: unknown[]
): unknown[] => [
  'collection',
  uid,
  ...args,
];

export const getPinsKey = (
  uid: ID,
): string[] => ['collection-pins', uid];
