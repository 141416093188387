import * as React from 'react';

import * as s from './NothingFound.m.less';
import { NotFoundProps } from './types';

export const NothingFound: React.FC<NotFoundProps> = function NothingFound({
  children,
  text,
}) {
  return (
    <div className={s.root}>
      {text && (
        typeof text === 'boolean' ? 'Sorry, nothing has found' : text
      )}
      {children}
    </div>
  );
};
