import * as React from 'react';

export function usePreviousValue<T>(value: T): T | undefined {
  const ref = React.useRef<[T | undefined, T | undefined]>([undefined, undefined]);

  if (value !== ref.current[1]) {
    ref.current = [ref.current[1], value];
  }

  return ref.current[0];
}
