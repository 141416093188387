import { ReactComponent as Facebook } from '@mdi/svg/svg/facebook.svg';
import { ReactComponent as Instagram } from '@mdi/svg/svg/instagram.svg';
import { ReactComponent as YouTube } from '@mdi/svg/svg/youtube.svg';
import * as React from 'react';
import { DirectShareDestination } from 'weplayed-typescript-api';

import { ReactComponent as TikTok } from './tiktok.svg';
import { SocialIconProps } from './types';
import { ReactComponent as Twitter } from './x.svg';

export const SocialIcon: React.FC<SocialIconProps> = function SocialIcon({
  className,
  target,
}) {
  switch (target) {
    case DirectShareDestination.FACEBOOK: return <Facebook className={className} />;
    case DirectShareDestination.INSTAGRAM: return <Instagram className={className} />;
    case DirectShareDestination.TIKTOK: return <TikTok className={className} />;
    case DirectShareDestination.TWITTER: return <Twitter className={className} />;
    case DirectShareDestination.YOUTUBE: return <YouTube className={className} />;
    default: return null;
  }
};
