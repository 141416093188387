import cx from 'classnames';

import { Avatar } from 'cms/components/Avatar';

import * as s from './Controls.m.less';
import { CreatorProps } from './types';

export const Creator = function Creator({
  creator,
  hidden,
}: CreatorProps): JSX.Element {
  return (
    <>
      <Avatar user={creator} className={s.avatar} />
      &nbsp;
      <span className={cx(s.avatarName, hidden && s.hidden)}>{creator.full_name}</span>
    </>
  );
};
